import { type SendbirdGroupChat } from '@sendbird/chat/groupChannel';

export default {
    sdk: null as SendbirdGroupChat | null,

    get() {
        return this.sdk;
    },

    set(val: SendbirdGroupChat | null) {
        this.sdk = val;
    },
};
