import { type ProgramFamily, type ProgramType } from 'Program';

export enum ProgramApplicationStatus {
    SubmittedApplication = 'submitted_application',
    SelectedForAdmission = 'selected_for_admission',
    NotSelectedForAdmission = 'not_selected_for_admission',
}

interface ProgramApplicationAdminOnlyFields {
    admissionsDecision: string | null;
    admissionsInterviewer: string | null;
    admissionsInterviewDate: number | null;
    admissionsFinalDecision: string | null;
    admissionsValarOptIn: boolean;

    admissionsMotivationAssessmentScore: string | null;
    admissionsContributionAssessmentScore: string | null;
    admissionsInsightfulnessAssessmentScore: string | null;

    // LEGACY
    admissionsMotivationScore: string | null;
    admissionsContributionScore: string | null;
    admissionsInsightfulnessScore: string | null;
}

interface ProgramApplicationBaseFields {
    id: string;
    appliedAt: number;
    cohortId: string;
    cohortName: string;
    cohortProgramGuideUrl: string;
    earlyAdmissionEligible: boolean;
    earlyAdmissionOptIn: boolean | null;
    interviewConducted: boolean;
    interviewScheduled: boolean;
    invitedToInterview: boolean;
    invitedToInterviewUrl: string;
    invitedToPreRecordedInterview: boolean;
    programFamily: ProgramFamily;
    programType: ProgramType;
    status: ProgramApplicationStatus;
    willNotInterview: boolean;
}

export type ProgramApplication<adminOnlyFields = false> = adminOnlyFields extends true
    ? ProgramApplicationAdminOnlyFields & ProgramApplicationBaseFields
    : ProgramApplicationBaseFields;
