import angularModule from 'Settings/angularModule/scripts/settings_module';
import template from 'Settings/angularModule/views/edit_documents.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

import progressBadgeCompleteTurquoise from 'images/progress_badge_complete_turquoise.png';
import trashcanBeige from 'vectors/trashcan_beige.svg';
import states from 'states';
import {
    getHasPendingAdmissionOfferOrIsNotYetCurrent,
    getHasPendingProgramApplication,
    getIsCurrentOrHasCompletedActiveProgram,
} from 'Users';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('editDocuments', [
    '$injector',

    function factory($injector) {
        const User = $injector.get('User');
        const Cohort = $injector.get('Cohort');
        const $window = $injector.get('$window');
        const ngToast = $injector.get('ngToast');
        const TranslationHelper = $injector.get('TranslationHelper');
        const PrivateUserDocumentsHelper = $injector.get('PrivateUserDocumentsHelper');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const frontRoyalUpload = $injector.get('frontRoyalUpload');
        const S3TranscriptAsset = $injector.get('S3TranscriptAsset');
        const LocalizedCountries = $injector.get('LocalizedCountries');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                user: '<',
            },
            link(scope) {
                scope.progressBadgeCompleteTurquoise = progressBadgeCompleteTurquoise;
                scope.trashcanBeige = trashcanBeige;
                scope.currentPlaceDetails = {};
                // lock the individual form fields and force the user to search for their address
                //  via Google Places AutoComplete if they have not already provided address_line_1
                scope.formIsCollapsed = !scope.user.address_line_1;

                // initially show or hide address line 2 based on whether
                // the user has filled this line out previously
                scope.showAddressLine2 = scope.user.address_line_2;

                scope.stateOptions = states;

                scope.userProxy = User.new(scope.user.asJson());

                // TODO: Remove the following after running convert_state_abbr rake task
                // Ticket: https://trello.com/c/7YiDAv5Q/
                scope.userStateIndex = scope.stateOptions.findIndex(
                    state => (state.abbreviation || state.label) === scope.userProxy.state,
                );

                if (scope.userStateIndex >= 0) {
                    scope.userProxy.state = scope.stateOptions[scope.userStateIndex].label;
                }
                // end TODO

                NavigationHelperMixin.onLink(scope);
                PrivateUserDocumentsHelper.onLinkWhenUsingProxy(scope);

                scope.MAX_ENGLISH_LANGUAGE_PROFICIENCY_DOCUMENTS = 7;
                scope.EDITABLE_DOCUMENTS_ATTRIBUTES = [
                    // document upload related attributes
                    'transcripts_verified',
                    'english_language_proficiency_documents_approved',
                    'english_language_proficiency_documents_type',
                    'english_language_proficiency_comments',
                ];
                scope.REQUIRED_MAILING_ADDRESS_ATTRIBUTES = ['address_line_1', 'city', 'state', 'country', 'phone'];
                scope.EDITABLE_MAILING_ADDRESS_ATTRIBUTES = scope.REQUIRED_MAILING_ADDRESS_ATTRIBUTES.concat([
                    'address_line_2',
                    'zip',
                ]);
                scope.S3_DOCUMENT_ATTRIBUTES = ['s3_transcript_assets', 's3_english_language_proficiency_documents'];

                const translationHelper = new TranslationHelper('settings.edit_documents');
                scope.cannotUploadOnThisDevice = !!($window.CORDOVA && $window.device.platform === 'iOS');

                scope.showMailingAddress =
                    Cohort.requiresMailingAddress(scope.user.programType) &&
                    (scope.user.isCurrentOrHasCompletedActiveProgram ||
                        getHasPendingAdmissionOfferOrIsNotYetCurrent(scope.user));

                // override default "cities-only" config
                scope.googlePlacesOptions = {
                    autoCompleteOptions: {
                        types: [],
                    },
                };

                // This config is used to determine which of the Google Places data fields will populate
                // which user location detail fields. Example for 'city': if 'locality' doesn't exist for
                // a particular Google Place, then it will go down the list until it finds the highest level
                // available in the Google Places data (postal_town, sublocality_level_1, etc).
                scope.fullAddressConfig = {
                    address_line_1: 'name',
                    city: {
                        locality: 'long',
                        postal_town: 'long',
                        sublocality_level_1: 'long',
                        sublocality_level_2: 'long',
                        sublocality_level_3: 'long',
                        sublocality_level_4: 'long',
                        sublocality_level_5: 'long',
                    },
                    state: {
                        administrative_area_level_1: 'long',
                        administrative_area_level_2: 'long',
                        administrative_area_level_3: 'long',
                        administrative_area_level_4: 'long',
                        administrative_area_level_5: 'long',
                    },
                    zip: {
                        postal_code: 'short',
                    },
                    country: {
                        country: 'short',
                    },
                };

                scope.formatPartialAddressString = (partialAddressKey, placeDetails) => {
                    const partialAddressConfig = scope.fullAddressConfig[partialAddressKey];

                    if (typeof partialAddressConfig === 'string') {
                        return placeDetails[partialAddressConfig];
                    }

                    let string = '';

                    Object.keys(partialAddressConfig).forEach(placeDetailsKey => {
                        const lengthType = partialAddressConfig[placeDetailsKey];
                        if (!placeDetails[placeDetailsKey]) {
                            return;
                        }
                        const placeDetailString = placeDetails[placeDetailsKey][lengthType];

                        if (!string) {
                            string = placeDetailString;
                        }
                    });

                    return string;
                };

                scope.onPlaceChange = (place, placeDetails) => {
                    const { place_id } = place;

                    // update userProxy, which will update the input forms
                    Object.keys(scope.fullAddressConfig).forEach(partialAddressKey => {
                        const partialAddressString = scope.formatPartialAddressString(partialAddressKey, placeDetails);
                        scope.userProxy[partialAddressKey] = partialAddressString;
                    });

                    // update career_profile here instead of passing these values to location-autocomplete's ng-model
                    // and details-model, so that the search input field can start out empty on initial page load.
                    scope.userProxy.career_profile.place_id = place_id;
                    scope.userProxy.career_profile.place_details = placeDetails;

                    scope.formIsCollapsed = false;

                    scope.$digest();
                };

                // on scope for specs
                scope.transcriptViewConfig = {
                    get_head_start: {
                        header: {
                            key: 'settings.edit_documents.transcripts',
                            subTextKeys: [
                                'settings.edit_documents.unofficial_transcripts_description_part_one',
                                'settings.edit_documents.unofficial_transcripts_description_part_two',
                            ],
                            class: 'form-group no-border row',
                        },
                        body: {
                            class: () => 'form-group row no-border',
                            educationExperiences: user =>
                                user.career_profile.educationExperiencesThatCanRequireOfficial,
                            educationExperienceSubTextKey: experience =>
                                experience.unofficialTranscripts.length === 0
                                    ? 'settings.edit_documents.unofficial_transcripts_upload'
                                    : null,
                            showUnofficialTranscriptList: experience => experience.unofficialTranscripts.length > 0,
                            showUploadButton: () => true,
                        },
                        showFooter: true,
                    },
                    official_not_approved_or_waived: {
                        header: {
                            key: 'settings.edit_documents.transcripts',
                            subTextKeys: [
                                'settings.edit_documents.unofficial_transcripts_please_upload',
                                'settings.edit_documents.official_transcripts_also_required',
                            ],
                            class: 'form-group no-border row',
                        },
                        body: {
                            class: $last => `form-group row ${!$last || !scope.showMailingAddress ? 'no-border' : ''}`,
                            educationExperiences: user =>
                                user.career_profile.educationExperiencesThatCanRequireOfficial,
                            educationExperienceSubTextKey: experience => {
                                if (experience.official_transcript_required) {
                                    return 'settings.edit_documents.official_transcripts_required';
                                }

                                if (experience.unofficialTranscripts.length === 0) {
                                    return 'settings.edit_documents.unofficial_transcripts_upload';
                                }

                                return null;
                            },
                            showUnofficialTranscriptList: experience => experience.unofficialTranscripts.length > 0,
                            showUploadButton: (experience, user) => {
                                if (user.isCurrentOrHasCompletedActiveProgram) {
                                    return false;
                                }

                                if (experience.official_transcript_required) {
                                    return !experience.unofficial_transcripts_approved;
                                }

                                return true;
                            },
                        },
                    },
                    only_accepted: {
                        header: {
                            key: 'settings.edit_documents.transcripts',
                            class: 'form-group no-border row',
                        },
                        body: {
                            class: $last => `form-group row ${!$last || !scope.showMailingAddress ? 'no-border' : ''}`,
                            educationExperiences: user => [
                                user.career_profile.educationExperienceRequiringOfficialTranscript,
                            ],
                            educationExperienceSubTextKey: () =>
                                'settings.edit_documents.official_transcripts_required',
                            showUnofficialTranscriptList: () => false,
                            showUploadButton: () => false,
                        },
                    },
                    official_approved_or_waived: {
                        header: {
                            key: 'settings.edit_documents.transcripts',
                            subTextKeys: [
                                scope.user.officialTranscriptApproved
                                    ? 'settings.edit_documents.official_transcripts_approved'
                                    : 'settings.edit_documents.official_transcripts_waived',
                            ],
                            // mailing address comes below transcript section - if visible, show border
                            class: scope.showMailingAddress ? 'form-group row' : 'form-group row no-border',
                        },
                    },
                };

                // See comment above User#recordsIndicateUserShouldProvideTranscripts about users in
                // multiple programs
                Object.defineProperty(scope, 'transcriptView', {
                    get() {
                        if (scope.user?.recordsIndicateUserShouldProvideTranscripts) {
                            if (scope.user.officialTranscriptApprovedOrWaived) {
                                return scope.transcriptViewConfig.official_approved_or_waived;
                            }

                            if (getIsCurrentOrHasCompletedActiveProgram(scope.user)) {
                                return scope.transcriptViewConfig.only_accepted;
                            }

                            if (getHasPendingProgramApplication(scope.user)) {
                                return scope.transcriptViewConfig.get_head_start;
                            }

                            return scope.transcriptViewConfig.official_not_approved_or_waived;
                        }

                        return undefined;
                    },
                    configurable: true,
                });

                Object.defineProperty(scope, 'showAnyDocumentSections', {
                    get() {
                        return scope.user && (scope.showEnglishLanguageProficiencySection || !!scope.transcriptView);
                    },
                    configurable: true,
                });

                Object.defineProperty(scope, 'hasUnsavedDocumentsSectionChanges', {
                    get() {
                        return hasUnsavedChanges(scope.EDITABLE_DOCUMENTS_ATTRIBUTES);
                    },
                    configurable: true,
                });

                Object.defineProperty(scope, 'hasUnsavedMailingAddressChanges', {
                    get() {
                        return hasUnsavedChanges(scope.EDITABLE_MAILING_ADDRESS_ATTRIBUTES);
                    },
                    configurable: true,
                });

                function hasUnsavedChanges(editableAttributes) {
                    return !!(
                        scope.user && _.find(editableAttributes, attr => scope.userProxy[attr] !== scope.user[attr])
                    );
                }

                function getCustomCheckboxIsCheckedHandler(indicatesUserShouldUploadProp) {
                    return docsVerifiedProp =>
                        scope.user && (scope.user[docsVerifiedProp] || !scope.user[indicatesUserShouldUploadProp]);
                }

                scope.customCheckboxIsCheckedHandlers = {
                    transcripts_verified: getCustomCheckboxIsCheckedHandler(
                        'recordsIndicateUserShouldProvideTranscripts',
                    ),
                    english_language_proficiency_documents_approved: getCustomCheckboxIsCheckedHandler(
                        'recordsIndicateUserShouldUploadEnglishLanguageProficiencyDocuments',
                    ),
                };

                scope.fieldIsDisabled = indicatesUserShouldUploadProp =>
                    !!(scope.user && !scope.user[indicatesUserShouldUploadProp]);

                scope.uploadIsRequired = (docsVerifiedProp, indicatesUserShouldUploadProp) =>
                    !!(scope.user && !scope.user[docsVerifiedProp] && scope.user[indicatesUserShouldUploadProp]);

                // Document upload/deletion happens in place on the main scope.user object, which means that
                // the S3 documents on the scope.userProxy object can be out of sync with the main scope.user
                // object, so this method copies the document attributes over to the userProxy to re-sync them.
                scope.copyS3DocumentsToUserProxy = () => {
                    scope.userProxy.copyAttrs(_.pick(scope.user.asJson(), scope.S3_DOCUMENT_ATTRIBUTES));
                };

                scope.onTranscriptSelect = ($file, $invalidFiles, educationExperienceProxy) => {
                    educationExperienceProxy.$$transcriptErrMessage = null;
                    educationExperienceProxy.$$uploadingTranscript = true;

                    frontRoyalUpload
                        .handleNgfSelect($file, $invalidFiles, file => ({
                            url: S3TranscriptAsset.UPLOAD_URL,
                            data: {
                                record: {
                                    file,
                                    education_experience_id: educationExperienceProxy.id,
                                    transcript_type: 'unofficial',
                                },
                            },
                            supportedFormatsForErrorMessage: '.pdf, .doc, .docx, .jpg, .png',
                        }))
                        .then(response => {
                            const transcriptJson = response.data.contents.s3_transcript_assets[0];

                            // Since this change happens independently of a save button, reflect
                            // it on the original object now.
                            const originalEducationExperience = scope.user.career_profile.education_experiences.find(
                                e => e.id === educationExperienceProxy.id,
                            );
                            originalEducationExperience.transcripts.push(S3TranscriptAsset.new(transcriptJson));

                            educationExperienceProxy.transcripts.push(S3TranscriptAsset.new(transcriptJson));
                        })
                        .catch(err => {
                            educationExperienceProxy.$$transcriptErrMessage = err && err.message;
                        })
                        .finally(() => {
                            educationExperienceProxy.$$uploadingTranscript = false;
                        });
                };

                scope.save = (attributesToSave, attributesToOmit) => {
                    // Document upload/deletion happens in place on the main scope.user object, which means that
                    // the S3 documents on the scope.userProxy object can be out of sync with the main scope.user
                    // object, so before we save, we ensure that they're in sync with each other in that respect.
                    scope.copyS3DocumentsToUserProxy();

                    // We use a temporary user proxy here because the userProxy object acts as the model that's
                    // responsible for accepting all edits in the form, but since we have multiple save buttons
                    // that call this method and each button expects only certain attributes to be saved, we need
                    // to ensure that only the appropriate attributes are included in the save call and since
                    // the userProxy object contains ALL of the edits, we need to use a temporary proxy instead
                    // of calling save on the userProxy object directly.
                    const tempUserProxy = User.new(_.omit(scope.userProxy.asJson(), attributesToOmit));
                    scope.saving = true;

                    tempUserProxy
                        .save({
                            education_experiences: [
                                scope.userProxy.career_profile.educationExperienceRequiringOfficialTranscript,
                            ],
                        })
                        .then(response => {
                            // Ensure that attributes that were updated as a direct result
                            // of this save all copied over to the main scope.user object
                            // as well as any changes that may have occurred on the server
                            // as an indirect result of this update.
                            scope.user.copyAttrs(
                                _.pick(response.result, attributesToSave.concat(scope.S3_DOCUMENT_ATTRIBUTES)),
                            );

                            scope.copyS3DocumentsToUserProxy();

                            // Be sure to reflect updated_at change, otherwise we can trigger a 406
                            scope.userProxy.updated_at = response.result.updated_at;
                            scope.user.updated_at = scope.userProxy.updated_at;

                            const ngToastContent =
                                attributesToSave === scope.EDITABLE_DOCUMENTS_ATTRIBUTES
                                    ? translationHelper.get('documents_saved')
                                    : translationHelper.get('mailing_address_saved');
                            ngToast.create({
                                content: ngToastContent,
                                className: 'success',
                            });
                        })
                        .finally(() => {
                            scope.saving = false;
                        });
                };

                scope.uploadButtonText = translationHelper.get('upload');

                // See comment above User#recordsIndicateUserShouldUploadEnglishLanguageProficiencyDocuments about users in
                // multiple programs
                scope.showEnglishLanguageProficiencySection =
                    scope.user.recordsIndicateUserShouldUploadEnglishLanguageProficiencyDocuments;
                scope.showSignInOnDesktopToUploadDocsMessage =
                    scope.cannotUploadOnThisDevice &&
                    (scope.user.missingOfficialTranscripts || scope.user.missingEnglishLanguageProficiencyDocuments);

                scope.countryOptions = scope.showMailingAddress
                    ? _.map(LocalizedCountries.getForCurrentLocale(), country => ({
                          value: country.value,
                          label: country.text,
                      }))
                    : null;
            },
        };
    },
]);
