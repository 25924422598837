import { type ProgramInclusion } from 'ProgramInclusion';
import { type AdmissionOffer } from 'AdmissionOffer';
import { type ProgramApplication } from 'ProgramApplication';
import { AoiRecordType, type AoiRecord } from './ProgramAoi.types';

export const isAdmissionOffer = (record: AoiRecord | null): record is AdmissionOffer =>
    !!record && 'offeredAt' in record;
export const isProgramApplication = (record: AoiRecord | null): record is ProgramApplication =>
    !!record && 'appliedAt' in record;
export const isProgramInclusion = (record: AoiRecord | null): record is ProgramInclusion =>
    !!record && 'includedAt' in record;

export const isAoiRecord = (maybeAoiRecord: unknown): maybeAoiRecord is AoiRecord =>
    !!maybeAoiRecord &&
    typeof maybeAoiRecord === 'object' &&
    (isAdmissionOffer(maybeAoiRecord as AdmissionOffer) ||
        isProgramApplication(maybeAoiRecord as ProgramApplication) ||
        isProgramInclusion(maybeAoiRecord as ProgramInclusion));

export function aoiRecordCreationTime(record: AoiRecord) {
    if (isProgramApplication(record)) return new Date(record.appliedAt);
    if (isAdmissionOffer(record)) return new Date(record.offeredAt);
    if (isProgramInclusion(record)) return new Date(record.includedAt);

    throw new Error('Not an AOI record');
}

export function recordType(record: AoiRecord) {
    if (isProgramApplication(record)) return AoiRecordType.ProgramApplication;
    if (isAdmissionOffer(record)) return AoiRecordType.AdmissionOffer;
    if (isProgramInclusion(record)) return AoiRecordType.ProgramInclusion;

    throw new Error('Not an AOI record');
}
