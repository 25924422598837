import { ProgramInclusionStatus } from 'ProgramInclusion';
import { AdmissionOfferStatus } from 'AdmissionOffer';
import { ProgramApplicationStatus } from 'ProgramApplication';

export const PENDING_STATUSES = [
    ProgramInclusionStatus.Included,
    ProgramApplicationStatus.SubmittedApplication,
    AdmissionOfferStatus.OfferedAdmission,
] as const;

export const SUCCESS_STATUSES = [
    ProgramInclusionStatus.Graduated,
    ProgramApplicationStatus.SelectedForAdmission,
    AdmissionOfferStatus.AcceptedAdmissionOffer,
] as const;

export const FAILURE_STATUSES = [
    ProgramInclusionStatus.WillNotGraduate,
    ProgramApplicationStatus.NotSelectedForAdmission,
    AdmissionOfferStatus.DeclinedAdmissionOffer,
] as const;
