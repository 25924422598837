import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type ProgramListWithSubSections as ProgramListWithSubSectionsType } from '../selectProgram.types';
import { ProgramList } from './ProgramList';

export function ProgramListWithSubSections({ programList }: { programList: ProgramListWithSubSectionsType }) {
    const { t } = useTranslation('back_royal');
    const [selectedSubSectionIndex, setSelectedSubSectionIndex] = useState<number>(0);

    const selectedSubSection = programList.subSections()[selectedSubSectionIndex];

    return (
        <div className="program-list-with-sub-sections tw-px-[8px]">
            <div className="sub-section-tabs tw-mb-9 tw-flex tw-justify-center tw-gap-x-2 tw-gap-y-5 sm:tw-gap-x-5">
                {programList.subSections().map((subSection, index) => (
                    <button
                        type="button"
                        key={subSection.title.key}
                        onClick={() => setSelectedSubSectionIndex(index)}
                        className={`sub-section-tab tw-rounded tw-border-none tw-p-2 tw-text-xxs tw-font-semibold ${
                            selectedSubSectionIndex === index
                                ? 'selected tw-bg-blue tw-text-white'
                                : 'tw-bg-transparent tw-text-blue'
                        }`}
                    >
                        {t(subSection.title.key, subSection.title.params ?? {}).toLocaleUpperCase()}
                    </button>
                ))}
            </div>
            <ProgramList items={selectedSubSection.items()} intro={selectedSubSection.intro} />
        </div>
    );
}
