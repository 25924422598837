import { getBackRoyalApiBaseConfig } from 'BackRoyalApi';
import { createApi } from 'ReduxHelpers';
import { type PlaylistShowResponse } from './Playlists.types';

export const playlistEditorApi = createApi('playlistEditorApi', {
    ...getBackRoyalApiBaseConfig('playlistEditorApi'),
    endpoints: builder => ({
        getPlaylist: builder.query<PlaylistShowResponse, string>({
            query: id => ({
                url: `playlists/${id}.json`,
                method: 'GET',
            }),
        }),
    }),
});
