import 'Oreo/angularModule';
import { generateGuid } from 'guid';
import { gsap } from 'FrontRoyalGsap';

export default angular
    .module('scrollHelper', [
        'FrontRoyal.Oreo', // screenMdMin
    ])
    .factory('scrollHelper', [
        '$injector',
        $injector => {
            const $route = $injector.get('$route');
            const $window = $injector.get('$window');
            const screenMdMin = $injector.get('SCREEN-MD-MIN');

            return {
                scrollToTop(smooth, elemSelector, duration) {
                    this.scrollToPosition(0, smooth, elemSelector, duration);
                },

                // If you are on marketing pages, pass in window as the elemSelector
                scrollToElement(element, smooth, additionalOffset, elemSelector, duration, axis = 'y') {
                    const scrollableElem = elemSelector ? $(elemSelector) : this.container();
                    let position;

                    const elementOffset = element.offset();

                    if (scrollableElem[0] === $window) {
                        // if the element is hidden when this code runs, offset() will have returned null
                        if (!elementOffset) {
                            return;
                        }
                        position = axis === 'y' ? elementOffset.top : elementOffset.left;
                    } else {
                        const scrollableOffset = scrollableElem.offset();

                        // if the element or scrollableElement is hidden when this code runs, offset() will have returned null
                        if (!elementOffset || !scrollableOffset) {
                            return;
                        }
                        position =
                            axis === 'y'
                                ? elementOffset.top - scrollableOffset.top + scrollableElem.scrollTop()
                                : elementOffset.left - scrollableOffset.left + scrollableElem.scrollLeft();
                    }
                    position += additionalOffset || 0;
                    this.scrollToPosition(position, smooth, scrollableElem, duration, axis);
                },

                scrollToPosition(position, smooth, elemSelector, duration, axis = 'y') {
                    const scrollableElem = elemSelector ? $(elemSelector) : this.container();
                    duration = duration || 0.5;
                    const scrollTo = {};
                    scrollTo[axis] = position;
                    if (smooth) {
                        gsap.to(scrollableElem, {
                            duration,
                            scrollTo,
                            ease: 'power2.inOut',
                        });
                    } else if (axis === 'y') {
                        scrollableElem.scrollTop(position);
                    } else {
                        scrollableElem.scrollLeft(position);
                    }
                },

                container() {
                    const useShell = !($route.current && $route.current.useShell === false);
                    if (!useShell) {
                        return $('body');
                    }

                    const mainBox = $('.page-with-left-nav-and-main-box .main-box');
                    // See page_with_left_nav_and_main_box.scss, $navPositionThreshold
                    if (mainBox.length > 0 && $window.innerWidth < screenMdMin) {
                        return mainBox;
                    }
                    return $('#app-main-container:eq(0)');
                },

                /*
                This scrollable element can change when the screen size
                changes.  This method handles that and makes sure that you
                are always watching for scrolls on the appropriate element.

                It also cleans everything up when the provided scope is
                destroyed.
            */
                watchScrollUntilDestroyed(scope, callback) {
                    const self = this;
                    const namespace = generateGuid();
                    let container;

                    // Whenever the screen is resized, set up scroll
                    // watchers on the appropriate container (if necessary)
                    function onResize() {
                        const newContainer = self.container();
                        if (!container || newContainer[0] !== container[0]) {
                            container = newContainer;
                            container.off(`scroll.${namespace}`);
                            container.on(`scroll.${namespace}`, callback);
                            callback();
                        }
                    }

                    function off() {
                        container.off(`scroll.${namespace}`);
                        $($window).off(`resize.${namespace}`);
                    }

                    $($window).on(`resize.${namespace}`, onResize);
                    onResize(); // Set things up once right away

                    scope.$on('$destroy', off); // Clean up when the scope is destroyed
                    return off; // Return a function that turns stuff off, ike scope.$on does
                },
            };
        },
    ]);
