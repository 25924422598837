
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.playlists = window.Smartly.locales.modules.en.back_royal.playlists || {};
window.Smartly.locales.modules.en.back_royal.playlists.playlist_library = window.Smartly.locales.modules.en.back_royal.playlists.playlist_library || {};
window.Smartly.locales.modules.en.back_royal.playlists.playlist_library = {...window.Smartly.locales.modules.en.back_royal.playlists.playlist_library, ...{
    "curated_playlists": "CURATED PLAYLISTS",
    "my_playlists": "MY PLAYLISTS",
    "title": "PLAYLISTS"
}
}
    