import angularModule from 'Playlists/angularModule/scripts/playlists_module';
import template from 'Playlists/angularModule/views/playlist_map_content_item_icon.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('playlistMapContentItemIcon', [
    '$injector',

    function factory() {
        return {
            restrict: 'E',
            templateUrl,
            scope: {
                contentItem: '<',
                locked: '<',
            },
            link(scope) {
                const MIN_SECTIONS = 1;
                const MAX_SECTIONS = 10;

                //----------------------------
                // Display Helpers
                //----------------------------

                // clamp the range of sections to ensure proper display in view
                scope.numSections = Math.max(
                    MIN_SECTIONS,
                    Math.min(MAX_SECTIONS, scope.contentItem && scope.contentItem.streamCount),
                );

                // Used for playlists to determine how much to fill the progress 'tower' on the left side of the playlist icon
                scope.setNumberComplete = playlist => {
                    if (playlist.complete) {
                        scope.numSectionsFilled = scope.numSections;
                    } else {
                        // count number of completed streams
                        let streamsCompleted = _.filter(playlist.streams, stream => stream.complete).length;

                        // interpolate streamsCompleted to be integer from 1 to numSections-1 if more than 10 streams
                        if (streamsCompleted > 0 && playlist.streamCount > MAX_SECTIONS) {
                            // ensure at least 1 section is highlighted
                            // ensure we don't prematurely highlight all 10 sections by using floor
                            streamsCompleted = Math.max(
                                1,
                                Math.floor((streamsCompleted / playlist.streamCount) * MAX_SECTIONS),
                            );
                        }

                        scope.numSectionsFilled = streamsCompleted;
                    }
                };

                scope.setNumberComplete(scope.contentItem);
            },
        };
    },
]);
