import angularModule from 'Positionable/angularModule/scripts/positionable_module';

angularModule.directive('resizeHandle', [
    'DraggableDirHelper',
    'AspectRatioEnforcer',
    '$timeout',

    (DraggableDirHelper, AspectRatioEnforcer, $timeout) => ({
        restrict: 'E',

        template:
            '<span ' +
            'class="resize" ' +
            'ng-style="getResizeStyles(location, $index)" ' +
            'draggable="true" ' +
            'dragstart="onResizeStart($event)" ' +
            'dragstop="onResized($event)" ' +
            'dragmove="previewResize($event)"></span>',

        scope: {
            resizeWidth: '=',
            resizeHeight: '=',
            targetSelector: '@resizeTarget',
            afterResized: '&',
            restrictAspectRatio: '=',
            resizeUnits: '=',
        },

        link(scope, elem) {
            elem.data('setTargetEl', el => {
                scope.targetEl = el;
            });

            scope.$watch('targetSelector', targetSelector => {
                if (!targetSelector) {
                    scope.targetEl = undefined;
                } else {
                    // the resize handle must be inside the target element
                    const el = elem.closest(targetSelector);

                    if (el.length === 0) {
                        throw new Error(`No target element found for "${targetSelector}"`);
                    }
                }
            });

            scope.setDataWidthAndHeightFromElSizeOnceResizedInBrowser = i => {
                // wait half a second so that hopefully the correct width and height
                // have been set
                if (i === 0) {
                    $timeout(scope.setDataWidthAndHeightFromElSizeOnceResizedInBrowser.bind(scope, i + 1), 500);
                    return;
                }
                const el = scope.targetEl;
                if (el) {
                    scope.onResizeStart();
                    scope.onResized({
                        dragAndDrop: {
                            movedX: 0,
                            movedY: 0,
                        },
                    });
                }
            };

            scope.setElementSizeFromData = () => {
                const el = scope.targetEl;
                if (el && el.length > 0) {
                    if (
                        angular.isUndefined(scope.resizeWidth) ||
                        angular.isUndefined(scope.resizeHeight) ||
                        scope.resizeWidth === null ||
                        scope.resizeHeight === null
                    ) {
                        el.css({
                            width: 'auto',
                            height: 'auto',
                        });

                        scope.setDataWidthAndHeightFromElSizeOnceResizedInBrowser(0);
                    } else {
                        el.width(scope.resizeWidth + scope.units);
                        el.height(scope.resizeHeight + scope.units);
                    }
                }
            };

            scope.$watchCollection('[targetEl, resizeWidth, resizeHeight]', () => {
                scope.setElementSizeFromData();
            });

            Object.defineProperty(scope, 'units', {
                get() {
                    return scope.resizeUnits === '%' ? '%' : 'px';
                },
            });

            scope.onResizeStart = () => {
                let dataWidth = scope.resizeWidth;
                let dataHeight = scope.resizeHeight;
                const parentEl = scope.targetEl.parent();
                const pixelWidth = scope.targetEl.width();
                const pixelHeight = scope.targetEl.height();

                if (scope.units === '%' && (!parentEl.width() || !parentEl.height())) {
                    throw new Error('Cannot set percentage dimensions because the parent has no width or height.');
                }

                if (angular.isUndefined(dataWidth) || dataWidth === null) {
                    dataWidth = scope.units === 'px' ? pixelWidth : (100 * pixelWidth) / parentEl.width();
                }

                if (angular.isUndefined(dataHeight) || dataHeight === null) {
                    dataHeight = scope.units === 'px' ? pixelHeight : (100 * pixelHeight) / parentEl.height();
                }

                scope.initialResizeDimensions = {
                    pixelWidth: scope.targetEl.width(),
                    pixelHeight: scope.targetEl.height(),
                    dataWidth,
                    dataHeight,
                };
            };

            // dragover will not run a scope.$apply, because it would be too
            // slow to run so many of them.  So, rather than setting the width and
            // height on the location, we'll do it directly on the element.
            scope.previewResize = evt => {
                elem.hide();
                const dimensions = scope.getNewDimensions(evt);
                scope.targetEl.width(dimensions.cssWidth);
                scope.targetEl.height(dimensions.cssHeight);
                if (!['relative', 'absolute'].includes(scope.targetEl.css('position'))) {
                    scope.targetEl.css('position', 'relative');
                }
            };

            scope.onResized = evt => {
                scope.previewResize(evt);

                const dimensions = scope.getNewDimensions(evt);
                elem.show();

                scope.resizeWidth = dimensions.dataWidth;
                scope.resizeHeight = dimensions.dataHeight;

                if (scope.afterResized) {
                    scope.afterResized({
                        $event: evt,
                    });
                }
            };

            scope.getNewDimensions = evt => {
                const targetWidth = scope.initialResizeDimensions.pixelWidth + evt.dragAndDrop.movedX;
                const targetHeight = scope.initialResizeDimensions.pixelHeight + evt.dragAndDrop.movedY;
                const obj = {};

                if (scope.restrictAspectRatio) {
                    const aspectRatioEnforcer = new AspectRatioEnforcer();
                    aspectRatioEnforcer.setInitialDimensions({
                        width: scope.initialResizeDimensions.pixelWidth,
                        height: scope.initialResizeDimensions.pixelHeight,
                    });
                    aspectRatioEnforcer.calculate(targetWidth, targetHeight);
                    obj.pixelWidth = aspectRatioEnforcer.width;
                    obj.pixelHeight = aspectRatioEnforcer.height;
                } else {
                    obj.pixelWidth = targetWidth;
                    obj.pixelHeight = targetHeight;
                }

                if (scope.units === '%') {
                    // calcualate the new data values for x and y as ratios of the old values.
                    obj.dataWidth =
                        scope.initialResizeDimensions.dataWidth *
                        (obj.pixelWidth / scope.initialResizeDimensions.pixelWidth);
                    obj.dataHeight =
                        scope.initialResizeDimensions.dataHeight *
                        (obj.pixelHeight / scope.initialResizeDimensions.pixelHeight);
                } else {
                    obj.dataWidth = obj.pixelWidth;
                    obj.dataHeight = obj.pixelHeight;
                }

                // round the x and y values so they don't
                // have too many decimals for a ruby API
                obj.dataWidth = Number(obj.dataWidth.toFixed(5));
                obj.dataHeight = Number(obj.dataHeight.toFixed(5));

                obj.cssWidth = obj.dataWidth + scope.units;
                obj.cssHeight = obj.dataHeight + scope.units;

                return obj;
            };

            scope.getResizeStyles = () => {
                if (!scope.targetEl || scope.targetEl.length === 0) {
                    return;
                }
                return {
                    position: 'absolute',
                    top: '100%',
                    left: '100%',
                    // 'top': (scope.targetEl.position().top + scope.targetEl.height()) + 'px',
                    // 'left': (scope.targetEl.position().left + scope.targetEl.width()) + 'px',
                    'z-index': scope.targetEl.css('zIndex'),
                };
            };
        },
    }),
]);
