import { type Scholarship } from 'Scholarship';

export const scholarshipStubZenith200: Scholarship = {
    id: '895b9b47-1abf-4dc1-b7d2-3379da2e05cd',
    name: 'Level 200 The Zenith Award for Academic Excellence',
    amount: 2800,
    label: 'academic_excellence',
    level: 200,
} as Scholarship;

export const scholarshipStubValarImpact500: Scholarship = {
    id: 'ad1d7f46-9ff4-49c0-a79a-b2fac1f5b282',
    name: 'Level 500 Valar Impact Scholarship',
    amount: 6000,
    label: 'impact_valar',
    level: 500,
};

export const scholarshipStubStrada300: Scholarship = {
    id: 'c39d6ec5-16de-4e77-8b5c-8a52b82ab05f',
    name: 'Level 300 The Strada Leadership Scholarship',
    amount: 2800,
    label: 'strada',
    level: 200,
} as Scholarship;

export const scholarshipStubHyperion200: Scholarship = {
    id: 'f7012e69-bd8b-4556-a616-db6fc7167d04',
    name: 'Level 200 Hyperion Award for Entrepreneurs',
    amount: 2800,
    label: 'entrepreneurship',
    level: 200,
} as Scholarship;

export const scholarshipStubZenith150: Scholarship = {
    id: '8b3e6cd39-7ccd-45d2-b829-0272d7aa9311',
    name: 'Level 150 The Zenith Award for Academic Excellence',
    amount: 2100,
    label: 'academic_excellence',
    level: 150,
} as Scholarship;

export const fullScholarshipStub = {
    id: '1107e6d6-48f9-4cd5-8379-36d49c0a7c2e',
    name: 'Full Scholarship Powell Impact and Sustainability Scholarship',
    amount: 13300.0,
    label: 'sustainability',
    level: 99,
} as Scholarship;

export const cumulativeOfferedScholarshipStub = {
    id: '895b9b47-1abf-4dc1-b7d2-3379da2e05cd',
    name: 'Level 400 Hyperion Award for Entrepreneurs',
    amount: 5600,
    label: 'entrepreneurship',
    level: 400,
} as Scholarship;

export const cumulativeScholarshipStub200 = {
    id: 'fbba1162-4a5c-4e2f-a24c-bb572c4523fe',
    name: 'Level 200',
    amount: 2800,
    label: null,
    level: 200,
} as Scholarship;

export const cumulativeScholarshipStub400 = {
    id: '7127e91f-c863-4a63-8eba-e3afae474b01',
    name: 'Level 400',
    amount: 5600,
    label: null,
    level: 400,
} as Scholarship;

export const cumulativeFullScholarshipStub = {
    id: 'f3bd925c-8c4c-4dbf-b731-b65fa632317f',
    name: 'Level 700',
    amount: 9800,
    label: null,
    level: 99,
} as Scholarship;

export const offerableScholarships: Scholarship[] = [
    scholarshipStubZenith200,
    scholarshipStubHyperion200,
    scholarshipStubZenith150,
    fullScholarshipStub,
    cumulativeScholarshipStub200,
    cumulativeScholarshipStub400,
    cumulativeFullScholarshipStub,
    scholarshipStubStrada300,
];
