import 'NoApply/angularModule';
import 'ExtensionMethods/string';

export default angular.module('PiggybackOnNg', ['noApply']).factory('PiggybackOnNg', [
    '$injector',

    $injector => ({
        on(_directiveName, callback, eventName) {
            const directiveName = _directiveName;
            const directive = $injector.get(`${directiveName}Directive`)[0];
            const origCompile = directive.compile;
            eventName = eventName || directiveName.slice(2, 99999).toLowerCase();

            directive.compile = ($element, attr) => {
                const origFunc = origCompile($element, attr);
                // eslint-disable-next-line no-shadow
                return (scope, element, attr) => {
                    const _callback = function piggybackOnNg(event) {
                        callback(scope, element, attr, event);
                    };
                    element.on(eventName, _callback);

                    scope.$on('$destroy', () => {
                        element.off(eventName, _callback);
                    });

                    origFunc(scope, element, attr);
                };
            };

            if (directiveName.slice(0, 2) === 'ng') {
                this.on(`noApply${eventName.camelize(true)}`, callback, eventName);
            }
        },
    }),
]);
