
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.settings = window.Smartly.locales.modules.en.back_royal.settings || {};
window.Smartly.locales.modules.en.back_royal.settings.select_program = window.Smartly.locales.modules.en.back_royal.settings.select_program || {};
window.Smartly.locales.modules.en.back_royal.settings.select_program = {...window.Smartly.locales.modules.en.back_royal.settings.select_program, ...{
    "section_degree_programs": "Degree Programs",
    "section_exec_ed": "Executive Education",
    "section_exec_ed_intro": "Intro text goes here",
    "section_rise_to_c_suite": "Rise to the C-Suite",
    "section_rise_to_c_suite_intro": "Equip yourself with role-specific expertise needed to ascend to the highest levels of leadership. These programs are 8 months long and include ample opportunities to connect with amazing peers virtually and in-person. At just $7,600, they are radically affordable compared to other top C-suite certifications.",
    "section_finance_data": "Finance & Data",
    "section_finance_data_intro": "We offer a variety of courses designed to enhance your data, finance, or accounting skills, with options available to accommodate varying levels of quantitative proficiency. These certifications vary in terms of tuition fees and duration.",
    "section_ai_future_of_business": "AI & Future of Business",
    "section_ai_future_of_business_intro": "From adoption strategies, prompt engineering, to coding and prototyping, these programs are designed to give you the tools to leverage AI in your business effectively and efficiently.",
    "learn_more": "Learn More",
    "apply_now": "Apply Now",
    "register": "Register",
    "application_pending": "Application Pending",
    "unavailable": "Unavailable",
    "pending_degree_application_message": "Only one application may be submitted per admissions cycle. Please contact admissions at <a href=\"mailto:admissions@quantic.edu\">admissions@quantic.edu</a> if you would like to change your program of application.",
    "program_mba": "The MBA",
    "program_mba_description": "Accelerate your career with a rigorous program designed for high-potential, early-career learners eager to make a difference.",
    "program_emba": "The Executive MBA",
    "program_emba_description": "Gain key leadership and management skills while collaborating with experienced professionals from top companies around the world.",
    "program_msba": "MS Business Analytics",
    "program_msba_description": "This program is a catalyst for professionals seeking to blend their data-oriented background with strategic leadership.",
    "program_msba_preapproved_description": "A 13-month degree program thats serves as a career catalyst, blending data-oriented thinking with strategic business leadership.",
    "program_msse": "MS Software Engineering",
    "program_msse_description": "Master modern software engineering essentials, from cloud apps, machine learning, to AI engineering, and more.",
    "program_msse_preapproved_description": "A 13-month degree program to master modern software engineering essentials, from cloud apps and machine learning to AI engineering.",
    "program_cto_cert": "CTO Program",
    "program_cto_cert_description": "Ideal for rising technical leaders and aspiring future CTOs, you'll learn strategic roadmapping, architect cutting-edge tech infrastructure, and align technology initiatives with business goals to drive innovation and lead teams to success.",
    "program_founders_cert": "Founders Program",
    "program_founders_cert_description": "For established entrepreneurs and those just beginning their start-up journeys. In this program you’ll gain strategic foresight, disruptive thinking, and agile problem-solving skills to launch and scale ventures, secure funding, and craft pitches.",
    "program_executive_cert": "Executive Program",
    "program_executive_cert_description": "Embark upon your journey to becoming a next-gen business leader. In this program you’ll gain modern, holistic business acumen to excel in dynamic markets, lead across functions, and develop versatile skills that translate to commercial success.",
    "program_cmo_cert": "CMO Program",
    "program_cmo_cert_description": "Sharpen your marketing leadership as you harness data-driven insights, embrace digital transformation, and cultivate customer-centric strategies. You’ll learn to accelerate growth through innovation and thrive in today’s multifaceted marketing landscape.",
    "program_fin_for_non_fin_managers_cert": "Finance and Accounting for Managers",
    "program_fin_for_non_fin_managers_cert_description": "Develop skills to confidently communicate and propose business investments to decision-makers and financial experts. In this program you will gain a holistic understanding of financial analysis, essential for effective leadership and smart decision-making throughout your career.",
    "program_bus_analytics_leaders_cert": "Business Analytics for Leaders",
    "program_bus_analytics_leaders_cert_description": "Elevate your leadership prowess with the power of business analytics. Master data-driven decision-making, efficiently extract valuable insights, and turn data into impactful strategies. This program is tailored for mid-career professionals with a minimum of 3 years relevant experience.",
    "program_data_science_foundations_cert": "Data Science Foundations",
    "program_data_science_foundations_cert_description": "Future-proof your career with data expertise. Master Python, SQL, statistics, analytics, machine learning, and more as you build a strong conceptual foundation and craft end-to-end data science solutions using generative AI. Ideal for early to mid career professionals.",
    "program_tyb_ai_cert": "Transform Your Business with AI and ChatGPT",
    "program_tyb_ai_cert_description": "Equip yourself with AI skills to lead business transformation. Explore cutting-edge applications of AI and ChatGPT, analyze risks, prompt engineering, and architectural dynamics for AI integration. Stay current as this immensely impactful tech unfolds.",
    "program_prototype_gpt_cert": "AI for Building Software",
    "program_prototype_gpt_cert_description": "Master ChatGPT to efficiently prototype digital ventures without prior coding experience. Cultivate in-demand skills in AI-driven Web application development, validation, and testing. With this toolkit, you’ll accelerate your business with enhanced efficiency and insight.",
    "program_ai_for_technical_leaders_cert": "AI for Technical Leaders",
    "program_ai_for_technical_leaders_cert_description": "Gain a sophisticated understanding of the latest in AI and ChatGPT, LLM-based systems, model fine-tuning, and AI adoption. You’ll learn to make architectural choices, mitigate risks, and strategize around the systems and capabilities needed to deploy AI within your organization.",
    "program_tyb_blockchain_cert": "Transform Your Business with Blockchain",
    "program_tyb_blockchain_cert_description": "Learn blockchain fundamentals, understand key applications, and strategize blockchain adoption and integration. This program is ideal for managers and professionals seeking to understand  practical blockchain applications for their organization."
}
}
    