import { showExecEdEligibilityBundle } from 'AdmissionsGuidance/showExecEdEligibilityBundle';
import { useAngularContext } from 'AngularContext';
import { useNavigationHelper, useCurrentUserIguanaObject } from 'FrontRoyalAngular';
import FrontRoyalSpinner from 'FrontRoyalSpinner';
import { type ProgramType, ProgramTypeConfigs, ProgramFamily } from 'Program';
import { getProgramFamilyApplication } from 'ProgramFamilyApplication';
import parseHtml from 'html-react-parser';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCurrentlyPreventedFromSubmittingApplication, getHasPendingProgramApplication } from 'Users';
import { ClickAwayListener, Tooltip } from '@mui/material';
import useIsMobileMixin from 'StudentNetwork/react/useIsMobileMixin';
import { type ProgramListItem as ProgramListItemType } from '../selectProgram.types';

export function ProgramListItem({ item, preapproved = false }: { item: ProgramListItemType; preapproved?: boolean }) {
    const { t } = useTranslation('back_royal');
    const { loadRoute, loadUrl } = useNavigationHelper();
    const $injector = useAngularContext();
    const currentUser = useCurrentUserIguanaObject();
    const [loading, setLoading] = useState<boolean>(false);
    const { xsOrSm } = useIsMobileMixin();
    const programTypeForItem = item.programType;
    const isExecEdItem = ProgramTypeConfigs[programTypeForItem].isExecEd;
    const execEdEligibilityBundle = currentUser?.execEdEligibilityBundle;
    const execEdProgramFamilyApplication = useMemo(() => {
        if (!currentUser) return null;
        return getProgramFamilyApplication(currentUser, ProgramFamily.exec_ed_certificate);
    }, [currentUser]);

    const programTypesInSameFamily = useMemo(
        () =>
            Object.keys(ProgramTypeConfigs).filter(
                programType =>
                    ProgramTypeConfigs[programType as ProgramType].programFamily ===
                    ProgramTypeConfigs[programTypeForItem].programFamily,
            ) as ProgramType[],
        [programTypeForItem],
    );

    const degreeProgramTypes = useMemo(
        () =>
            Object.keys(ProgramTypeConfigs).filter(
                programType => ProgramTypeConfigs[programType as ProgramType].isDegreeProgram,
            ) as ProgramType[],
        [],
    );

    const applicationPendingForThisProgramFamily = useMemo(() => {
        if (isExecEdItem && !!execEdProgramFamilyApplication && !execEdEligibilityBundle?.activatedAt) return true;

        if (isExecEdItem) return false;

        const hasPendingApplicationInFamily = programTypesInSameFamily.some(programType =>
            getHasPendingProgramApplication(currentUser, { programType }),
        );

        return hasPendingApplicationInFamily;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        currentUser,
        currentUser?.programApplications, // an under-the-hood dependency
        isExecEdItem,
        execEdProgramFamilyApplication,
        execEdEligibilityBundle,
        programTypesInSameFamily,
    ]);

    const applicationPendingForAnyDegreeProgram = useMemo(
        () => degreeProgramTypes.some(programType => getHasPendingProgramApplication(currentUser, { programType })),
        [degreeProgramTypes, currentUser],
    );

    const preventedFromSubmitting = useMemo(() => {
        if (applicationPendingForThisProgramFamily) return true;

        // clicking Apply Now just opens the eligibility bundle modal, so don't prevent EE clicks unless pending.
        if (isExecEdItem) return false;

        const preventedFromSubmittingInFamily = programTypesInSameFamily.some(programType =>
            getCurrentlyPreventedFromSubmittingApplication(currentUser, { programType }),
        );

        return preventedFromSubmittingInFamily || applicationPendingForAnyDegreeProgram;
    }, [
        applicationPendingForThisProgramFamily,
        isExecEdItem,
        currentUser,
        programTypesInSameFamily,
        applicationPendingForAnyDegreeProgram,
    ]);

    const preventedMessage =
        !isExecEdItem && applicationPendingForAnyDegreeProgram && !applicationPendingForThisProgramFamily
            ? t('settings.select_program.pending_degree_application_message')
            : null;

    const handleApplyNowClick = useCallback(async () => {
        if (preapproved) {
            setLoading(true);
            await currentUser!.offerAdmissionAlumniQuanticLaunch(programTypeForItem);
            setLoading(false);
            loadRoute('/settings/application_status');
            return;
        }

        if (!isExecEdItem) {
            // Is a degree program; change the user's program state pref and redirect to the application page
            setLoading(true);
            await currentUser!.ensureAndChangePrefProgram(programTypeForItem);
            setLoading(false);
            loadRoute('/settings/application');
            return;
        }

        // Is an Exec Ed program

        if (execEdEligibilityBundle?.activatedAt) {
            // They have an activated bundle already; show the Congrats/Review and Register modal
            showExecEdEligibilityBundle($injector);
            return;
        }

        if (!execEdProgramFamilyApplication) {
            // They don't have an activated bundle and also no Exec Ed program family application;
            // redirect to the signup page
            const isLocal = window.location.hostname === 'localhost' && !window.CORDOVA;
            const isStaging = window.location.hostname.includes('staging');
            loadUrl(
                `${
                    isLocal
                        ? 'http://localhost:8000'
                        : isStaging
                        ? 'https://staging-quantic.com'
                        : 'https://quantic.edu'
                }/invite/exec-edu-signup`,
                '_blank',
            );
        }

        // Otherwise, they have a pending program family application; Apply Now button should
        // be disabled, but if they somehow click it, do nothing
    }, [
        loadRoute,
        loadUrl,
        currentUser,
        setLoading,
        isExecEdItem,
        programTypeForItem,
        $injector,
        execEdEligibilityBundle,
        execEdProgramFamilyApplication,
        preapproved,
    ]);

    const handleLearnMoreClick = useCallback(() => {
        loadUrl(item.learnMoreUrl, '_blank');
    }, [loadUrl, item]);

    return (
        <ProgramListItemPresentation
            item={item}
            handleApplyNowClick={handleApplyNowClick}
            handleLearnMoreClick={handleLearnMoreClick}
            loading={loading}
            applicationPendingForThisProgramFamily={applicationPendingForThisProgramFamily}
            preventedFromSubmitting={preventedFromSubmitting}
            ctaTooltipMessage={preventedMessage}
            xsOrSm={xsOrSm}
            preapproved={preapproved}
        />
    );
}

export function ProgramListItemPresentation({
    item,
    handleApplyNowClick,
    handleLearnMoreClick,
    loading,
    applicationPendingForThisProgramFamily,
    preventedFromSubmitting,
    ctaTooltipMessage,
    xsOrSm,
    preapproved = false,
}: {
    item: ProgramListItemType;
    handleApplyNowClick: () => Promise<void>;
    handleLearnMoreClick: () => void;
    loading: boolean;
    applicationPendingForThisProgramFamily: boolean;
    preventedFromSubmitting: boolean;
    ctaTooltipMessage: string | null;
    xsOrSm: boolean;
    preapproved?: boolean;
}) {
    const { t } = useTranslation('back_royal');
    const [mobileTooltipOpen, setMobileTooltipOpen] = useState(false);

    const ctaLocaleKey = useMemo(() => {
        if (preapproved) return 'settings.select_program.register';
        if (applicationPendingForThisProgramFamily) return 'settings.select_program.application_pending';
        if (preventedFromSubmitting) return 'settings.select_program.unavailable';
        return 'settings.select_program.apply_now';
    }, [preapproved, applicationPendingForThisProgramFamily, preventedFromSubmitting]);

    const ctaDisabled = applicationPendingForThisProgramFamily || preventedFromSubmitting;
    const itemDescriptionKey =
        preapproved && item.preapprovedDescription ? item.preapprovedDescription.key : item.description.key;

    const tooltipContents = (
        <>
            {ctaTooltipMessage && (
                <span className="tw-border-0 tw-bg-transparent tw-p-0 tw-outline-none">
                    {/* the yellow warning triangle emoji */}
                    ⚠️
                </span>
            )}
            <button
                type="button"
                onClick={handleApplyNowClick}
                data-testid="apply-now-button"
                disabled={ctaDisabled}
                className="apply-now tw-inline-flex tw-flex-wrap tw-content-end tw-justify-center tw-rounded tw-border-none tw-bg-transparent tw-p-1 tw-text-xs tw-text-coral disabled:tw-cursor-not-allowed disabled:tw-opacity-50"
            >
                {t(ctaLocaleKey).toLocaleUpperCase()}
            </button>
        </>
    );

    return (
        <div
            key={item.title.key}
            className="program-list-item tw-flex tw-h-auto tw-min-h-[300px] tw-w-[290px] tw-flex-col tw-justify-start tw-border tw-border-solid tw-border-[#f5f5f5] tw-bg-white tw-shadow-[0px_5px_5px_0px_rgba(0,0,0,.14)]"
        >
            {item.imageMode === 'center' && (
                <div className="image tw-flex tw-h-[157px] tw-w-full tw-items-center tw-justify-center tw-py-3">
                    <img className="tw-h-full" src={item.image} alt={t(item.title.key, item.title.params ?? {})!} />
                </div>
            )}
            {item.imageMode === 'cover' && (
                <div className="image tw-h-[157px] tw-w-full">
                    <img
                        className="tw-object-cover"
                        src={item.image}
                        alt={t(item.title.key, item.title.params ?? {})!}
                    />
                </div>
            )}
            <div className="content tw-px-4 tw-pb-8 tw-pt-4">
                <h3 className="tw-mb-4 tw-text-[18px] tw-font-normal">{t(item.title.key, item.title.params ?? {})}</h3>
                <div className="tw-mb-3 tw-text-xs tw-leading-normal tw-text-grey-shuttle">
                    {t(itemDescriptionKey, item.description.params ?? {})}
                </div>
            </div>
            <div className="buttons tw-mt-auto tw-flex tw-justify-between tw-p-4">
                <button
                    type="button"
                    data-testid="learn-more-button"
                    onClick={handleLearnMoreClick}
                    className="learn-more tw-flex tw-flex-wrap tw-content-end tw-justify-center tw-rounded tw-border-none tw-bg-transparent tw-p-1 tw-text-xs tw-text-blue "
                >
                    {/* The string in other locales is longer and overlaps if nowrap is applied; styling nowrap only for 'en' */}
                    <span className="lang-en:tw-whitespace-nowrap">
                        {t('settings.select_program.learn_more').toLocaleUpperCase()}
                    </span>
                </button>
                <div>
                    {loading ? (
                        <div className="spinner-wrapper tw-flex tw-items-center tw-justify-end">
                            <FrontRoyalSpinner className="no-top-margin no-delay" />
                        </div>
                    ) : (
                        <span className="tw-flex tw-items-center tw-justify-end">
                            {xsOrSm ? (
                                <ClickAwayListener onClickAway={() => setMobileTooltipOpen(false)}>
                                    <Tooltip
                                        open={mobileTooltipOpen}
                                        title={ctaTooltipMessage ? parseHtml(ctaTooltipMessage) : ''}
                                        PopperProps={{
                                            sx: {
                                                '& .MuiTooltip-tooltip': {
                                                    fontSize: '14px',
                                                    fontFamily: '"ProximaNovaSoft", sans-serif',
                                                    '& a': {
                                                        color: '#fff',
                                                        textDecoration: 'underline',
                                                    },
                                                },
                                            },
                                        }}
                                    >
                                        <span
                                            role="button"
                                            data-testid="tooltip-trigger"
                                            tabIndex={0}
                                            onKeyDown={e => {
                                                if (e.key === 'Enter') {
                                                    setMobileTooltipOpen(!mobileTooltipOpen);
                                                }
                                            }}
                                            onClick={() => setMobileTooltipOpen(!mobileTooltipOpen)}
                                        >
                                            {tooltipContents}
                                        </span>
                                    </Tooltip>
                                </ClickAwayListener>
                            ) : (
                                <Tooltip
                                    title={ctaTooltipMessage ? parseHtml(ctaTooltipMessage) : ''}
                                    PopperProps={{
                                        sx: {
                                            '& .MuiTooltip-tooltip': {
                                                fontSize: '14px',
                                                fontFamily: '"ProximaNovaSoft", sans-serif',
                                                '& a': {
                                                    color: '#fff',
                                                    textDecoration: 'underline',
                                                },
                                            },
                                        },
                                    }}
                                >
                                    <span data-testid="tooltip-trigger">{tooltipContents}</span>
                                </Tooltip>
                            )}
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
}
