export default angular.module('safeApply', []).factory('safeApply', [
    '$injector',

    $injector => {
        const $timeout = $injector.get('$timeout');
        const $rootScope = $injector.get('$rootScope');

        return (scope, fn, options) => {
            if (scope.$$destroyed) {
                return;
            }

            const phase = $rootScope.$$phase;
            options = options || {};

            if (phase === '$apply' || phase === '$digest') {
                if (fn && typeof fn === 'function') {
                    fn();
                }
            } else if (options.async) {
                $timeout(() => {});
            } else {
                scope.$apply(fn);
            }
        };
    },
]);

angular.module('safeApply').factory('safeDigest', [
    '$rootScope',

    $rootScope => (scope, fn) => {
        if (scope.$$destroyed) {
            return;
        }

        const phase = $rootScope.$$phase;
        if (phase === '$apply' || phase === '$digest') {
            if (fn && typeof fn === 'function') {
                fn();
            }
        } else {
            scope.$digest(fn);
        }
    },
]);
