import { ApplicationFormConfigsByProgramFamily } from 'FormConfigs';
import { type ProgramFamilyConfig, ProgramFamily } from './Program.types';

export const ProgramFamilyConfigs: Record<ProgramFamily, ProgramFamilyConfig> = {
    [ProgramFamily.master_of_business_administration]: {
        programFamily: ProgramFamily.master_of_business_administration,
        applicationFormConfigs: ApplicationFormConfigsByProgramFamily[ProgramFamily.master_of_business_administration],
        openCoursesSidebarBoxContentKey: 'content_open_courses_business_admin',
        applicationReceivedModalContinueCoursesKey: 'continue_courses_business_admin',
        programBoxNotAppliedMobileHtmlKey: 'mba_programs',
    },
    [ProgramFamily.master_of_science_in_business_analytics]: {
        programFamily: ProgramFamily.master_of_science_in_business_analytics,
        applicationFormConfigs:
            ApplicationFormConfigsByProgramFamily[ProgramFamily.master_of_science_in_business_analytics],
        openCoursesSidebarBoxContentKey: 'content_open_courses_business_analytics',
        applicationReceivedModalContinueCoursesKey: 'continue_courses_business_analytics',
        programBoxNotAppliedMobileHtmlKey: 'msba_program',
    },
    [ProgramFamily.master_of_science_in_software_engineering]: {
        programFamily: ProgramFamily.master_of_science_in_software_engineering,
        applicationFormConfigs:
            ApplicationFormConfigsByProgramFamily[ProgramFamily.master_of_science_in_software_engineering],
        openCoursesSidebarBoxContentKey: 'content_open_courses_software_engineering',
        applicationReceivedModalContinueCoursesKey: 'continue_courses_software_engineering',
        programBoxNotAppliedMobileHtmlKey: 'msse_program',
    },
    [ProgramFamily.exec_ed_certificate]: {
        programFamily: ProgramFamily.exec_ed_certificate,
        applicationFormConfigs: [],
    },
    [ProgramFamily.valar_leadership_accelerator]: {
        programFamily: ProgramFamily.valar_leadership_accelerator,
        applicationFormConfigs: [],
    },
    [ProgramFamily.the_business_certificate]: {
        programFamily: ProgramFamily.the_business_certificate,
        applicationFormConfigs: [],
    },
    [ProgramFamily.jordanian_math]: {
        programFamily: ProgramFamily.jordanian_math,
        applicationFormConfigs: [],
    },
    [ProgramFamily.career_network_only]: {
        programFamily: ProgramFamily.career_network_only,
        applicationFormConfigs: [],
    },
};
