import { formatCurrencyForDisplay } from 'Currency';
import { TuitionPlanFrequency } from 'TuitionPlan';
import { type PaymentSituation } from 'PaymentSituation';
import { type CurrentUserIguanaObject } from 'Users';
import { TuitionAndRegistrationSection } from 'TuitionAndRegistrationSection';
import klarnaIcon from 'vectors/registration/icon-klarna-color.svg';
import {
    type PaymentTypeOptionButton,
    type PaymentConfig,
    PaymentTypeOption,
    PaymentConfigState,
} from './TuitionAndRegistration.types';
import { KLARNA_AVAILABILITY_COUNTRIES, KLARNA_EXCLUDED_STATES } from './constants';

export function getPaymentConfigState({
    country,
    shouldChargeCreditCardPaymentFee,
}: {
    country: CurrentUserIguanaObject['country'];
    shouldChargeCreditCardPaymentFee: CurrentUserIguanaObject['shouldChargeCreditCardPaymentFee'];
}): PaymentConfigState {
    // While Puerto Rico is technically a US territory, GooglePlaces assigns it the
    // "country" code "PR" according to https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2#PR
    // Regardless, for the purpose of determining if ACH payments are available, we consider
    // it part of the US.
    // Note that other US territories (US Virgin Islands, American Samoa, Guam, etc.) are
    // also geocoded with country !== 'US' and for now we are not enabling ACH or charging
    // CC payment fees in those places.
    if (country !== 'US' && country !== 'PR') {
        return PaymentConfigState.non_us_no_cc_payment_fee;
    }

    if (!shouldChargeCreditCardPaymentFee) {
        return PaymentConfigState.us_no_cc_payment_fee;
    }

    return PaymentConfigState.us;
}

export function getPaymentConfig({
    paymentConfigState,
    selectedPaymentSituation,
    creditCardPaymentFeeRate,
    section,
    supportsBuyNowPayLater,
    country,
    state,
}: {
    paymentConfigState: PaymentConfigState;
    selectedPaymentSituation: PaymentSituation;
    creditCardPaymentFeeRate: number;
    section: TuitionAndRegistrationSection;
    supportsBuyNowPayLater: boolean;
    country: CurrentUserIguanaObject['country'];
    state: CurrentUserIguanaObject['state'];
}): PaymentConfig | null {
    if (!selectedPaymentSituation) return null;

    const basePaymentConfig = _getBasePaymentConfig(selectedPaymentSituation);
    const buttons = _getButtons({ selectedPaymentSituation, section, supportsBuyNowPayLater, country, state });

    const paymentConfigsByState: Record<PaymentConfigState, PaymentConfig> = {
        us: {
            ...basePaymentConfig,
            paymentConfigState: PaymentConfigState.us,
            paymentOptionButtons: [
                buttons.us_bank_account,
                {
                    ...buttons.card,
                    subtext: {
                        key: 'settings.tuition_and_registration.transaction_fee',
                        values: {
                            creditCardPaymentFeeRate: (creditCardPaymentFeeRate * 100).toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                            }),
                        },
                    },
                },
                buttons.buy_now_pay_later,
                buttons.wire_transfer,
            ].filter(button => button.shouldShow()),
        },
        us_no_cc_payment_fee: {
            ...basePaymentConfig,
            paymentConfigState: PaymentConfigState.us_no_cc_payment_fee,
            paymentOptionButtons: [
                buttons.us_bank_account,
                buttons.card,
                buttons.buy_now_pay_later,
                buttons.wire_transfer,
            ].filter(button => button.shouldShow()),
        },
        non_us_no_cc_payment_fee: {
            ...basePaymentConfig,
            paymentConfigState: PaymentConfigState.non_us_no_cc_payment_fee,
            paymentOptionButtons: [buttons.card, buttons.buy_now_pay_later, buttons.wire_transfer].filter(button =>
                button.shouldShow(),
            ),
        },
    };

    return paymentConfigsByState[paymentConfigState];
}

export function getDefaultSelectedPaymentOption(paymentConfig?: PaymentConfig | null): PaymentTypeOption | null {
    if (!paymentConfig) return null;

    // If there is only one option, auto-set the default value
    if (paymentConfig.availablePaymentTypeOptions().length === 1) return paymentConfig.availablePaymentTypeOptions()[0];

    // US Bank Account is recommended when available
    if (paymentConfig.availablePaymentTypeOptions().includes(PaymentTypeOption.us_bank_account))
        return PaymentTypeOption.us_bank_account;

    // Otherwise there should be nothing selected by default
    return null;
}

export function _getBasePaymentConfig(
    selectedPaymentSituation: PaymentSituation,
): Omit<PaymentConfig, 'paymentOptionButtons' | 'paymentConfigState'> {
    const {
        tuitionPlan: { frequency, numIntervals },
        paymentInformation: { paymentAmountPerInterval, netDiscountAmount },
    } = selectedPaymentSituation;

    return {
        paymentSituation: selectedPaymentSituation,
        heading: {
            key: `settings.tuition_and_registration.selected_tuition_plan_${frequency}`,
            values: { paymentAmountPerInterval: formatCurrencyForDisplay(paymentAmountPerInterval) },
        },
        subheadingHtml: {
            key: `settings.tuition_and_registration.tuition_plan_detail_${frequency}`,
            values: { netDiscountAmount: formatCurrencyForDisplay(netDiscountAmount), numIntervals },
        },
        availablePaymentTypeOptions() {
            return (this as PaymentConfig).paymentOptionButtons?.map(button => button.paymentOption) || [];
        },
    };
}

export function _getButtons({
    selectedPaymentSituation,
    section,
    supportsBuyNowPayLater,
    country,
    state,
}: {
    selectedPaymentSituation: PaymentSituation;
    section: TuitionAndRegistrationSection;
    supportsBuyNowPayLater: boolean;
    country: CurrentUserIguanaObject['country'];
    state: CurrentUserIguanaObject['state'];
}): Record<PaymentTypeOption, PaymentTypeOptionButton> {
    const {
        tuitionPlan: { frequency },
    } = selectedPaymentSituation;

    const buyNowPayLaterIsAvailableInLocation =
        !!country && !!KLARNA_AVAILABILITY_COUNTRIES[country] && !KLARNA_EXCLUDED_STATES[state || ''];

    return {
        us_bank_account: {
            paymentOption: PaymentTypeOption.us_bank_account,
            icons: [{ type: 'fontawesome', className: 'fas fa-building-columns', shouldShow: () => true }],
            title: { key: 'settings.tuition_and_registration.us_bank_account' },
            shouldShow: () => true,
        },
        card: {
            paymentOption: PaymentTypeOption.card,
            icons: [{ type: 'fontawesome', className: 'fas fa-credit-card', shouldShow: () => true }],
            title: { key: 'settings.tuition_and_registration.card' },
            shouldShow: () => true,
        },
        buy_now_pay_later: {
            paymentOption: PaymentTypeOption.buy_now_pay_later,
            icons: [
                {
                    type: 'image',
                    path: klarnaIcon,
                    shouldShow: () => true,
                },
            ],
            title: { key: 'settings.tuition_and_registration.buy_now_pay_later' },
            shouldShow: () =>
                frequency === TuitionPlanFrequency.Once &&
                supportsBuyNowPayLater &&
                buyNowPayLaterIsAvailableInLocation,
        },
        wire_transfer: {
            paymentOption: PaymentTypeOption.wire_transfer,
            icons: [{ type: 'fontawesome', className: 'fas fa-money-bill-transfer', shouldShow: () => true }],
            title: { key: 'settings.tuition_and_registration.wire_transfer' },
            shouldShow: () =>
                [TuitionPlanFrequency.Once, TuitionPlanFrequency.Annual].includes(frequency) &&
                [TuitionAndRegistrationSection.cohortRegistrationPlanSelection].includes(section),
        },
    };
}
