
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.search_engine_optimization = window.Smartly.locales.modules.en.back_royal.search_engine_optimization || {};
window.Smartly.locales.modules.en.back_royal.search_engine_optimization.site_metadata_mixin = window.Smartly.locales.modules.en.back_royal.search_engine_optimization.site_metadata_mixin || {};
window.Smartly.locales.modules.en.back_royal.search_engine_optimization.site_metadata_mixin = {...window.Smartly.locales.modules.en.back_royal.search_engine_optimization.site_metadata_mixin, ...{
    "default_share_title": "{{brandName}} - Earn a {{brandName}} MBA. Land Your Dream Job.",
    "default_share_description": "Go from corporate scrub to senior director in the time you binge-watched 3 seasons of Downton Abbey. Learn business smarts in a snackable format for free!",
    "default_share_tweet_template": "Marketable business skills in 5 minutes a day with {{twitterName}} #LearningByDoing #Freeducation",
    "default_share_tweet_template_quantic": "The business school of the future is here! Join global tech and business leaders in the {{twitterName}} degree programs. #ModernMBA",
    "default_share_tweet_template_miya_miya": "Learning to love math with bite-sized lessons from {{twitterName}}! #FreeEducation",
    "default_share_email_title": "Check this out",
    "default_share_email_title_miya_miya": "Learn with Miya Miya",
    "default_share_email_description": "I thought you might enjoy {{brandName}}, a new platform to help you quickly master essential business skills & land your dream job. . \n\nClick here to get started: {{canonicalUrl}}\n\n{{signature}}",
    "default_share_course_email_description": "Hi,\n\nI thought you might enjoy {{brandName}}, a new platform to help you master essential business skills & land your dream job.  \n\nI suggest you try {{courseTitle}}. {{courseDescription}}\n\nClick here to get started: {{canonicalUrl}}\n\n{{signature}}",
    "default_share_course_email_description_miya_miya": "Hi,\n\nI thought you might enjoy Miya Miya, a new platform to help you master math skills & ace your next school exam.\n\nI suggest you try {{courseTitle}}. {{courseDescription}}\n\nClick here to get started: {{canonicalUrl}}\n\n{{signature}}",
    "smartly_demo_twitter": "I learned Probability with {{twitterName}} in 4 minutes! #ProbabilityPro",
    "blue_ocean_share_title": "Learn Blue Ocean Strategy - Sign up",
    "blue_ocean_share_description": "Sign up to discover a bold business strategy that rewards innovation and casts competition aside. Learn how to implement a Blue Ocean Strategy with {{brandName}}!",
    "blue_ocean_share_tweet_template": "Master #BlueOcean Strategy with the new course by {{socialHashtag}}!  {{url}} via {{twitterName}} @BlueOceanStrtgy @HarvardBiz",
    "blue_ocean_share_email_title": "Check this out",
    "blue_ocean_share_email_description": "Hi,\n\nI thought you might like to take {{brandName}}’s mini course on Blue Ocean Strategy, the #1 selling business book of the last decade. \n\nIn this course you’ll learn how to create uncontested market space to make your business's competition irrelevant.\n\nHere's the link to {{brandName}}'s Blue Ocean Strategy: {{canonicalUrl}}\n\n{{signature}}"
}
}
    