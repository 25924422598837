// This function returns a measurement in ms of how far off the client's clock
// is from the true value i.e. the "offset".
export default function getServerClientTimeOffset(serverGeneratedTimestamp, timeSinceRequest, now) {
    now = now || Date.now();
    const timeSinceServerTimeGenerated = getTimeSinceServerTimeGenerated(timeSinceRequest);
    const serverTime = serverGeneratedTimestamp + timeSinceServerTimeGenerated;
    return now - serverTime;
}

function getTimeSinceServerTimeGenerated(timeSinceRequest) {
    // We assume that the server generated the time at exactly
    // the midpoint of the request.
    return timeSinceRequest / 2;
}
