import { monthlyPlan, annualPlan, oneTimePlan, fullScholarshipPlan } from 'TuitionPlan';

export const stubNoScholarshipQuanticPaymentSituations = [
    {
        tuitionPlan: oneTimePlan,
        cumulativeScholarshipAmount: 0,
        cumulativeScholarshipLevel: null,
        paymentInformation: {
            baseTuition: 13300,
            netScholarshipAmount: 0,
            netFeeAmount: 0,
            netDiscountAmount: 2100,
            netRequiredPayment: 11200,
            paymentAmountPerInterval: 11200,
            initialBalance: 0,
        },
        canReconcilePaymentsAutomatically: true,
    },
    {
        tuitionPlan: annualPlan,
        cumulativeScholarshipAmount: 0,
        cumulativeScholarshipLevel: null,
        paymentInformation: {
            baseTuition: 13300,
            netScholarshipAmount: 0,
            netFeeAmount: 0,
            netDiscountAmount: 1500,
            netRequiredPayment: 11800,
            paymentAmountPerInterval: 5900,
            initialBalance: 0,
        },
        canReconcilePaymentsAutomatically: true,
    },
    {
        tuitionPlan: monthlyPlan,
        cumulativeScholarshipAmount: 0,
        cumulativeScholarshipLevel: null,
        paymentInformation: {
            baseTuition: 13300,
            netScholarshipAmount: 0,
            netFeeAmount: 0,
            netDiscountAmount: 0,
            netRequiredPayment: 13300,
            paymentAmountPerInterval: 950,
            initialBalance: 0,
        },
        canReconcilePaymentsAutomatically: true,
    },
];

export const stubNoScholarshipQuanticBasePaymentSituation = {
    tuitionPlan: monthlyPlan,
    cumulativeScholarshipAmount: 0,
    cumulativeScholarshipLevel: null,
    paymentInformation: {
        baseTuition: 13300,
        netScholarshipAmount: 0,
        netFeeAmount: 0,
        netDiscountAmount: 0,
        netRequiredPayment: 13300,
        paymentAmountPerInterval: 950,
        initialBalance: 0,
    },
    canReconcilePaymentsAutomatically: true,
};

export const stubLevel150QuanticPaymentSituations = [
    {
        tuitionPlan: oneTimePlan,
        cumulativeScholarshipAmount: 2100,
        cumulativeScholarshipLevel: 150,
        paymentInformation: {
            baseTuition: 13300,
            netScholarshipAmount: 2100,
            netFeeAmount: 0,
            netDiscountAmount: 1700,
            netRequiredPayment: 9500,
            paymentAmountPerInterval: 9500,
            initialBalance: 0,
        },
        canReconcilePaymentsAutomatically: true,
    },
    {
        tuitionPlan: annualPlan,
        cumulativeScholarshipAmount: 2100,
        cumulativeScholarshipLevel: 150,
        paymentInformation: {
            baseTuition: 13300,
            netScholarshipAmount: 2100,
            netFeeAmount: 0,
            netDiscountAmount: 1000,
            netRequiredPayment: 10200,
            paymentAmountPerInterval: 5100,
            initialBalance: 0,
        },
        canReconcilePaymentsAutomatically: true,
    },
    {
        tuitionPlan: monthlyPlan,
        cumulativeScholarshipAmount: 2100,
        cumulativeScholarshipLevel: 150,
        paymentInformation: {
            baseTuition: 13300,
            netScholarshipAmount: 2100,
            netFeeAmount: 0,
            netDiscountAmount: 0,
            netRequiredPayment: 11200,
            paymentAmountPerInterval: 800,
            initialBalance: 0,
        },
        canReconcilePaymentsAutomatically: true,
    },
];

export const stubLevel150QuanticBasePaymentSituation = {
    tuitionPlan: monthlyPlan,
    cumulativeScholarshipAmount: 2100,
    cumulativeScholarshipLevel: 150,
    paymentInformation: {
        baseTuition: 13300,
        netScholarshipAmount: 2100,
        netFeeAmount: 0,
        netDiscountAmount: 0,
        netRequiredPayment: 11200,
        paymentAmountPerInterval: 800,
        initialBalance: 0,
    },
    canReconcilePaymentsAutomatically: true,
};

export const stubLevel200QuanticPaymentSituations = [
    {
        tuitionPlan: oneTimePlan,
        cumulativeScholarshipAmount: 2800,
        cumulativeScholarshipLevel: 200,
        paymentInformation: {
            baseTuition: 13300,
            netScholarshipAmount: 2800,
            netFeeAmount: 0,
            netDiscountAmount: 1200,
            netRequiredPayment: 9300,
            paymentAmountPerInterval: 9300,
            initialBalance: 0,
        },
        canReconcilePaymentsAutomatically: true,
    },
    {
        tuitionPlan: annualPlan,
        cumulativeScholarshipAmount: 2800,
        cumulativeScholarshipLevel: 200,
        paymentInformation: {
            baseTuition: 13300,
            netScholarshipAmount: 2800,
            netFeeAmount: 0,
            netDiscountAmount: 700,
            netRequiredPayment: 9800,
            paymentAmountPerInterval: 4900,
            initialBalance: 0,
        },
        canReconcilePaymentsAutomatically: true,
    },
    {
        tuitionPlan: monthlyPlan,
        cumulativeScholarshipAmount: 2800,
        cumulativeScholarshipLevel: 200,
        paymentInformation: {
            baseTuition: 13300,
            netScholarshipAmount: 2800,
            netFeeAmount: 0,
            netDiscountAmount: 0,
            netRequiredPayment: 10500,
            paymentAmountPerInterval: 750,
            initialBalance: 0,
        },
        canReconcilePaymentsAutomatically: true,
    },
];

export const stubLevel200QuanticBasePaymentSituation = {
    tuitionPlan: monthlyPlan,
    cumulativeScholarshipAmount: 2800,
    cumulativeScholarshipLevel: 200,
    paymentInformation: {
        baseTuition: 13300,
        netScholarshipAmount: 2800,
        netFeeAmount: 0,
        netDiscountAmount: 0,
        netRequiredPayment: 10500,
        paymentAmountPerInterval: 750,
        initialBalance: 0,
    },
    canReconcilePaymentsAutomatically: true,
};

export const stubLevel400QuanticPaymentSituations = [
    {
        tuitionPlan: oneTimePlan,
        cumulativeScholarshipAmount: 5600,
        cumulativeScholarshipLevel: 400,
        paymentInformation: {
            baseTuition: 13300,
            netScholarshipAmount: 5600,
            netFeeAmount: 0,
            netDiscountAmount: 1000,
            netRequiredPayment: 6700,
            paymentAmountPerInterval: 6700,
            initialBalance: 0,
        },
        canReconcilePaymentsAutomatically: true,
    },
    {
        tuitionPlan: annualPlan,
        cumulativeScholarshipAmount: 5600,
        cumulativeScholarshipLevel: 400,
        paymentInformation: {
            baseTuition: 13300,
            netScholarshipAmount: 5600,
            netFeeAmount: 0,
            netDiscountAmount: 500,
            netRequiredPayment: 7200,
            paymentAmountPerInterval: 3600,
            initialBalance: 0,
        },
        canReconcilePaymentsAutomatically: true,
    },
    {
        tuitionPlan: monthlyPlan,
        cumulativeScholarshipAmount: 5600,
        cumulativeScholarshipLevel: 400,
        paymentInformation: {
            baseTuition: 13300,
            netScholarshipAmount: 5600,
            netFeeAmount: 0,
            netDiscountAmount: 0,
            netRequiredPayment: 7700,
            paymentAmountPerInterval: 550,
            initialBalance: 0,
        },
        canReconcilePaymentsAutomatically: true,
    },
];

export const stubLevel400QuanticBasePaymentSituation = {
    tuitionPlan: monthlyPlan,
    cumulativeScholarshipAmount: 5600,
    cumulativeScholarshipLevel: 400,
    paymentInformation: {
        baseTuition: 13300,
        netScholarshipAmount: 5600,
        netFeeAmount: 0,
        netDiscountAmount: 0,
        netRequiredPayment: 7700,
        paymentAmountPerInterval: 550,
        initialBalance: 0,
    },
    canReconcilePaymentsAutomatically: true,
};

export const stubFullScholarshipQuanticPaymentSituations = [
    {
        tuitionPlan: fullScholarshipPlan,
        cumulativeScholarshipAmount: 13300,
        cumulativeScholarshipLevel: 99,
        paymentInformation: {
            baseTuition: 13300,
            netScholarshipAmount: 13300,
            netFeeAmount: 0,
            netDiscountAmount: 0,
            netRequiredPayment: 0,
            paymentAmountPerInterval: 0,
            initialBalance: 0,
        },
        canReconcilePaymentsAutomatically: true,
    },
];

export const stubFullScholarshipQuanticBasePaymentSituation = {
    tuitionPlan: monthlyPlan,
    cumulativeScholarshipAmount: 0,
    cumulativeScholarshipLevel: null,
    paymentInformation: {
        baseTuition: 13300,
        netScholarshipAmount: 0,
        netFeeAmount: 0,
        netDiscountAmount: 0,
        netRequiredPayment: 13300,
        paymentAmountPerInterval: 950,
        initialBalance: 0,
    },
    canReconcilePaymentsAutomatically: true,
};
