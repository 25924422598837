import {
    type PaymentConfig,
    type RegisterButton,
    PaymentView,
    RegisterButtonState,
    PaymentTypeOption,
} from './TuitionAndRegistration.types';

type MixedScopeParams = {
    processingRegistration: boolean | undefined;
    registerRequiresRedirectToBrowser: boolean | undefined;
    paymentConfig: PaymentConfig | undefined;
    activePaymentView: PaymentView | undefined;
    selectedPaymentType: PaymentTypeOption | null | undefined;
    defaultState: RegisterButtonState;
};

export function getRegisterButtonState({
    processingRegistration,
    registerRequiresRedirectToBrowser,
    paymentConfig,
    activePaymentView,
    selectedPaymentType,
    defaultState,
}: MixedScopeParams): RegisterButtonState {
    if (registerRequiresRedirectToBrowser) {
        return RegisterButtonState.requires_redirect_to_browser;
    }

    if (processingRegistration) {
        return RegisterButtonState.processing;
    }

    if (activePaymentView === PaymentView.select_payment && selectedPaymentType === PaymentTypeOption.wire_transfer) {
        return RegisterButtonState.wire_transfer_instructions;
    }

    if (
        activePaymentView === PaymentView.select_plan &&
        paymentConfig &&
        paymentConfig.availablePaymentTypeOptions().length > 1
    ) {
        return RegisterButtonState.continue_to_register;
    }

    return defaultState;
}

type GetRegisterButtonProps = {
    registerButtonState: RegisterButtonState;
};

export function getRegisterButton({ registerButtonState }: GetRegisterButtonProps): RegisterButton {
    const configsByState: Record<RegisterButtonState, RegisterButton> = {
        processing: {
            registerButtonState: RegisterButtonState.processing,
            text: { key: 'settings.tuition_and_registration.processing' },
        },
        requires_redirect_to_browser: {
            registerButtonState: RegisterButtonState.requires_redirect_to_browser,
            text: { key: 'settings.tuition_and_registration.launch_browser' },
        },
        continue_to_register: {
            registerButtonState: RegisterButtonState.continue_to_register,
            text: { key: 'settings.tuition_and_registration.continue_to_register' },
            iconClassname: 'fa-light fa-arrow-right',
        },
        review_and_register: {
            registerButtonState: RegisterButtonState.review_and_register,
            text: { key: 'settings.tuition_and_registration.review_and_register' },
        },
        register: {
            registerButtonState: RegisterButtonState.register,
            text: { key: 'settings.tuition_and_registration.register' },
        },
        wire_transfer_instructions: {
            registerButtonState: RegisterButtonState.wire_transfer_instructions,
            text: { key: 'settings.tuition_and_registration.button_view_wire_transfer_instructions' },
        },
    };

    return configsByState[registerButtonState];
}
