
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.settings = window.Smartly.locales.modules.en.back_royal.settings || {};
window.Smartly.locales.modules.en.back_royal.settings.change_password = window.Smartly.locales.modules.en.back_royal.settings.change_password || {};
window.Smartly.locales.modules.en.back_royal.settings.change_password = {...window.Smartly.locales.modules.en.back_royal.settings.change_password, ...{
    "change_password": "Change Password",
    "password": "Password",
    "password_confirmation": "Password Confirmation",
    "re_type": "Retype",
    "save_changes": "SAVE CHANGES",
    "password_updated": "Password successfully updated.",
    "try_again": "Please check your password and confirmation and try again."
}
}
    