import getServerClientTimeOffset from './getServerClientTimeOffset';

const key = 'serverClientTimeOffset';

// The earlier this is called during pageload, the better.  This is because
// the smaller that window.performance.now() is, the more precise we
// can be about the server time
export function ensureServerClientTimeOffsetOnWindow() {
    if (!window[key]) {
        const val = getServerClientTimeOffset(window.serverGeneratedTimestamp, window.performance.now());
        setServerClientTimeOffetOnWindow(val);
    }

    return window[key];
}

export function setServerClientTimeOffetOnWindow(val) {
    window[key] = val;
}
