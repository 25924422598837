import { type QueryArgFrom, type EndpointDefinitions } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import {
    type Api,
    type BaseQueryFn,
    type FetchArgs,
    type FetchBaseQueryError,
} from '@reduxjs/toolkit/dist/query/react';
import { type AnyObject } from '@Types';

type ApiResultType<
    BaseQuery extends BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
    Definitions extends EndpointDefinitions,
    Path extends string,
    TagTypes extends string,
    Name extends keyof Api<BaseQuery, Definitions, Path, TagTypes>['endpoints'],
> = Api<BaseQuery, Definitions, Path, TagTypes>['endpoints'][Name]['Types']['ResultType'];

type QueryResult<
    BaseQuery extends BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
    Definitions extends EndpointDefinitions,
    Path extends string,
    TagTypes extends string,
    Name extends keyof Api<BaseQuery, Definitions, Path, TagTypes>['endpoints'],
> = {
    data: ApiResultType<BaseQuery, Definitions, Path, TagTypes, Name>;
    isFetching: boolean;
    refetch: () => void;
};

function useQueryHooks<
    BaseQuery extends BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
    Definitions extends EndpointDefinitions,
    Path extends string,
    TagTypes extends string,
    Name extends keyof Api<BaseQuery, Definitions, Path, TagTypes>['endpoints'],
    Params extends QueryArgFrom<Definitions[Name]>,
>({ api, endpoint, params }: { api: Api<BaseQuery, Definitions, Path, TagTypes>; endpoint: Name; params?: Params }) {
    type ResultType = ApiResultType<BaseQuery, Definitions, Path, TagTypes, Name>;
    const endpointDefinition = api.endpoints[endpoint];

    // useQuery gives us the status and result of the data loading. When the results of useQuery tells us that
    // loading is in progress, we need a promise we can throw to trigger suspense. useLazyQuery gives us that function

    // FIXME: it would be better if we didn't have to cast these types ourselves. There's gotta be a better way
    const useQuery = (endpointDefinition as unknown as AnyObject).useQuery as unknown as (
        params?: Params,
    ) => ResultType;

    const useLazyQuery = (endpointDefinition as unknown as AnyObject).useLazyQuery as unknown as () => [
        (params?: Params) => Promise<ResultType>,
    ];

    const { data, isError, isLoading, error, isFetching } = useQuery(params);
    const [trigger] = useLazyQuery();

    return {
        trigger,
        data,
        isError,
        error,
        isLoading,
        isFetching,
    };
}

export function useSuspenseQuery<
    BaseQuery extends BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
    Definitions extends EndpointDefinitions,
    Path extends string,
    TagTypes extends string,
    Name extends keyof Api<BaseQuery, Definitions, Path, TagTypes>['endpoints'],
    Params extends QueryArgFrom<Definitions[Name]>,
>(
    api: Api<BaseQuery, Definitions, Path, TagTypes>,
    endpoint: Name,
    params?: Params,
): QueryResult<BaseQuery, Definitions, Path, TagTypes, Name> {
    const { trigger, data, isError, error, isLoading, isFetching } = useQueryHooks({ api, endpoint, params });

    if (isLoading) {
        const loadingPromise = trigger(params);
        throw loadingPromise;
    }
    if (isError) throw error;

    return {
        data,

        // is fetching is true when there is already data, but we are fetching new data
        isFetching,
        refetch: () => trigger(params),
    };
}

// type MutationResult<
//     BaseQuery extends BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
//     Definitions extends EndpointDefinitions,
//     Path extends string,
//     TagTypes extends string,
//     Name extends keyof Api<BaseQuery, Definitions, Path, TagTypes>['endpoints'],
// > = {
//     data: ApiResultType<BaseQuery, Definitions, Path, TagTypes, Name>;
//     error: unknown;
// };
// type MutationCallback<
//     BaseQuery extends BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
//     Definitions extends EndpointDefinitions,
//     Path extends string,
//     TagTypes extends string,
//     Name extends keyof Api<BaseQuery, Definitions, Path, TagTypes>['endpoints'],
// > = (
//     params: Api<BaseQuery, Definitions, Path, TagTypes>['endpoints'][Name]['Types']['QueryArg'],
// ) => Promise<Api<BaseQuery, Definitions, Path, TagTypes>['endpoints'][Name]['Types']['ResultType']>;

// type UseSuspenseMutationReturnValue<
//     BaseQuery extends BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
//     Definitions extends EndpointDefinitions,
//     Path extends string,
//     TagTypes extends string,
//     Name extends keyof Api<BaseQuery, Definitions, Path, TagTypes>['endpoints'],
// > = [
//     MutationCallback<BaseQuery, Definitions, Path, TagTypes, Name>,
//     MutationResult<BaseQuery, Definitions, Path, TagTypes, Name>,
// ];

// This implementation seemed to be working at some point. After that, I found issues in
// useSuspenseQuery and refactored it. useSuspenseMutation might still work as-is. Would need to be re-tested though
// export function useSuspenseMutation<
//     BaseQuery extends BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
//     Definitions extends EndpointDefinitions,
//     Path extends string,
//     TagTypes extends string,
//     Name extends keyof Api<BaseQuery, Definitions, Path, TagTypes>['endpoints'],
// >(
//     api: Api<BaseQuery, Definitions, Path, TagTypes>,
//     endpoint: Name,
// ): UseSuspenseMutationReturnValue<BaseQuery, Definitions, Path, TagTypes, Name> {
//     type ResultType = ApiResultType<BaseQuery, Definitions, Path, TagTypes, Name>;

//     const [promise, setPromise] = useState<Promise<ResultType> | null>(null);
//     const [data, setData] = useState<ResultType | null>(null);
//     const [error, setError] = useState<unknown | null>(null);
//     const dispatch = useDispatch();

//     const send = useCallback(
//         (params: Api<BaseQuery, Definitions, Path, TagTypes>['endpoints'][Name]['Types']['QueryArg']) => {
//             const newPromise = makeRequest({ api, endpoint, params, dispatch }).then(setData, setError);
//             setPromise(newPromise);
//             return newPromise;
//         },
//         [api, endpoint, dispatch],
//     );

//     const isLoading = promise && !data && !error;
//     if (error) throw error;
//     if (isLoading) throw promise;

//     return [
//         send,
//         {
//             data,
//             error,
//         },
//     ];
// }
