import angularModule from 'Settings/angularModule/scripts/settings_module';
import template from 'Settings/angularModule/views/tuition_and_registration/cohort_registration_tuition_ledger.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { paymentSituationWithFormattedValues } from 'PaymentSituation';
import { isFullScholarship, isPartialScholarship } from 'Scholarship';
import { formatCurrencyForDisplay } from 'Currency';
import { getSplitTestConfig } from 'SplitTesting';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('cohortRegistrationTuitionLedger', [
    '$injector',

    function factory($injector) {
        const TranslationHelper = $injector.get('TranslationHelper');
        const Cohort = $injector.get('Cohort');
        const $rootScope = $injector.get('$rootScope');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                admissionOffer: '<',
                cohortSectionOffer: '<',
            },
            link(scope) {
                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                    configurable: true,
                });

                const translationHelper = new TranslationHelper('settings.tuition_and_registration');

                scope.hasPartialScholarship = isPartialScholarship(
                    scope.cohortSectionOffer.cumulativeOfferedScholarship,
                );
                scope.hasFullScholarship = isFullScholarship(scope.cohortSectionOffer.cumulativeOfferedScholarship);
                scope.hasNoScholarship = !scope.hasPartialScholarship && !scope.hasFullScholarship;

                const basePaymentSituation = paymentSituationWithFormattedValues(
                    scope.cohortSectionOffer.basePaymentSituation,
                );

                scope.tuitionLedgerRows = [];

                // As of right now, only ExecEd programs hide their program title in the
                // base tuition row of the tuition ledger. If this logic needs to expand to other program types,
                // it may be a better idea to create a schedulable.js flag to control this behavior.
                const hideProgramTitleInBaseTuitionRow = Cohort.isExecEd(scope.admissionOffer.programType);
                const titleLocaleKey = (() => {
                    let key = 'base_tuition';

                    if (scope.hasNoScholarship) {
                        key += '_no_scholarship';
                    } else if (hideProgramTitleInBaseTuitionRow) {
                        key += '_no_program_title';
                    }

                    return key;
                })();

                const baseTuitionRow = {
                    titleLocale: translationHelper.get(titleLocaleKey, {
                        programTitle: Cohort.fullTitle(scope.admissionOffer.programType),
                    }),
                    costOrDiscountLocale: translationHelper.get('cost_of_ledger_item', {
                        value: basePaymentSituation.paymentInformation.formattedFullTuitionPaymentPerInterval,
                    }),
                    totalAmountLocale: translationHelper.get('total_amount', {
                        numIntervals: basePaymentSituation.tuitionPlan.numIntervals,
                        totalAmount: formatCurrencyForDisplay(basePaymentSituation.paymentInformation.baseTuition),
                    }),
                    className: 'base-tuition-row',
                };

                scope.tuitionLedgerRows.push(baseTuitionRow);

                if (scope.hasPartialScholarship || scope.hasFullScholarship) {
                    const scholarshipTranslationHelper = new TranslationHelper('scholarship_labels');

                    const scholarshipRows = scope.cohortSectionOffer.offeredScholarships?.map(scholarship => {
                        const impactGlobalTestConfig = getSplitTestConfig(
                            'impactGlobalScholarshipTest',
                            scope.currentUser,
                        );

                        const impactLabelOverrideLocale =
                            /impact/i.test(scholarship.label) && impactGlobalTestConfig.impactLocaleOverride;

                        // See https://trello.com/c/MzuW1e1J
                        const meritLabelOverride =
                            ['strada', 'zenith'].includes(scholarship.label) && scope.admissionOffer.meritLabelOverride;

                        return {
                            titleLocale:
                                meritLabelOverride ||
                                scholarshipTranslationHelper.getWithFallbackKey(
                                    impactLabelOverrideLocale || scholarship.label,
                                    // Fallback if scholarshipLabel isn't defined in the JSON for the current user's scholarship
                                    'base_scholarship',
                                    {},
                                    {},
                                    undefined,
                                    undefined,
                                    false,
                                ),
                            costOrDiscountLocale: translationHelper.get('discount_of_ledger_item', {
                                value: scholarship.amount / basePaymentSituation.tuitionPlan.numIntervals,
                            }),
                            className: 'scholarship-row',
                        };
                    });

                    const totalTuitionRow = {
                        titleLocale: translationHelper.get('total_tuition'),
                        costOrDiscountLocale: translationHelper.get('cost_of_ledger_item', {
                            value: scope.hasFullScholarship
                                ? '0'
                                : basePaymentSituation.paymentInformation.formattedPaymentAmountPerInterval,
                        }),
                        totalAmountLocale: scope.hasFullScholarship
                            ? translationHelper.get('full_scholarship')
                            : translationHelper.get('total_amount', {
                                  numIntervals: basePaymentSituation.tuitionPlan.numIntervals,
                                  totalAmount: formatCurrencyForDisplay(
                                      basePaymentSituation.paymentInformation.netRequiredPayment,
                                  ),
                              }),
                        className: 'total-tuition-row',
                    };

                    scope.tuitionLedgerRows.push(...scholarshipRows, totalTuitionRow);
                }
            },
        };
    },
]);
