export const SMARTLY_DOMAIN = 'smart.ly';
export const SMARTLY_APP_DOMAIN = `app.${SMARTLY_DOMAIN}`;
export const SMARTLY_DOMAIN_STAGING = 'staging-smartly.com';
export const SMARTLY_APP_DOMAIN_STAGING = `app.${SMARTLY_DOMAIN_STAGING}`;
export const SMARTLY_UPLOADS_DOMAIN = `uploads.${SMARTLY_DOMAIN}`;

// If we update this, we also need to update python_modules/institution/institutions.py in tutorbot
export const QUANTIC_DOMAIN = 'quantic.edu';
export const QUANTIC_APP_DOMAIN = `app.${QUANTIC_DOMAIN}`;
export const QUANTIC_CHINA_DOMAIN = 'quantic.cn';
export const QUANTIC_CHINA_APP_DOMAIN = `app.${QUANTIC_CHINA_DOMAIN}`;
export const QUANTIC_CHINA_UPLOADS_DOMAIN = `uploads.${QUANTIC_CHINA_DOMAIN}`;
export const QUANTIC_DOMAIN_STAGING = 'staging-quantic.com';
export const QUANTIC_APP_DOMAIN_STAGING = `app.${QUANTIC_DOMAIN_STAGING}`;
export const QUANTIC_CHINA_DOMAIN_STAGING = 'cn.staging-quantic.com';

// NOTE that this app domain is using `app-` instead of `app.` like other ones
export const QUANTIC_CHINA_APP_DOMAIN_STAGING = `app-${QUANTIC_CHINA_DOMAIN_STAGING}`;

export const NON_INSTITUTIONAL_SUBDOMAINS = ['app', 'cn', 'app-'];

export const QUANTIC_BLOG_DOMAIN = `blog.${QUANTIC_DOMAIN}`;
export const QUANTIC_STUDENT_EMAIL_DOMAIN = `students.${QUANTIC_DOMAIN}`;

// FIXME: We should change this to the permanent Valar domain once we have access to it
// If we update this, we also need to update python_modules/institution/institutions.py in tutorbot
export const VALAR_DOMAIN = `valar.${QUANTIC_DOMAIN}`;
export const VALAR_DOMAIN_STAGING = `valar.${QUANTIC_DOMAIN_STAGING}`;

export const WHITELISTED_ALT = `whitelisted-alt.${QUANTIC_DOMAIN_STAGING}`;

export const WHITELISTED_REDIRECT_DOMAINS = [
    SMARTLY_DOMAIN,
    SMARTLY_APP_DOMAIN,
    SMARTLY_DOMAIN_STAGING,
    SMARTLY_APP_DOMAIN_STAGING,
    QUANTIC_DOMAIN,
    QUANTIC_APP_DOMAIN,
    QUANTIC_CHINA_DOMAIN,
    QUANTIC_CHINA_APP_DOMAIN,
    QUANTIC_DOMAIN_STAGING,
    QUANTIC_APP_DOMAIN_STAGING,
    QUANTIC_CHINA_DOMAIN_STAGING,
    QUANTIC_CHINA_APP_DOMAIN_STAGING,
    VALAR_DOMAIN,
    VALAR_DOMAIN_STAGING,
    WHITELISTED_ALT,
];

export function targetIsWhitelisted(target) {
    let url;
    try {
        url = new URL(target);
    } catch (_) {
        return false;
    }

    if (
        !window.CORDOVA &&
        (window.preloadedConfig?.app_env_name == null || window.preloadedConfig?.app_env_name === 'Smartly (Dev)')
    ) {
        // Whitelist local urls for web in development environment
        WHITELISTED_REDIRECT_DOMAINS.push(
            'localhost:3000',
            'localhost:3001',
            'localhost:8000',
            'localhost:8001',
            `${window.preloadedConfig?.ngrok_subdomain}.ngrok.io`,
        );
    }

    return WHITELISTED_REDIRECT_DOMAINS.some(domain => domain === url.host);
}

export function rootHost(config) {
    return config.isQuantic() ? QUANTIC_DOMAIN : SMARTLY_DOMAIN;
}

export function appHost(config) {
    return config.isQuantic() ? QUANTIC_APP_DOMAIN : SMARTLY_APP_DOMAIN;
}

export function generateCanonicalUrl(config, path, useAppSubdomain = false) {
    let canonicalUrl;
    const host = useAppSubdomain ? appHost(config) : rootHost(config);

    // The canonicalUrl may contain references to Quantic, so we dynamically
    // replace those references to be contextual for Smartly users since it
    // would be weird if the brand name in the canonicalRootDomain was different
    // than the brand name in the canonicalUrl.
    path = config.isQuantic() ? path : path.replace('quantic', 'smartly');
    if (path.indexOf('/') === 0) {
        canonicalUrl = `https://${host}${path}`;
    } else {
        canonicalUrl = `https://${host}/${path}`;
    }
    return canonicalUrl;
}
