import angularModule from 'Settings/angularModule/scripts/settings_module';
import template from 'Settings/angularModule/views/sign_out.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('signOut', [
    function factory() {
        return {
            restrict: 'E',
            templateUrl,
            scope: {},
            link() {},
        };
    },
]);
