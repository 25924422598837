
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.settings = window.Smartly.locales.modules.en.back_royal.settings || {};
window.Smartly.locales.modules.en.back_royal.settings.transcripts = window.Smartly.locales.modules.en.back_royal.settings.transcripts || {};
window.Smartly.locales.modules.en.back_royal.settings.transcripts = {...window.Smartly.locales.modules.en.back_royal.settings.transcripts, ...{
    "official_transcript": "Official Transcript",
    "partial_transcript": "Partial Transcript",
    "mobile_desc": "Launch {{brandName}} on a desktop browser to download transcripts.",
    "official_desc": "Download a digital copy of your official transcript below. For questions and special requests, contact us at <a href=\"mailto:{{brandEmail}}\" target=\"_blank\">{{brandEmail}}</a>",
    "partial_desc": "Download a digital copy of your partial transcript below.",
    "download_official_transcript": "Download Official Transcript",
    "download_partial_transcript": "Download Partial Transcript"
}
}
    