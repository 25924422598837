import { type PublishedPlaylist } from 'FrontRoyalStore';
import transformKeyCase from 'Utils/transformKeyCase';
import { type PlaylistIguanaObject, type Playlist } from './Playlists.types';

export const camelCasePlaylist = (playlist: PublishedPlaylist | undefined | null): Playlist | undefined | null => {
    if (!playlist) return playlist;
    return transformKeyCase(playlist, { to: 'camelCase' });
};

export function snakeCasePlaylist(playlist: Playlist | undefined | null): PublishedPlaylist | undefined | null {
    if (!playlist) return playlist;
    return transformKeyCase(playlist, { to: 'snakeCase' });
}

export function playlistIguanaObjectToCamelCase(
    playlist: PlaylistIguanaObject | undefined | null,
): Playlist | undefined | null {
    if (!playlist) return playlist;

    return camelCasePlaylist({
        ...playlist.asJson(),
        locale_pack_id: playlist.locale_pack.id,
    });
}
