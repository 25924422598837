/* eslint-disable import/prefer-default-export */
import { AoiRecordType } from 'ProgramAoi';
import { TuitionAndRegistrationSection, getTuitionAndRegistrationSection } from 'TuitionAndRegistrationSection';
import { earlyRegistrationDeadlineMs, offeredAdmission } from 'AdmissionOffer';
import { subscriptionIncomplete, subscriptionPastDue } from 'Subscription';
import { getTuitionContract, getAoiRecord, getAdmissionOffer } from 'Users';

const BURSAR_USERNAME = 'billing';

export function getHeaderParamsForTuitionAndRegistration(injector) {
    const currentUser = injector.get('$rootScope').currentUser;
    const body = getTuitionAndRegistrationSection(currentUser);
    if (body === null) {
        return null;
    }

    const TranslationHelper = injector.get('TranslationHelper');
    const translationHelper = new TranslationHelper('settings.settings');

    const admissionOffer = getAoiRecord(currentUser, AoiRecordType.AdmissionOffer);
    const subscription = getTuitionContract(currentUser)?.subscription;

    // specialCase means we don't think the student can fix their payment situation through the
    // student UI, and needs to contact the bursar.  This can happen if an included user has no
    // subscription or grace period, and the remaining balance on the tuition contract is less
    // than one payment.
    if (body === TuitionAndRegistrationSection.specialCase) {
        return getContactBursarHeaderParams(currentUser, translationHelper);
    }

    // If a user has successfully completed a Stripe Checkout session,
    // we want to show them the registered header UI since a successfully
    // completed checkout experience implies successful payment.
    if (currentUser.completedStripeCheckoutSession) {
        return getRegisteredHeaderParams(currentUser, translationHelper, body);
    }

    // If the user has an incomplete subscription, we want to show them a header UI that tells
    // them how to fix their situation.
    if (subscriptionIncomplete(subscription)) {
        return getIncompleteHeaderParams(currentUser, translationHelper);
    }

    // If the user hasn't accepted their admission offer, we want to show them the unregistered
    // header UI.
    if (offeredAdmission(admissionOffer)) {
        return getUnregisteredHeaderParams(currentUser, translationHelper, injector);
    }

    // If the user is seeing the cohortRegistrationNoPlanSelection body section,
    // we want to show them the unregistered header UI. The user is technically registered
    // but needs to set up a new Subscription, so we show them the 'unregistered' header
    if (body === TuitionAndRegistrationSection.cohortRegistrationNoPlanSelection) {
        return getUnregisteredHeaderParams(currentUser, translationHelper, injector);
    }

    return getRegisteredHeaderParams(currentUser, translationHelper, injector, body);
}

// This is only exported for consumption in specs - it should not be used outside of this module.
// If you find yourself needing to use getSupportEmail outside of this module, please move it into
// its own module.
export function getSupportEmail(currentUser, body = null) {
    const tuitionContract = getTuitionContract(currentUser);
    const subscription = tuitionContract?.subscription;

    // When called from another function inside this file, we may have
    // already determined the body section, so we allow it to be passed
    // in so that we don't have to recalculate it.  Just for performance,
    // it would not change the result.
    body = body || getTuitionAndRegistrationSection(currentUser);

    // Normally, we direct support requests to the support email for the cohort's program type
    // (i.e. emba@quantic.edu). But, in a few cases we direct them to the bursar
    //
    // 1. They were assigned a grace period and they are not yet all paid up. If they
    //      have a grace period, it means that they've already been in touch with the
    //      bursar and arranged some sort of special payment situation, so it makes
    //      sense for them to continue directing communication there.
    // 2. They have a past due subscription.  Seems likely that a user in this state who
    //      is trying to contact support really needs to talk to the bursar.
    // 3. They've made payments outside of Stripe. In which case, we don't let them
    //    manage their billing state or view payment history. They have to contact the
    //    bursar for that.
    const showBursarEmail =
        tuitionContract?.inPaymentGracePeriod ||
        subscriptionPastDue(subscription) ||
        currentUser.paid_outside_of_stripe ||
        body === TuitionAndRegistrationSection.specialCase;

    return currentUser.relevantCohort.supportEmailAddress(showBursarEmail ? BURSAR_USERNAME : undefined);
}

function getContactBursarHeaderParams(currentUser, translationHelper) {
    return {
        header: translationHelper.get('your_account_is_locked'),
        subheader: translationHelper.get('please_contact_the_bursar', {
            brandEmail: currentUser.relevantCohort.supportEmailAddress(BURSAR_USERNAME),
        }),
        showCheckmark: false,
    };
}

function getUnregisteredHeaderParams(currentUser, translationHelper, injector) {
    const dateHelper = injector.get('dateHelper');
    const header = translationHelper.get('congratulations_you_are_in');
    let subheader;
    let showCheckmark;

    if (currentUser.relevantCohort.afterRegistrationOpenDate) {
        let key = 'now_secure_place_in_x';
        if (currentUser.relevantCohort.supportsNewPriceChange || !currentUser.relevantCohort.program_guide_url) {
            key = 'now_secure_place_in_x_no_guide';
        }
        if (currentUser.relevantCohort.isExecEd) {
            key = 'register_or_review_exec_ed';
        }
        subheader = translationHelper.get(key, {
            cohortTitle: currentUser.relevantCohort.fullTitle,
        });
        showCheckmark = true;
    } else {
        const inEarlyAdmissionsRegistrationPeriod = currentUser.relevantCohort.inEarlyAdmissionsRegistrationPeriod;

        const earlyAdmissionRegistrationDeadline = inEarlyAdmissionsRegistrationPeriod
            ? earlyRegistrationDeadlineMs(getAdmissionOffer(currentUser))
            : null;

        if (earlyAdmissionRegistrationDeadline && Date.now() < earlyAdmissionRegistrationDeadline) {
            subheader = translationHelper.get('now_secure_place_in_x_no_guide', {
                cohortTitle: currentUser.relevantCohort.fullTitle,
            });
            showCheckmark = true;
        } else {
            subheader = translationHelper.get('registration_opens_on', {
                registrationOpen: dateHelper.formattedUserFacingMonthDayYearLong(
                    currentUser.relevantCohort.registrationOpenDate,
                    false,
                ),
            });
            showCheckmark = false;
        }
    }

    return { header, subheader, showCheckmark };
}

function getRegisteredHeaderParams(currentUser, translationHelper, body) {
    const header = translationHelper.get('you_are_now_registered');

    let programGuideUrl;
    let cohortTitle;

    if (currentUser.registeredForPreviousCohort) {
        const ao = getAdmissionOffer(currentUser);

        programGuideUrl = ao.fastTrackProgramGuideUrl;
        cohortTitle = ao.fastTrackCohortTitle;
    } else {
        programGuideUrl = currentUser.relevantCohort.program_guide_url;
        cohortTitle = currentUser.relevantCohort.title;
    }

    const subheaderKey = programGuideUrl
        ? 'you_have_secured_enrollment_in_x'
        : 'you_have_secured_enrollment_in_x_no_guide';

    const subheader = translationHelper.get(subheaderKey, {
        brandEmail: getSupportEmail(currentUser, body),
        cohortTitle,
    });

    return { header, subheader, showCheckmark: true };
}

function getIncompleteHeaderParams(currentUser, translationHelper) {
    const header = translationHelper.get('registration_incomplete');
    const subheader = translationHelper.get('follow_steps_to_secure', {
        cohortTitle: currentUser.relevantCohort.title,
    });
    return { header, subheader, showCheckmark: false };
}
