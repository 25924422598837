import { type EndpointDefinitions } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import {
    type Api,
    type BaseQueryFn,
    type FetchArgs,
    type FetchBaseQueryError,
} from '@reduxjs/toolkit/dist/query/react';
import { type AnyFunction } from '@Types';
import { storeProvider } from './storeProvider';

async function sendRequest<ResultType>({ send }: { send: AnyFunction; endpointName: string }): Promise<ResultType> {
    const dispatch = storeProvider.dispatch;
    const request = dispatch(send());

    const result = await request;

    request.unsubscribe();

    if (result.isError || result.error) {
        // We always expect result.error to be defined here, but just in case, raise the full result
        throw result.error || result;
    }

    return result.data;
}

export function createMakeRequestFunction<
    BaseQuery extends BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
    Definitions extends EndpointDefinitions,
    Path extends string,
    TagTypes extends string,
>(api: Api<BaseQuery, Definitions, Path, TagTypes>) {
    return async function makeRequest<
        Name extends keyof typeof api['endpoints'],
        Params extends typeof api['endpoints'][Name]['Types']['QueryArg'],
    >(
        ...args: Params extends void ? [Name] : [Name, Params]
    ): Promise<typeof api['endpoints'][Name]['Types']['ResultType']> {
        const [name, params] = args;

        const initiate = api.endpoints[args[0]].initiate as AnyFunction;

        return sendRequest({
            send: () => initiate(params),
            endpointName: name as string,
        });
    };
}
