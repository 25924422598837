import { Brand, fetchBrandConfig } from 'AppBranding';
import { type WelcomePackageConfiguration } from './Program.types';
import { students } from './welcomePackageStudents';

export const welcomePackageConfigs: Record<string, WelcomePackageConfiguration> = {
    execEd: {
        badgeSrc: fetchBrandConfig(Brand.quantic).programWelcomePackageBadge,
        // FIXME: once we have a list of students that have registered into Exec Ed certificates, we can use
        // headline_these_students_registered.
        // When we do that, I assume each Exec Ed config will need to override the welcome package config
        // to include a list of students who registered into that specific cert.
        headlineKey: 'headline_these_students_registered_generic',
        videoSrc: null,
        students: [
            students.KristenNogard,
            students.HyderAlikhan,
            students.TomGarvey,
            students.MarieWeijler,
            students.DanielNiego,
            students.WeichaoRachelZhai,
        ],
    },
    mba: {
        badgeSrc: fetchBrandConfig(Brand.quantic).programWelcomePackageBadge,
        headlineKey: 'headline_these_students_registered',
        videoSrc: 'https://www.youtube.com/embed/QQ7Qx2ysoQI?rel=0&amp;showinfo=0',
        students: [
            students.LeondraJames,
            students.HyderAlikhan,
            students.TomGarvey,
            students.MarieWeijler,
            students.DanielNiego,
            students.WeichaoRachelZhai,
        ],
    },
    emba: {
        badgeSrc: fetchBrandConfig(Brand.quantic).programWelcomePackageBadge,
        headlineKey: 'headline_these_students_registered',
        videoSrc: 'https://www.youtube.com/embed/plxX1MYx-CY?rel=0&amp;showinfo=0',
        students: [
            students.AlexeyDubrovskiy,
            students.CatherineRichards,
            students.HuiqiWang,
            students.KelechiDavidEluwa,
            students.JessicaWatson,
            students.BryanChuah,
        ],
    },
    mbaLeadership: {
        badgeSrc: fetchBrandConfig(Brand.valar).programWelcomePackageBadge,
        // FIXME: Once we have a list of students from the Valar MBA program, we can change this to `headline_these_students_registered`
        headlineKey: 'headline_these_students_registered_temp',
        videoSrc: null, // We currently don't have a promotional video for Valar
        students: [
            students.LeondraJames,
            students.HyderAlikhan,
            students.TomGarvey,
            students.MarieWeijler,
            students.DanielNiego,
            students.WeichaoRachelZhai,
        ],
    },
    embaStrategicLeadership: {
        badgeSrc: fetchBrandConfig(Brand.valar).programWelcomePackageBadge,
        headlineKey: 'headline_these_students_registered',
        videoSrc: null, // We currently don't have a promotional video for Valar
        students: [
            students.OmarWalke,
            students.YanRuTan,
            students.DamolaBamgboye,
            students.KennethSmith,
            students.HeleseSmauldon,
            students.AmiraRoux,
        ],
    },
    msba: {
        badgeSrc: fetchBrandConfig(Brand.quantic).programWelcomePackageBadge,
        // FIXME: once we have a list of students that have registered into MSBA, we can use
        // headline_these_students_registered.
        headlineKey: 'headline_these_students_registered_generic',
        videoSrc: null, // We currently don't have a promotional video for MSBA
        students: [
            students.KristenNogard,
            students.HyderAlikhan,
            students.TomGarvey,
            students.MarieWeijler,
            students.DanielNiego,
            students.WeichaoRachelZhai,
        ],
    },
    msse: {
        badgeSrc: fetchBrandConfig(Brand.quantic).programWelcomePackageBadge,
        // FIXME: once we have a list of students that have registered into MSSE, we can use
        // headline_these_students_registered.
        headlineKey: 'headline_these_students_registered_generic',
        videoSrc: null, // We currently don't have a promotional video for MSSE
        students: [
            students.KristenNogard,
            students.HyderAlikhan,
            students.TomGarvey,
            students.MarieWeijler,
            students.DanielNiego,
            students.WeichaoRachelZhai,
        ],
    },
};
