import angularModule from 'Porter-stemmer/angularModule/scripts/porter-stemmer';

angularModule.factory('stemmer', [
    '$injector',
    $injector => {
        const $window = $injector.get('$window');
        const stemmer = $injector.get('porterStemmer.Base');

        return {
            // I stole this code from the demo, which for some
            // reason they did not includ in the library: http://9ol.es/porter_js_demo.html
            stemmedWords(text) {
                const locale = $window.serverDeterminedLocale || 'en';

                let wordlist;
                let ix;
                let stem;
                const stemmed = [];

                // Only call toLowerCase on words with all extended ASCII characters
                let latin = true;
                for (let i = 0; i < text.length; i++) {
                    // Extended ASCII makes up the first 256 characters (0 - 255) of unicode
                    if (text.charCodeAt(i) > 255) {
                        latin = false;
                    }
                }
                if (latin) {
                    text = text.toLowerCase();
                }

                // We only scrub English because the word character class in regex only works
                // for the base-Latin block (the first 127 characters of unicode). This is a stop-gap
                // fix for searching in other languages.
                // See: https://github.com/quanticedu/back_royal/issues/2203
                if (locale === 'en') {
                    // dump non-words
                    text = text.replace(/[^\w]/g, ' ');
                }

                // dump multiple white-space
                text = text.replace(/\s+/g, ' ');

                // split
                wordlist = text.split(' ');

                // We only run the stemmer for English for now
                if (locale === 'en') {
                    for (ix in wordlist) {
                        stem = stemmer(wordlist[ix]);
                        // overlap.push(wordlist[ix].replace(stem, stem + '<em>') + '</em>');
                        stemmed.push(stem);
                    }
                    return stemmed;
                }
                return wordlist;
            },

            stemmedWordsSet(texts) {
                const obj = {};
                texts.forEach(text => {
                    if (!text) {
                        return;
                    }

                    this.stemmedWords(text).forEach(searchTerm => {
                        obj[searchTerm] = true;
                    });
                });
                return obj;
            },
        };
    },
]);
