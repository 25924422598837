import { generateGuid } from 'guid';
import ClientStorage from 'ClientStorage';
import 'Iguana';
import 'FrontRoyalConfig/angularModule';

export default angular.module('sessionTracker', ['FrontRoyal.Config', 'SuperModel']).factory('SessionTracker', [
    '$injector',
    function factory($injector) {
        /*

            The SessionTracker lets you track "sessions" of some activity.  You
            call `pingCurrentSession` with an identifier and the amount of inactivity
            that should define a new session, and you get back an object that has an
            id you can use to identify the session.

            SessionTracker stores things in ClientStorage, so refreshing the page has
            nothing to do with starting a new session.

            SessionTracker uses server time.

            example:

            SessionTracker.pingCurrentSession('lessonPlayer:SOME_LESSON_ID', 20.minutes).id;
            => 'SESSION_ID_1'
            // Wait 18 minutes and it is still session 1 because it's < 20 minutes since the last activity
            SessionTracker.pingCurrentSession('lessonPlayer:SOME_LESSON_ID', 20.minutes).id;
            => 'SESSION_ID_1'
            //  Wait 4 minutes and it is still session 1 because it's < 20 minutes since the last activity
            SessionTracker.pingCurrentSession('lessonPlayer:SOME_LESSON_ID', 20.minutes).id;
            => 'SESSION_ID_1'
            // Wait 21 minutes and now it's session 2
            SessionTracker.pingCurrentSession('lessonPlayer:SOME_LESSON_ID', 20.minutes).id;
            => 'SESSION_ID_2'

        */

        const SuperModel = $injector.get('SuperModel');
        const ConfigFactory = $injector.get('ConfigFactory');
        const $rootScope = $injector.get('$rootScope');

        const SessionTracker = SuperModel.subclass(function () {
            this.extend({
                _currentSessions: {},

                pingCurrentSession(identifier, expirationThreshold) {
                    // ensure that all sessions get reset when the user changes
                    const userId = $rootScope.currentUser && $rootScope.currentUser.id;
                    if (userId) {
                        identifier = `${userId}___${identifier}`;
                    }

                    // Make sure that we have checked local storage at least once
                    this._ensureLocalStorageRead();
                    let currentSession = this._currentSessions[identifier];

                    // If there is no current session or if it is expired
                    // due to a period of inactivity, then create a new one
                    if (!currentSession || currentSession.isExpired) {
                        currentSession = new SessionTracker(
                            identifier,
                            SessionTracker._now(),
                            expirationThreshold,
                            generateGuid(),
                        );
                        this._currentSessions[identifier] = currentSession;
                    }
                    currentSession.ping();

                    return this._currentSessions[identifier];
                },

                clientStorageKey(identifier) {
                    return `SessionTracker:${identifier}`;
                },

                _ensureLocalStorageRead() {
                    if (this._localeStorageRead) {
                        return;
                    }

                    const self = this;

                    // Look for all SessionTracker entries in ClientStorage so we
                    // can pull active ones into self._currentSessions and delete
                    // expired ones
                    ClientStorage.keys().forEach(i => {
                        if (i.match(/^SessionTracker:/)) {
                            let sessionInfo;
                            let sessionTracker;
                            let identifier;
                            try {
                                identifier = i.split('SessionTracker:')[1];
                                sessionInfo = JSON.parse(
                                    ClientStorage.getItem(SessionTracker.clientStorageKey(identifier)),
                                );
                                // eslint-disable-next-line no-empty
                            } catch (err) {}

                            if (sessionInfo) {
                                sessionTracker = new SessionTracker(
                                    identifier,
                                    new Date(sessionInfo.lastActivityAtTimestamp),
                                    sessionInfo.expirationThreshold,
                                    sessionInfo.id,
                                );
                            }

                            // If the entry in client storage is invalid, then sessionInfo will be undefined.
                            // In that case, or if the session is expired, delete it
                            if (!sessionInfo || (sessionTracker && sessionTracker.isExpired)) {
                                ClientStorage.removeItem(i);
                            } else if (sessionTracker) {
                                self._currentSessions[identifier] = sessionTracker;
                            }
                        }
                    });
                },

                // mockable in specs
                _now() {
                    return ConfigFactory.getServerTimestamp();
                },
            });

            Object.defineProperty(this.prototype, 'isExpired', {
                get() {
                    return SessionTracker._now() - this.expirationThreshold > this.lastActivityAt;
                },
            });

            return {
                initialize(identifier, lastActivityAt, expirationThreshold, id) {
                    this.identifier = identifier;
                    this.lastActivityAt = lastActivityAt;
                    this.expirationThreshold = expirationThreshold;
                    this.id = id;
                },

                ping() {
                    this.lastActivityAt = new Date(SessionTracker._now());
                    ClientStorage.setItem(
                        SessionTracker.clientStorageKey(this.identifier),
                        JSON.stringify({
                            lastActivityAtTimestamp: this.lastActivityAt.getTime(),
                            identifier: this.identifier,
                            expirationThreshold: this.expirationThreshold,
                            id: this.id,
                        }),
                    );
                },
            };
        });

        return SessionTracker;
    },
]);
