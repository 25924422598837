export default angular.module('RepeatComplete', []).directive('onFinishRender', [
    '$timeout',
    $timeout => ({
        restrict: 'A',

        link(scope, elem) {
            if (scope.$last === true) {
                $timeout(() => {
                    scope.$emit('ngRepeatFinished');
                });

                scope.$eval(elem.attr('on-finish-render'));
            }
        },
    }),
]);
