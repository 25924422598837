
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.playlists = window.Smartly.locales.modules.en.back_royal.playlists || {};
window.Smartly.locales.modules.en.back_royal.playlists.playlist_dashboard = window.Smartly.locales.modules.en.back_royal.playlists.playlist_dashboard || {};
window.Smartly.locales.modules.en.back_royal.playlists.playlist_dashboard = {...window.Smartly.locales.modules.en.back_royal.playlists.playlist_dashboard, ...{
    "activate_playlist": "ACTIVATE PLAYLIST",
    "activate_x": "Activating this playlist will deactivate the <strong>{{playlistName}}</strong> playlist",
    "playlist_active": "PLAYLIST ACTIVE",
    "tap_to_remove": "Tap to Remove",
    "title": "PLAYLIST DETAILS",
    "view_on_dashboard": "VIEW ON DASHBOARD",
    "x_courses": "{{num}} COURSES"
}
}
    