export default class ServerTime {
    constructor(serverClientTimeOffset) {
        this.serverClientTimeOffset = serverClientTimeOffset;
    }

    // public method that returns the current server time as a timestamp
    // if a value for 'now' is passed in, use that instead of the current Date.now() value
    getServerTimestamp(now) {
        now = now || Date.now();
        return now - this.serverClientTimeOffset;
    }
}
