import { type ProgramTypeConfig } from 'Program';
import moize from 'moize';

/*
    The buildConfig allows us to make a hierarchy of config objects to keep ProgramTypeConfig.ts organized.

    It really only does one thing, which is to convert functions to getters. Doing that allows one function to
    override another function, while also making sure we have the final inputs for each function before we
    ever try to call it.
*/

type ConfigArgs = {
    [key in keyof ProgramTypeConfig]: ((args: ProgramTypeConfig) => ProgramTypeConfig[key]) | ProgramTypeConfig[key];
};

const handleConfigErrors = (e: Error, prop: string) => {
    if (e?.name === 'RangeError') {
        const recursiveError = new Error(
            `Dynamic config properties can not be directly dependent on each other, check the use of "${prop}"`,
        );
        recursiveError.name = 'RecursiveConfigPropertyError';

        throw recursiveError;
    }
    throw e;
};

export default function buildConfig<T extends ProgramTypeConfig>(config: ConfigArgs): ProgramTypeConfig {
    return new Proxy(config as T, {
        get: moize((target: T, prop: string, r: unknown) => {
            const key = prop as keyof typeof target;
            const val = target[key];

            if (typeof val === 'function') {
                try {
                    return val(r);
                } catch (e) {
                    handleConfigErrors(e as Error, prop);
                }
            }
            return val;
        }),
    });
}
