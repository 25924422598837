import angularModule from 'Settings/angularModule/scripts/settings_module';
import template from 'Settings/angularModule/views/tuition_and_registration/cohort_registration_full_scholarship.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { TuitionPlanFrequency } from 'TuitionPlan';
import { selectablePaymentSituationForTuitionPlanFrequency } from 'PaymentSituation';
import { getAdmissionOffer, getProgramApplication } from 'Users';
import { formatCurrencyForDisplay } from 'Currency';
import {
    attachDeferralModalFn,
    attachRegisterButtonWatchFn,
    attachRegisterEnabledWatchFn,
    attachCohortDatesFn,
} from './CohortRegistrationDirHelpers';
import { RegisterButtonState } from './TuitionAndRegistration.types';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('cohortRegistrationFullScholarship', [
    '$injector',

    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');
        const ErrorLogService = $injector.get('ErrorLogService');
        const $http = $injector.get('$http');
        const $window = $injector.get('$window');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                // This is called from inside the smallprint locale
                launchProgramGuide: '&',
            },
            link(scope) {
                //-------------------------
                // Initialization
                //-------------------------
                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                // A user should never see this page if their active AOI record isn't an AdmissionOffer.
                scope.admissionOffer = getAdmissionOffer(scope.currentUser);

                // While it is technically possible for an admission offer to have multiple cohort section offers
                // (in the sense that the data model supports it), it should not be possible for a user to be offered
                // admission with multiple cohort section offers going forward. We can safely look at the first element
                // in the array here since this UI only applies to folks who are offered admission.
                scope.cohortSectionOffer = scope.admissionOffer?.cohortSectionOffers[0];

                // It shouldn't be possible to get here without a CohortSectionOffer or an AdmissionOffer.
                // If we do, we want to know about it. If this error is logged, the UI is definitely broken for this user.
                if (!scope.cohortSectionOffer) {
                    ErrorLogService.notifyInProd(
                        'Trying to render cohortRegistrationFullScholarship UI without a CohortSectionOffer',
                        undefined,
                        {
                            userId: scope.currentUser.id,
                        },
                    );
                    $rootScope.goHome();
                }

                const fullScholarshipPaymentSituation = selectablePaymentSituationForTuitionPlanFrequency(
                    scope.cohortSectionOffer?.selectablePaymentSituations,
                    TuitionPlanFrequency.FullScholarship,
                );

                scope.programInclusionProxy = {
                    shareable_with_classmates: true,
                };

                scope.peerNomination = getProgramApplication(scope.currentUser)?.peerNomination;
                if (scope.peerNomination) {
                    scope.notifyNominatorProxy = { value: true };
                }

                scope.totalScholarshipForDisplay = formatCurrencyForDisplay(
                    scope.cohortSectionOffer?.cumulativeOfferedScholarship?.amount,
                );

                //-------------------------
                // Registration helpers
                //-------------------------

                // We need to call these after initialization above because they use things on the scope.
                attachDeferralModalFn(scope, $injector, scope.cohortSectionOffer?.cumulativeOfferedScholarship);
                attachRegisterButtonWatchFn(scope, RegisterButtonState.register);
                attachRegisterEnabledWatchFn(scope);
                attachCohortDatesFn(scope);

                scope.registerForPreviousCohortProxy = {
                    value: !!scope.showFastTrackRegistration,
                };

                //-------------------------
                // Locale Keys
                //-------------------------

                scope.narrativeKey = 'settings.tuition_and_registration.narrative_full_scholarship';
                scope.graphicTitleKey = 'settings.tuition_and_registration.graphic_full_scholarship_title';
                scope.graphicSubtitleKey = 'settings.tuition_and_registration.graphic_full_scholarship_subtitle';

                if (scope.reapplicationDate) {
                    scope.smallPrintKey = 'settings.tuition_and_registration.smallprint_scholarship';
                } else {
                    scope.smallPrintKey =
                        'settings.tuition_and_registration.smallprint_scholarship_no_reapplication_date';
                }

                scope.tuitionDetailsLabelKey = 'settings.tuition_and_registration.tuition_and_scholarship_details';

                //-------------------------
                // SelfRegister UMA handling
                //-------------------------

                scope.handleRegisterButtonClick = () => {
                    scope.processingRegistration = true;
                    $http
                        .post(`${$window.ENDPOINT_ROOT}/api/user_actions/admission_offer/self_register.json`, {
                            admission_offer_id: scope.admissionOffer?.id,
                            cohort_section_id: scope.cohortSectionOffer?.cohortSectionId,
                            shareable_with_classmates: scope.programInclusionProxy.shareable_with_classmates,
                            notify_nominator: scope.notifyNominatorProxy?.value,
                            tuition_plan_id: fullScholarshipPaymentSituation.tuitionPlan.id,
                            register_for_previous_cohort: scope.registerForPreviousCohortProxy.value,
                        })
                        .then(() => {
                            scope.processingRegistration = false;
                        });
                };
            },
        };
    },
]);
