import * as userAgentHelper from 'userAgentHelper';
import { logTinyEvent } from 'TinyEventLogger';
import { type ActionPerformed, type Token, PushNotifications } from '@capacitor/push-notifications';
import { type CurrentUserIguanaObject } from 'Users';

// Exported for specs
export const onRegistrationCallback = (user_id: CurrentUserIguanaObject['id'], token: Token) => {
    let devicePlatform;
    if (userAgentHelper.isAndroidDevice()) {
        devicePlatform = 'android';
    } else if (userAgentHelper.isiOSoriPadOSDevice()) {
        devicePlatform = 'ios';
    }

    // Although we use this event to identify a device in customer.io, the event itself doesn't need to be logged to customer.io -
    // we catch it in Event#process_after_saving_in_batch and queue up a job with serialized payload arguments.
    logTinyEvent('push-notification:device-registered', {
        user_id,
        device_token: token.value,
        platform: devicePlatform,
    });
};

// Exported for specs
export const onPushNotificationActionPerformedCallback = (
    user_id: CurrentUserIguanaObject['id'],
    notification: ActionPerformed,
) => {
    if (notification.actionId !== 'tap') return;

    const payload = notification.notification.data;

    // Not a customer.io push notification, likely from Sendbird.
    if (!payload['CIO-Delivery-ID'] || !payload['CIO-Delivery-Token']) {
        return;
    }

    logTinyEvent(
        'push-notification:opened',
        {
            user_id,
            delivery_id: payload['CIO-Delivery-ID'],
            device_id: payload['CIO-Delivery-Token'],
            timestamp: Math.floor(Date.now() / 1000),
        },
        {
            // This event is needed in LogToCustomerIoJob because we track when a user opens
            // a push notification from customer.io.
            log_to_customerio: true,
        },
    );
};

export const addListeners = (user_id: CurrentUserIguanaObject['id']) => {
    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration', (token: Token) => onRegistrationCallback(user_id, token));

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) =>
        onPushNotificationActionPerformedCallback(user_id, notification),
    );
};
