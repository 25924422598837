/*
    A QueuedJobBatchWatcher will poll an api endpoint to get information about a batch
    of queued jobs that are running on the server, to monitor the progress in completing
    the jobs.

    QueuedJobBatchWatcher keeps track of a pendingCount and an errorCount that can be
    displayed to the user.

    A QueuedJobBatchWatcher has tools for storing itself in local storage so that a user
    can navigate away from a page that displays it and then navigate back and continue
    monitoring the progress.

    Usage:

    ```
        new QueuedJobBatchWatcher(batchInfo.batchId, $injector, {
            totalCount: 42,
        })
        .store('myBatchWatcher')
        .watch({
            onProgress: watcher => {
                console.log(`${watcher.pendingCount} of ${watcher.totalCount} jobs remaining.  ${watcher.errorCount} errors have occurred.`);
            }
        })
        .then(watcher => {
            console.log('No more jobs remaining. ${watcher.errorCount} errors have occurred.');
        });

        // After navigating away and coming back
        QueuedJobBatchWatcher.load('myBatchWatcher').watch(...).then(...);
*/
export default class QueuedJobBatchWatcher {
    #$http;
    #ClientStorage;
    #canceled;
    #timeoutId;
    #onProgress;
    #pollingStarted;
    #resolveWatch;

    // Load a QueuedJobBatchWatcher that was stored in localStorage (see Usage guide at the top of file)
    static load(key, $injector) {
        const ClientStorage = $injector.get('ClientStorage');
        const batchInfoJson = ClientStorage.getItem(key);
        let deserialized;
        try {
            deserialized = JSON.parse(batchInfoJson);
            // eslint-disable-next-line no-empty
        } catch (err) {
            ClientStorage.removeItem(key);
        }

        if (!deserialized) {
            return null;
        }

        const { batchId, pendingCount, errorCount, totalCount } = deserialized;

        return new this(batchId, $injector, { pendingCount, errorCount, totalCount });
    }

    // Remove a QueuedJobBatchWatcher from localStorage
    static unstore(key, $injector) {
        const ClientStorage = $injector.get('ClientStorage');
        ClientStorage.removeItem(key);
    }

    constructor(
        batchId,
        $injector,
        {
            // (optional) totalCount is the total number of jobs that were initially in the batch.  QueuedJobBatchWatcher
            // cannot get this value from the api call, so you have to just know it and provide it.  This number
            // will never change from the initial value that was passed in
            totalCount,

            // (optional) this is the initial value for `pendingCount`.  If you do not set this, then pendingCount will
            // be null until the first request pulls a value from the server.  If you know you just started the task, then
            // you can set this to be the same as the `totalCount` so that you can immediately read pendingCount off the watcher
            // even before the first request completes and sets the pendingCount from the server.
            pendingCount,
        },
    ) {
        this.#$http = $injector.get('$http');
        this.#ClientStorage = $injector.get('ClientStorage');

        this.batchId = batchId;

        this.totalCount = totalCount;
        this.pendingCount = pendingCount === undefined ? null : pendingCount;
        this.errorCount = null;
        this.#canceled = false;
        this.#pollingStarted = false;
    }

    // Store this instance in localstorage so it can be loaded up again later
    store(key) {
        this.#ClientStorage.setItem(key, this.#serialize());
        return this;
    }

    // Start polling the server for information on how many jobs are left.
    watch({
        // After each request, the onProgress callback will be triggered with the
        // watcher as the only argument
        onProgress,
    }) {
        if (this.#pollingStarted) {
            // I didn't want to have to worry about tracking multiple
            // callbacks
            throw new Error('watch has already been called.');
        }
        this.#pollingStarted = true;

        // Set default so we don't need anull check below
        this.#onProgress = onProgress || (() => {});

        return new Promise((resolve, reject) => {
            // We call this reject() function in cancel() below
            this.#resolveWatch = resolve;

            this.#pollRepeatedly().then(() => resolve(this), reject);
        });
    }

    // stop polling
    cancel() {
        clearTimeout(this.#timeoutId);
        this.#canceled = true;
    }

    #pollRepeatedly() {
        return new Promise((resolve, reject) => {
            this.#poll().then(batchInfo => {
                if (this.#canceled) {
                    return;
                }

                const complete = batchInfo.pending_count === 0;

                if (!complete) {
                    this.#timeoutId = setTimeout(() => {
                        this.#pollRepeatedly().then(resolve);
                    }, 1000);
                }

                // This needs to be called after the setTimeout so that
                // we can call jest.runAllTimers() in specs inside of the onProgress
                this.#onProgress(this);

                if (complete) {
                    resolve(this);
                }
            }, reject);
        });
    }

    #poll() {
        return this.#$http
            .get(`${window.ENDPOINT_ROOT}/api/delayed_jobs/get_batch.json`, {
                params: { batch_id: this.batchId },

                // No need to queue this GET request
                httpQueueOptions: { shouldQueue: false },
            })
            .then(response => {
                // If watcher.cancel() was called while the request was in flight,
                // the ignore the response.
                if (this.#canceled) {
                    return null;
                }
                const batchInfo = response.data.contents.batches[0];
                this.pendingCount = batchInfo.pending_count;
                this.errorCount = batchInfo.error_count;
                return batchInfo;
            });
    }

    #serialize() {
        return JSON.stringify({
            batchId: this.batchId,
            totalCount: this.totalCount,
        });
    }
}
