
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.playlists = window.Smartly.locales.modules.en.back_royal.playlists || {};
window.Smartly.locales.modules.en.back_royal.playlists.playlist_map = window.Smartly.locales.modules.en.back_royal.playlists.playlist_map || {};
window.Smartly.locales.modules.en.back_royal.playlists.playlist_map = {...window.Smartly.locales.modules.en.back_royal.playlists.playlist_map, ...{
    "specializations_title": "SPECIALIZATIONS",
    "specializations_caption": "{{numRequired}} OR MORE REQUIRED • {{numComplete}} COMPLETED",
    "exams_title": "EXAMS"
}
}
    