import 'ng-toast';
import 'angular-animate';
import 'angular-bind-html-compile';

import 'Authentication/angularModule';
import 'Cohorts/angularModule';
import 'Translation/angularModule';
import 'EventLogger/angularModule';
import 'FrontRoyalUpload/angularModule';
import 'HelpScoutBeacon/angularModule';
import 'IsMobile/angularModule';
import 'Navigation/angularModule';
import 'SafeApply/angularModule';
import 'ScrollHelper/angularModule';
import 'SearchEngineOptimization/angularModule';
import 'SoundManager/angularModule';
import 'TextToImage/angularModule';
import 'TimeoutHelpers/angularModule';
import 'Users/angularModule';
import 'Countries/angularModule';

export default angular
    .module('FrontRoyal.Settings', [
        'angular-bind-html-compile',
        'EventLogger',
        'timeoutHelpers',
        'scrollHelper',
        'SiteMetadata',
        'safeApply',
        'FrontRoyal.Authentication',
        'FrontRoyal.Cohorts',
        'FrontRoyal.Navigation',
        'FrontRoyal.Upload',
        'FrontRoyal.Countries',
        'HelpScoutBeacon',
        'Translation',
        'FrontRoyal.Users',
        'SoundManager',
        'ngAnimate',
        'ngToast',
        'isMobile',
        'TextToImage',
        'FrontRoyalStore',
    ])

    .constant('ENGLISH_LANGUAGE_PROFICIENCY_DOCUMENTS_TYPES', [
        'duolingo',
        'toefl_pbt',
        'ibt',
        'ielts',
        'pte',
        'act_compass',
        'accuplacer_esl',
        'eiken',
        'cefr',
        'other',
    ]);
