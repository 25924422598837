import { useTranslation } from 'react-i18next';
import { type ProgramListItem as ProgramListItemType, type Locale } from '../selectProgram.types';
import { ProgramListItem } from './ProgramListItem';

export function ProgramList({
    items,
    intro,
    preapproved = false,
}: {
    items: ProgramListItemType[];
    intro: Locale | undefined;
    preapproved?: boolean;
}) {
    const { t } = useTranslation('back_royal');

    return (
        <div className="program-list tw-mt-[2rem] tw-px-2">
            {intro && (
                <div className="intro-wrapper tw-mb-[2rem] tw-w-full tw-text-xs tw-font-normal tw-text-grey-shuttle">
                    {t(intro.key, intro.params ?? {})}
                </div>
            )}
            <div className="program-list-items tw-flex tw-flex-wrap tw-justify-center tw-gap-x-[2rem] tw-gap-y-[3rem]">
                {items.map(item => (
                    <ProgramListItem key={item.programType} item={item} preapproved={preapproved} />
                ))}
            </div>
        </div>
    );
}
