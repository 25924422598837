import angularModule from 'Positionable/angularModule/scripts/positionable_module';

angularModule;

angularModule.directive('positionable', [
    'DraggableDirHelper',
    '$compile',
    '$timeout',

    (DraggableDirHelper, $compile, $timeout) => ({
        restrict: 'A',

        scope: {
            posX: '=',
            posY: '=',
            posZ: '=',
            posUnits: '=?',
            posMovable: '=?',
            posResizable: '=?',
            posResizeWidth: '=',
            posResizeHeight: '=',
            posResizeTargetSelector: '@posResizeTarget',
            posAfterResized: '&',
            posRestrictAspectRatio: '=',
        },

        link(scope, elem, attrs) {
            function setMovable() {
                if (scope.posMovable) {
                    attrs.$set('draggable', 'true');
                    attrs.$set('dragData', 'dragData');
                    attrs.$set('dragstop', 'onPositionableDragStop($event)');
                } else {
                    attrs.$set('draggable', 'false');
                    delete attrs.dragstop;
                    delete attrs.dragData;
                }
            }

            setMovable();
            new DraggableDirHelper(elem, scope, attrs);

            function setSizeWhenNotResizable() {
                if (!scope.posResizable && scope.resizeTarget && scope.resizeTarget[0]) {
                    const units = scope.posUnits || 'px';

                    // SUPER WEIRD: ng-animate messes up setting width and height here in IE 11 when you use jquery setters
                    // Instead, set the width and height using plain old JS to avoid problems
                    if (scope.posResizeWidth) {
                        scope.resizeTarget[0].style.width = scope.posResizeWidth + units;
                    }

                    if (scope.posResizeHeight) {
                        scope.resizeTarget[0].style.height = scope.posResizeHeight + units;
                    }
                }
            }

            function setResizable() {
                if (scope.posResizable) {
                    scope.resizeHandle = $(
                        '<resize-handle ' +
                            'resize-width="posResizeWidth" ' +
                            'resize-height="posResizeHeight" ' +
                            'after-resized="_afterResized($event)" ' +
                            'restrict-aspect-ratio="posRestrictAspectRatio" ' +
                            'resize-units="posUnits" ' +
                            '></resize-handle>',
                    );
                    scope.resizeHandle.attr('id', Math.random());

                    $compile(scope.resizeHandle)(scope);
                    elem.append(scope.resizeHandle);

                    // need a timeout to ensure that the resizeTarget has
                    // been instantiated (in challenge-overlay-blanks)
                    $timeout(() => {
                        if (!scope.resizeTarget || scope.resizeTarget.length === 0) {
                            throw new Error(`No element found for "${scope.posResizeTargetSelector}"`);
                        }
                        if (!scope.resizeHandle || scope.resizeHandle.length === 0) {
                            throw new Error('No resize handle found.');
                        }

                        // I don't understand why this isn't set in tests, but just
                        // skipping it there
                        if (scope.resizeHandle.data('setTargetEl')) {
                            scope.resizeHandle.data('setTargetEl')(scope.resizeTarget);
                        }
                    });
                } else if (scope.resizeHandle) {
                    scope.resizeHandle.remove();
                }
            }

            function onPosResizeSet() {
                setSizeWhenNotResizable();

                if (angular.isUndefined(scope.posResizeWidth) && angular.isUndefined(scope.posResizeHeight)) {
                    elem.removeClass('fixed-size');
                } else {
                    elem.addClass('fixed-size');
                }
            }

            Object.defineProperty(scope, 'resizeTarget', {
                get() {
                    return elem.is(scope.posResizeTargetSelector) ? elem : elem.find(scope.posResizeTargetSelector);
                },
            });

            function getPositionValue(attr) {
                const units = scope.posUnits || 'px';
                if (units !== 'px' && units !== '%') {
                    throw new Error(`Units must be either "px" or "%", is "${units}"`);
                }
                return (scope[attr] || 0) + units;
            }

            function setPosition() {
                elem.css({
                    position: 'absolute',
                    left: getPositionValue('posX'),
                    top: getPositionValue('posY'),
                    zIndex: 100 + (scope.posZ || 0),
                });
            }

            scope._afterResized = $event => {
                if (scope.posAfterResized) {
                    scope.posAfterResized({
                        $event,
                    });
                }
            };

            scope.onPositionableDragStop = event => {
                // the resize dragstop event will bubble up.  When it
                // does, ignore it so we don't move the element when we're
                // trying to resize.
                let draggedElement;
                try {
                    draggedElement = event.dragAndDrop.startEvent.target;
                } catch (e) {}

                if (draggedElement && $(draggedElement).closest('resize-handle').length > 0) {
                    return;
                }
                const units = scope.posUnits || 'px';
                if (units === 'px') {
                    scope.posX += event.dragAndDrop.movedX;
                    scope.posY += event.dragAndDrop.movedY;
                } else if (units === '%') {
                    const parent = elem.offsetParent();

                    const currentXPixels = elem.position().left;
                    const currentYPixels = elem.position().top;

                    scope.posX = (100 * (currentXPixels + event.dragAndDrop.movedX)) / parent.width();
                    scope.posY = (100 * (currentYPixels + event.dragAndDrop.movedY)) / parent.height();
                } else {
                    throw new Error(`Units must be either "px" or "%", is "${units}"`);
                }

                // round the x and y values so they don't
                // have too many decimals for a ruby API
                scope.posX = Number(scope.posX.toFixed(5));
                scope.posY = Number(scope.posY.toFixed(5));
            };

            scope.$watch('posX', setPosition);
            scope.$watch('posY', setPosition);
            scope.$watch('posUnits', setPosition);
            scope.$watch('posMovable', setMovable);
            scope.$watch('posResizable', setResizable);
            scope.$watchCollection('[posResizeWidth, posResizeHeight]', onPosResizeSet);
        },
    }),
]);
