import { type ProgramType } from 'Program';

export enum ArticleSiteId {
    quantic = '54ebeb13e4b034c37ea91448',
    valar = '6166fe91ca9e0011a44348f2',
    execEd = '64b97ad8114ed272f0d4904f',
}

// use `rake help_scout:list_categories` to obtain these category IDs
export enum ArticleCategoryId {
    quanticMbaEmbaAdmissions = '640205ad0064af3c7159eb52',
    quanticMbaEmbaAcademicSupport = '62ea94f526aa5532ebc0bb97',
    quanticMbaEmbaCareerServices = '62ea94cb9ae17d202d6d84f8',
    quanticMbaEmbaLibraryServices = '62ea94d4a501d714fa22aaf8',
    quanticMbaEmbaStudentEvents = '62eacb850675b1205fb92cc9',

    quanticMsbaAdmissions = '66424c0660cfc03ad3490fe6',
    quanticMsbaAcademicSupport = '66424bea379fa732f4b9650d',
    quanticMsbaCareerServices = '66424c0d8f1b9635c9960534',
    quanticMsbaLibraryServices = '66424c1a07807c59aea6a559',
    quanticMsbaStudentEvents = '66424c221ee3dc3c695a75a0',

    quanticMsseAdmissions = '66424c3ee29ec34ff9cfa67a',
    quanticMsseAcademicSupport = '66424c3807807c59aea6a55a',
    quanticMsseCareerServices = '66424c471ee3dc3c695a75a2',
    quanticMsseLibraryServices = '66424c511ee3dc3c695a75a3',
    quanticMsseStudentEvents = '66424c5960cfc03ad3490fea',

    valarAdmissions = '640205e8c490cd5d5b96ab84',
    valarAcademicSupport = '62eacbae5e08866388c2f0e8',
    valarCareerServices = '62eacba40675b1205fb92ccd',
    valarLibraryServices = '62eacba826aa5532ebc0bd42',
    valarStudentEvents = '62eacbb226aa5532ebc0bd43',

    execEdAdmissions = '64b97b70c2f5ed048130ca3c',
    execEdAcademicSupport = '64b97b6157a4e8315e72194f',
    execEdCareerServices = '64b97b78d48f7f58ce212d3d',
    // ExecEd does not see the Library Services section in Resources.
    execEdLibraryServices = 'NA',
    execEdStudentEvents = '64b97ba4a9d61472afe091d0',
}

export type ProgramTypeToArticleCategoryIdMap = Partial<Record<ProgramType, ArticleCategoryId>>;

export type ArticleRef = {
    id: string;
    number: number;
    collectionId: string;
    slug: string;
    status: 'published' | 'notpublished';
    hasDraft: boolean;
    name: string;
    publicUrl: string;
    popularity: number;
    viewCount: number;
    createdBy: number;
    updatedBy: number;
    createdAt: string;
    updatedAt: string;
    lastPublishedAt: string;
};

export type ResourcesAPIResponse<T> = {
    articles: { items: T[]; page: number; pages: number; count: number };
};

export type ArticleSearchParams = {
    query: string;
    page?: number;
    categoryId?: ArticleCategoryId;
    pageSize?: number;
    programType: ProgramType;
    searchAllSite: boolean;
    siteId: ArticleSiteId;
};

export type ArticleSearchResult = {
    id: string;
    collectionId: string;
    siteId: string;
    categoryIds: string[];
    slug: string;
    name: string;
    preview: string;
    url: string;
    docsUrl: string;
    number: number;
    status: 'published' | 'notpublished';
    visibility: 'public' | 'private';
    updatedAt: string;
    hasDraft: boolean;
    lastPublishedAt: string;
};

export type LibraryDatabaseAccessResponse = {
    hasLibraryDatabaseAccess: boolean;
};
