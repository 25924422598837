
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.settings = window.Smartly.locales.modules.en.back_royal.settings || {};
window.Smartly.locales.modules.en.back_royal.settings.application_status = window.Smartly.locales.modules.en.back_royal.settings.application_status || {};
window.Smartly.locales.modules.en.back_royal.settings.application_status = {...window.Smartly.locales.modules.en.back_royal.settings.application_status, ...{
    "title": "{{programTitle}} Application",
    "pending_primary_message_rounds": "Thank you for applying to {{brandName}}. Applications will be reviewed at the end of the current submission cycle. {{applicationDeadlineWithDecision}}",
    "pending_primary_message_rolling": "Thank you for applying to {{brandName}}. Your application will be reviewed soon.",
    "pending_secondary_message_degree_program": "In the meantime, visit the Home tab to get started on the <strong>{{foundationsPlaylistTitle}}</strong> courses. Your progress in them is considered when we evaluate your application.",
    "pending_secondary_message_other": "In the meantime, you may explore our free courses in the introductory <strong>{{foundationsPlaylistTitle}}</strong> concentration, available on the home screen.",
    "deadline_message_network_only": "We review on a rolling basis, and usually get back to applicants within a few days.",
    "deadline_message_rolling_basis": "We review on a rolling basis, and usually get back to applicants within 5-10 days.",
    "edit_application": "Edit Application",
    "return_to_home": "Return to Home",
    "reapply": "Reapply to {{brandName}}",
    "rejected_primary_message_career_network_only": "We’re sorry we couldn’t accommodate your profile in the Career Network. We’ll be in touch as soon as we can provide you with quality opportunities.<br><br>In the meantime, you can use the button below to re-apply if your profile has changed, or if you’d like to apply to one of our MBA programs.",
    "rejected_primary_message_certificates": "We're sorry your application wasn't accepted into the {{programTitle}} program. You can use the button below to re-apply to one of our other programs, or to revise your profile and try applying again to the certificate program.",
    "rejected_primary_message_certificates_enable_quantic": "We're sorry your application wasn't accepted into the {{programTitle}} program. You can use the button below to re-apply to one of our other programs.",
    "rejected_primary_message_reapply_to_next": "The next class will be starting soon! Although you weren’t accepted for the current class, take the opportunity to reapply for the next one. {{reapplyAfterDeadline}}",
    "rejected_primary_message_reapply_to_next_rejected_after_pre_accepted": "The next class will be starting soon! Although you weren’t able to join us for the current class, take the opportunity to reapply for the next one. {{reapplyAfterDeadline}}",
    "expelled_primary_message": "The next class will be starting soon! Although you were unable to complete the current class, take the opportunity to reapply for the next one. {{reapplyAfterDeadline}}",
    "accepted_primary_message_default": "Congratulations on being accepted to the {{acceptedPrimaryMessageDefaultBrandName}} {{programTitle}} program!",
    "accepted_primary_message_career_network_only": "Congratulations on being accepted to the {{brandName}} Career Network!",
    "application_deadline_single_round": "Applications close <strong>{{applicationDeadline}}</strong>.",
    "application_deadline_multiple_rounds": "Round {{roundIndex}} applications close <strong>{{applicationDeadline}}</strong>.",
    "application_deadline_multiple_rounds_final": "Final round applications close <strong>{{applicationDeadline}}</strong>.",
    "application_deadline_single_round_with_decision_date": "Applications close <strong>{{applicationDeadline}}</strong>. Admissions decisions will be delivered by email on <strong>{{decisionDate}}</strong>.",
    "application_deadline_multiple_rounds_with_decision_date": "Round {{roundIndex}} applications close <strong>{{applicationDeadline}}</strong>. Admissions decisions will be delivered by email on <strong>{{decisionDate}}</strong>.",
    "application_deadline_multiple_rounds_final_with_decision_date": "Final round applications close <strong>{{applicationDeadline}}</strong>. Admissions decisions will be delivered by email on <strong>{{decisionDate}}</strong>.",
    "application_deadline_single_round_closed": "Applications closed on {{applicationDeadline}} and are now in review.",
    "application_deadline_single_round_closed_with_decision_date": "Applications closed on {{applicationDeadline}} and are now in review. Admissions decisions will be delivered by email on <strong>{{decisionDate}}</strong>.",
    "application_deadline_multiple_rounds_closed": "Round {{roundIndex}} applications closed on {{applicationDeadline}} and are now in review.",
    "application_deadline_multiple_rounds_closed_with_decision_date": "Round {{roundIndex}} applications closed on {{applicationDeadline}} and are now in review. Admissions decisions will be delivered by email on <strong>{{decisionDate}}</strong>.",
    "application_deadline_multiple_rounds_closed_final": "Final round applications closed on {{applicationDeadline}} and are now in review.",
    "application_deadline_multiple_rounds_closed_final_with_decision_date": "Final round applications closed on {{applicationDeadline}} and are now in review. Admissions decisions will be delivered by email on <strong>{{decisionDate}}</strong>.",
    "re_apply_after_single_round": "You will be able to re-apply after the current application deadline of {{applicationDeadline}}.",
    "re_apply_after_multiple_rounds": "You will be able to re-apply after the round {{roundIndex}} application deadline of {{applicationDeadline}}.",
    "deadline_passed_with_pending_application": "Thank you for applying to {{brandName}}. {{applicationDeadlineWithDecision}}",
    "delete_identification": "Are you sure you want to delete identification '{{file_name}}'?",
    "delete_transcript": "Are you sure you want to delete transcript '{{file_name}}'?",
    "delete_english_language_proficiency_document": "Are you sure you want to delete English language proficiency document '{{file_name}}'?",
    "verify_identity": "Are you sure you want to verify identification for {{email}}? This will delete their identification from our system.",
    "thank_you": "Thank You",
    "cn_thank_you": "Thank you for submitting your career network application. You’ll hear back in about a week!",
    "share_with_friends": "Share with Friends",
    "cn_share_with_friends": "In the meantime, share your personal referral link with your friends and colleagues.",
    "failed_unfortunately": "Unfortunately, you did not meet the graduation requirements. You will retain access to your course library. For more information, contact us at <a href=\"mailto:{{brandEmail}}\" target=\"_blank\">{{brandEmail}}</a>.",
    "rejected_primary_message_reapply_to_next_cant_reapply": "Though you won’t be joining the degree program, you can reapply beginning {{reapplicationDate}}. In the meantime, you’ll maintain free access to the open courses.",
    "rejected_primary_message_reapply_to_next_rejected_after_pre_accepted_cant_reapply": "Though you won’t be joining the degree program, you can reapply beginning {{reapplicationDate}}. In the meantime, you’ll maintain free access to the open courses."
}
}
    