import angularModule from 'Playlists/angularModule/scripts/playlists_module';
import trackIcon from 'vectors/track_icon.svg';
import trackIconLocked from 'vectors/track_icon_locked.svg';
import { regionAwareImageUrlForFormat } from 'regionAwareImage';

angularModule.factory('Playlist', [
    '$injector',
    $injector => {
        const $q = $injector.get('$q');
        const Iguana = $injector.get('Iguana');
        const IsContentItem = $injector.get('IsContentItem');
        const Stream = $injector.get('Lesson.Stream');
        const StreamEntry = $injector.get('Playlist.StreamEntry');
        const $rootScope = $injector.get('$rootScope');

        const Playlist = Iguana.subclass(function () {
            this.setCollection('playlists');
            this.alias('Playlist');
            this.include(IsContentItem);
            this.embedsOne('entity_metadata', 'EntityMetadata');
            this.embedsMany('stream_entries', 'Playlist.StreamEntry');

            $rootScope.$watch('currentUser', (newValue, oldValue) => {
                // If the user changes let's blow away the cache which contains stuff
                // like the progress of the previous user
                if (newValue && oldValue) {
                    this.resetCache();
                }
            });

            this.extend({
                groupable: false,
                fieldsForEditorList: [
                    'id',
                    'title',
                    'author',
                    'stream_entries',
                    'published_at',
                    'modified_at',
                    'locale',
                    'locale_pack',
                    'tag',
                    'related_cohort_names',
                    'programs_included_in',
                ],
                _playlistCache: {},
                _playlistCacheByLocalePackId: {},
                DEFAULT_ICON: trackIcon,
                LOCKED_ICON: trackIconLocked,
                supportsContentFamilyIdentifier: true,

                // FIXME: https://trello.com/c/BO5vJgxx/905-chore-dry-playlist-and-stream

                indexForCurrentUser(options) {
                    return this.index(this._optionsForGetCalls(options));
                },

                getCachedOrShow(playlistId, options) {
                    options = this._optionsForGetCalls(options);

                    if (!this._playlistCache[playlistId]) {
                        const deferred = $q.defer();
                        this.show(playlistId, options).then(response => {
                            // available_content_family_identifiers are included in the response meta,
                            // but we're only resolving the playlist from the server response, so we
                            // include the available_content_family_identifiers on the playlist so
                            // that they can be accessed by the caller.
                            if (options.get_available_content_family_identifiers) {
                                response.result.available_content_family_identifiers =
                                    response.meta.available_content_family_identifiers;
                            }
                            deferred.resolve(response.result);
                        });
                        this._playlistCache[playlistId] = {
                            promise: deferred.promise,
                        };
                    }
                    return this._playlistCache[playlistId].promise;
                },

                // FIXME: Lot of duplication here from steam.js. It would be nice to DRY this caching stuff up.
                getCachedForLocalePackId(localePackId, throwIfMissing) {
                    if (!localePackId) {
                        throw new Error('No localePackId passed in');
                    }

                    if (angular.isUndefined(throwIfMissing)) {
                        throwIfMissing = true;
                    }

                    let playlist = this._playlistCacheByLocalePackId[localePackId];

                    // If this stream has been removed from the streamCache,
                    // do not return it.
                    if (playlist && !this._playlistCache[playlist.id]) {
                        playlist = undefined;
                    }

                    // If we didn't find the playlist, copy everything over
                    // from the streamCache and see if we find it now
                    if (!playlist) {
                        this._rebuildPlaylistCacheByLocalePackId();
                    }
                    playlist = this._playlistCacheByLocalePackId[localePackId];

                    if (!playlist) {
                        if (throwIfMissing) {
                            throw new Error('No cached playlist found for locale pack.');
                        }
                        return undefined;
                    }

                    return playlist;
                },

                resetCache() {
                    this._playlistCache = {};
                    this._playlistCacheByLocalePackId = {};
                },

                loadStreams(playlists) {
                    // Build array of streams we need to retrieve
                    const streamLocalePackIds = _.chain(playlists)
                        .map('streamLocalePackIds')
                        .flattenDeep()
                        .uniq()
                        .value();

                    const unloadedLocalePackIds = _.reject(streamLocalePackIds, localePackId =>
                        Stream.getCachedForLocalePackId(localePackId, false),
                    );

                    // If there are any that are unloaded, make an api call
                    if (_.some(unloadedLocalePackIds)) {
                        return Stream.index({
                            filters: {
                                published: true,
                                locale_pack_id: unloadedLocalePackIds.sort(), // sort is unnecessary, but simplifies tests
                                in_users_locale_or_en: true,
                            },
                            include_progress: true,
                        }).then(() => true); // Don't return just the streams that were just loaded. Might be confusing.
                    }

                    return $q.resolve(false);
                },

                editorUrl(id) {
                    return `/editor/playlist/${id}/edit`;
                },

                _rebuildPlaylistCacheByLocalePackId() {
                    const cache = {};
                    this._playlistCacheByLocalePackId = cache;
                    _.forEach(this._playlistCache, entry => {
                        if (entry.playlist && entry.playlist.localePackId) {
                            cache[entry.playlist.localePackId] = entry.playlist;
                        }
                    });
                    return cache;
                },

                _optionsForGetCalls(options = {}) {
                    options.filters = options.filters || {};

                    return options;
                },
            });

            // eslint-disable-next-line no-shadow
            const Playlist = this;
            this.setCallback('after', 'copyAttrsOnInitialize', function () {
                if (this.id) {
                    Playlist._playlistCache[this.id] = {
                        promise: $q.when(this),
                        playlist: this,
                        playlistId: this.id,
                    };
                }

                if (this.localePackId) {
                    Playlist._playlistCacheByLocalePackId[this.localePackId] = this;
                }
            });

            Object.defineProperty(this.prototype, 'factoryName', {
                // eslint-disable-next-line lodash-fp/prefer-constant
                get() {
                    return 'Playlist';
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'complete', {
                get() {
                    const incompleteStream = _.find(this.stream_entries, streamEntry => {
                        // In all expected cases, there should be a stream found here, and if
                        // there isn't one then a message will go to sentry.  If one is missing, though,
                        // let's assume that it is not complete. (see https://trello.com/c/S2ZadRN3/1189-bug-no-cached-stream-found)
                        if (!streamEntry.stream) {
                            return true;
                        }
                        return !streamEntry.stream.complete;
                    });
                    return !incompleteStream;
                },
            });

            Object.defineProperty(this.prototype, 'numStreamsComplete', {
                get() {
                    return this.stream_entries.filter(streamEntry => streamEntry.stream && streamEntry.stream.complete)
                        .length;
                },
            });

            Object.defineProperty(this.prototype, 'percentComplete', {
                get() {
                    // We are electing to calculate the percentComplete on the client rather
                    // than using the percent_complete server value.
                    let total = 0;
                    let completed = 0;
                    _.forEach(this.streams, stream => {
                        _.forEach(stream.lessons, lesson => {
                            total += lesson.approxLessonMinutes;
                            if (lesson.complete || stream.complete) {
                                completed += lesson.approxLessonMinutes;
                            }
                        });
                    });

                    return total > 0 ? Math.round((completed / total) * 100) : 0;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'canCalculateComplete', {
                get() {
                    return this.allStreamsAvailable;
                },
            });

            Object.defineProperty(this.prototype, 'allStreamsAvailable', {
                get() {
                    return this.availableStreams.length === this.stream_entries.length;
                },
            });

            let prevAvailableStreams;
            Object.defineProperty(this.prototype, 'availableStreams', {
                get() {
                    const streams = _.chain(this.stream_entries).map('availableStream').compact().value();

                    if (!_.isEqual(streams, prevAvailableStreams)) {
                        prevAvailableStreams = streams;
                    }

                    return prevAvailableStreams;
                },
            });

            Object.defineProperty(this.prototype, 'streams', {
                get() {
                    // Note: If any of the streams are missing, they
                    // will just be filtered out of here.  There is no
                    // difference between this and availableStreams in the UI.  The
                    // difference is that this one will log to sentry if the stream is
                    // not found.
                    return _.chain(this.stream_entries).map('stream').compact().value();
                },
            });

            Object.defineProperty(this.prototype, 'streamCount', {
                get() {
                    return this.stream_entries.length;
                },
            });

            Object.defineProperty(this.prototype, 'streamLocalePackIds', {
                get() {
                    const self = this;
                    // always return the same array, but rebuild it each time
                    self.$$streamLocalePackIds = self.$$streamLocalePackIds || [];
                    self.$$streamLocalePackIds.splice(0);
                    _.chain(self.stream_entries)
                        .map('locale_pack_id')
                        .forEach(localePackId => {
                            self.$$streamLocalePackIds.push(localePackId);
                        })
                        .value();
                    return self.$$streamLocalePackIds;
                },
                set(localePackIds) {
                    const self = this;
                    this.stream_entries = _.map(localePackIds, localePackId => {
                        let entry = self.getStreamEntry(localePackId);

                        if (!entry) {
                            entry = StreamEntry.new({
                                locale_pack_id: localePackId,
                            });
                        }

                        return entry;
                    });
                },
            });

            Object.defineProperty(this.prototype, 'hasImage', {
                get() {
                    return regionAwareImageUrlForFormat(
                        this.image,
                        'original',
                        $injector.get('ConfigFactory').getSync(true),
                    );
                },
            });

            Object.defineProperty(this.prototype, 'relatedCohortsToS', {
                get() {
                    return this.related_cohort_names && this.related_cohort_names.length > 0
                        ? this.related_cohort_names.sort().join(', ')
                        : '';
                },
            });

            return {
                groupable: false,

                imageSrc() {
                    const src = regionAwareImageUrlForFormat(
                        this.image,
                        'original',
                        $injector.get('ConfigFactory').getSync(true),
                    );
                    return src || Playlist.DEFAULT_ICON;
                },

                containsLocalePackId(localePackId) {
                    return this._indexForLocalePackId(localePackId) !== -1;
                },

                getStreamEntry(localePackId) {
                    return _.find(this.stream_entries, {
                        locale_pack_id: localePackId,
                    });
                },

                includedInProgram(programName) {
                    return _.includes(this.programs_included_in, programName);
                },

                _indexForLocalePackId(localePackId) {
                    return this.streamLocalePackIds.indexOf(localePackId);
                },
            };
        });

        return Playlist;
    },
]);
