import angularModule from 'Playlists/angularModule/scripts/playlists_module';

angularModule.factory('Playlist.StreamEntry', [
    '$injector',
    $injector => {
        const Iguana = $injector.get('Iguana');
        const Stream = $injector.get('Lesson.Stream');
        const ErrorLogService = $injector.get('ErrorLogService');

        return Iguana.subclass(function () {
            this.alias('Playlist.StreamEntry');
            this.embeddedIn('playlist');

            Object.defineProperty(this.prototype, 'stream', {
                get() {
                    try {
                        return Stream.getCachedForLocalePackId(this.locale_pack_id, true);
                    } catch (err) {
                        ErrorLogService.notifyInProd(err, undefined, {
                            fingerprint: ['No cached stream found for locale pack.'],
                            streamLocalePackId: this.locale_pack_id,
                            playlistId: this.playlist().id,
                        });
                        return null;
                    }
                },
            });

            Object.defineProperty(this.prototype, 'availableStream', {
                get() {
                    return Stream.getCachedForLocalePackId(this.locale_pack_id, false);
                },
            });
        });
    },
]);
