// For degree programs, we mail a diploma to the student. The assignment of physical_diploma
// as the completionDocumentType also affects to whether we talk about "graduation" or "program completion"
export const PHYSICAL_DIPLOMA = 'physical_diploma';

// For Exec Ed, certificates are being issued through Parchment.  Students will receive an email with a link,
// they won’t be accessible directly from the Quantic UI. (VLA programs do something similar, but they use
// Sertifier instead of Parchment.)
export const DELIVERED_DIGITAL_CERTIFICATE = 'delivered_digital_certificate';

// For the biz cert, there is a certificate download link in the UI. The certificate
// is dynamically generated when the user clicks the link.
export const DYNAMIC_DOWNLOADABLE_CERTIFICATE = 'dynamic_downloadable_certificate';
