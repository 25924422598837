export default angular.module('Sequence', []).factory('sequence', [
    '$injector',

    function factory($injector) {
        const $q = $injector.get('$q');

        // copied from etl/scripts/util/sequence.js
        function sequence(list, fn, bindTo) {
            // e memo, then the value and index (or key) of the iteration, and finally a reference to the entire list.

            // we need a resolved promise to get the chain going
            const deferred = $q.defer();
            const _promise = deferred.promise;
            deferred.resolve();
            const returnValue = [];

            return _.reduce(
                list,
                (_promise, val, i) =>
                    _promise.then(fn.bind(bindTo, val, i)).then(result => {
                        returnValue.push(result);
                    }),
                _promise,
            ).then(() => returnValue);
        }

        return sequence;
    },
]);
