
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.scholarship_labels = window.Smartly.locales.modules.en.back_royal.scholarship_labels || {};
window.Smartly.locales.modules.en.back_royal.scholarship_labels = {...window.Smartly.locales.modules.en.back_royal.scholarship_labels, ...{
                                                                                       
    "employer_partner": "Employer Partner Scholarship",
    "employer_partner_short": "Employer Partner",
    "recruitment": "Recruitment Scholarship",
    "recruitment_short": "Recruitment",
    "strada": "Strada Leadership Scholarship",
    "strada_short": "Strada Leadership",
    "strada_female": "Strada Women in Leadership Scholarship",
    "strada_female_short": "Strada Women in Leadership",
    "impact_quantic": "Quantic Impact Scholarship",
    "impact_quantic_short": "Quantic Impact",
    "powell": "Powell Aid Scholarship",
    "powell_short": "Powell Aid",
    "zenith": "Leadership Scholarship",
    "zenith_short": "Leadership",
    "impact_valar": "Valar Impact Scholarship",
    "impact_valar_short": "Valar Impact",
    "hyperion": "Hyperion Aid Scholarship",
    "hyperion_short": "Hyperion Aid",
    "exec_ed_launch": "Exec Ed Launch Scholarship",
    "exec_ed_launch_short": "Exec Ed Launch Scholarship",
    "alumni": "Alumni Scholarship",
    "impact_global": "Global Impact Scholarship",
    "alumni_quantic_launch": "Quantic Launch Scholarship",

    "employer_partner_application": "Employer Partner Scholarship",
    "recruitment_application": "Recruitment Scholarship",
    "strada_application": "Leadership Scholarship",
    "impact_quantic_application": "Impact Scholarship",
    "powell_application": "Financial Aid Scholarship",
    "zenith_application": "Leadership Scholarship",
    "impact_valar_application": "Impact Scholarship",
    "hyperion_application": "Financial Aid Scholarship",
    "alumni_application": "Alumni Scholarship",

                                                
    "entrepreneurship": "Hyperion Award for Entrepreneurs",
    "entrepreneurship_short": "Hyperion",
    "entrepreneurship_desc": "Scholarship that provides tuition assistance for aspiring and seasoned entrepreneurs.",
    "emerging_leaders": "Strada Scholarship for Emerging Leaders",
    "emerging_leaders_short": "Strada",
    "emerging_leaders_desc": "Awarded to students with promising leadership ability, who often work in an emerging industry or nation.",
    "sustainability": "Powell Impact and Sustainability Scholarship",
    "sustainability_short": "Powell",
    "sustainability_desc": "For those working to improve the wellbeing of their community or the state of the world.",
    "women_in_tech": "Leadership Award for Women in Tech",
    "women_in_tech_short": "Leadership",
    "women_in_tech_desc": "Awarded to active technology professionals and executives with exceptional leadership qualities.",
    "academic_excellence": "The Zenith Award for Academic Excellence",
    "academic_excellence_short": "Zenith",
    "academic_excellence_desc": "Given to those who have demonstrated exceptional academic performance throughout their studies.",

                                                                                                        
                                                                                                              
                                                                                                                
    "innovation_sponsorship_award": "Innovation Sponsorship Award",
    "innovation_sponsorship_award_short": "Innovation",
    "edcor_award": "Edcor Award",
    "edcor_award_short": "Edcor",
    "global_health_impact_network": "Global Health Impact Network",
    "global_health_impact_network_short": "GHIN",

    "base_scholarship": "Scholarship"
}
}
    