import 'angular-utils-pagination';
import 'angular-selectize2/dist/angular-selectize';
import 'selectize-no-delete/src/selectize-no-delete';

import 'plotly.js/dist/plotly.min';
import 'plotly.js/dist/plotly-locale-ar';
import 'plotly.js/dist/plotly-locale-es';
import 'plotly.js/dist/plotly-locale-it';
import 'plotly.js/dist/plotly-locale-zh-cn';
import 'plotly.js/dist/plotly-locale-am';

import 'AdminEditorReportsShared/angularModule';
import 'Capabilities/angularModule';
import 'FrontRoyalSpinner/angularModule';
import 'FrontRoyalUiBootstrap/angularModule';
import 'ImageFadeInOnLoad/angularModule';
import 'Institutions/angularModule';
import 'Navigation/angularModule';
import 'OrderedHash/angularModule';
import 'Oreo/angularModule';
import 'Pagination/angularModule';
import 'SafeApply/angularModule';
import 'SortableColumns/angularModule';
import 'Translation/angularModule';

export default angular
    .module('FrontRoyal.Reports', [
        'FrontRoyal.AdminEditorReportsShared',
        'FrontRoyal.UiBootstrap',
        'FrontRoyal.Institutions',
        'FrontRoyal.Navigation',
        'FrontRoyal.Oreo',
        'imageFadeInOnLoad',
        'Pagination',
        'Translation',
        'FrontRoyalSpinner',
        'SortableColumns',
        'selectize',
        'Capabilities',
        'OrderedHash',
        'safeApply',
        'ui.bootstrap.datetimepicker', // angular-bootstrap-datetimepicker
        'angularUtils.directives.dirPagination', // angular-utils-pagination
    ])
    .constant('REPORTS_FORM_CLASSES', [
        'selectize-triangle-after',
        'inputs-31h',
        'beige-input-borders',
        'inputs-eggplant-fonts',
        'reset-form-states',
        'bs-dropdown-reset',
        'bs-dropdown-centered-below',
        'bs-dropdown-use-arrow-circles',
        'beige-datetimepicker',
    ]);
