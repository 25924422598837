import { AoiRecordType } from 'ProgramAoi';
import { getAoiRecord, getReapplicationDate, getAdmissionOffer } from 'Users';
import { earlyRegistrationDeadlineMs } from 'AdmissionOffer';
import * as dateHelper from 'DateHelpers';
import { getRegisterButton, getRegisterButtonState } from './getRegisterButtonConfig';
import { getPaymentConfigState } from './getPaymentConfig';
import { RegisterButtonState } from './TuitionAndRegistration.types';

async function openStripeCheckoutSession(scope, $injector, params) {
    const checkoutSessionsEndpoint = `${window.ENDPOINT_ROOT}/api/stripe_checkout_sessions`;

    scope.processingRegistration = true;

    const stripeCheckoutSession = await $injector
        .get('$http')
        .post(checkoutSessionsEndpoint, params)
        .then(response => response?.data?.session);

    // TODO: If we have a session but no URL here, it probably means getStripeCheckoutSessionUrl
    // returned a completed checkout session (this could happen). This could theoretically happen
    // if the user had two separate browser windows open, registered in one, selected the same plan
    // and clicked the register button in the other.
    // Right now, we just do nothing when that button is clicked if there isn't a URL. Maybe that's fine,
    // because this is an edge case. Should we consider doing something here if we don't have a URL?
    if (stripeCheckoutSession?.url) {
        $injector.get('$window').location.href = stripeCheckoutSession.url;
    } else {
        scope.processingRegistration = false;
    }
}

// We don't allow registration on Cordova - we should redirect them to a system browser
// so they can initiate a checkout session from there.
function openApplicationStatusInBrowser(_scope, $injector) {
    const applicationStatusTarget = `${$injector.get('$window').ENDPOINT_ROOT}/settings/application_status`;
    $injector.get('Navigation.NavigationHelperMixin').loadUrl(applicationStatusTarget, '_blank');
}

export function attachOpenStripeCheckoutSessionFn(scope, $injector) {
    // We don't allow registration on Cordova.
    if ($injector.get('$window').CORDOVA) {
        // This is used in the cohortRegistrationPlanSelection and cohortRegistrationNoPlanSelection templates,
        // so we need to add it to the scope.
        scope.registerRequiresRedirectToBrowser = true;
        scope.openStripeCheckoutSessionOrBrowser = openApplicationStatusInBrowser;
    } else {
        scope.openStripeCheckoutSessionOrBrowser = openStripeCheckoutSession;
    }
}

export function attachDeferralModalFn(scope, $injector, scholarship) {
    const TranslationHelper = $injector.get('TranslationHelper');
    const DialogModal = $injector.get('DialogModal');
    const translationHelper = new TranslationHelper('settings.tuition_and_registration');

    scope.openDeferralModal = () => {
        const admissionOffer = getAoiRecord(scope.currentUser, AoiRecordType.AdmissionOffer);

        const chooseDifferentStartDateKey = scholarship
            ? 'choose_different_start_date_scholarship'
            : 'choose_different_start_date';

        const chooseDifferentStartDateText = translationHelper.get(chooseDifferentStartDateKey, {
            startDate: dateHelper.formattedUserFacingMonthDayYearLong(
                scope.currentUser.relevantCohort.startDate,
                false,
            ),
        });

        let reapplyAtDifferentTimeKey = scholarship
            ? 'reapply_at_different_time_scholarship'
            : 'reapply_at_different_time';

        const currentReapplicationDate = getReapplicationDate(scope.currentUser);
        const reapplicationMonthYear = currentReapplicationDate
            ? dateHelper.formattedUserFacingMonthYearLong(currentReapplicationDate, false)
            : undefined;

        if (!reapplicationMonthYear) {
            reapplyAtDifferentTimeKey += `_no_date`;
        }

        const reapplyAtDifferentTimeText = translationHelper.get(reapplyAtDifferentTimeKey, {
            reapplicationMonthYear,
        });

        const upcomingCohortDates =
            admissionOffer?.upcomingCohortDates
                ?.map(cohortAttrs => new Date(cohortAttrs.startDate * 1000))
                ?.map(startDate => dateHelper.formattedUserFacingMonthDayLong(startDate, false)) || [];

        const contactUsWithPreferredStartDateText = translationHelper.get('contact_us_with_preferred_start_date', {
            admissionsEmail: scope.currentUser.relevantCohort.supportEmailAddress('admissions'),
        });

        const refundPolicyText = translationHelper.get('refund_policy');

        DialogModal.alert({
            title: translationHelper.get('deferring_admission'),
            content: `
                <p>${chooseDifferentStartDateText}</p>
                <ul>${upcomingCohortDates.map(date => `<li>${date}</li>`).join('')}</ul>
                <p>${contactUsWithPreferredStartDateText}</p>
                <p>${reapplyAtDifferentTimeText}</p>
                <p>${refundPolicyText}</p>
            `,
            scope: {
                loadFaqUrl: $injector.get('Navigation.NavigationHelperMixin').loadFaqUrl,
            },
        });
    };
}

export function attachRegisterButtonWatchFn(scope, defaultState = RegisterButtonState.review_and_register) {
    scope.$watchGroup(
        [
            'processingRegistration',
            'registerRequiresRedirectToBrowser',
            'paymentConfig',
            'activePaymentView',
            'selectedPaymentTypeProxy.value',
        ],
        ([
            processingRegistration,
            registerRequiresRedirectToBrowser,
            paymentConfig,
            activePaymentView,
            selectedPaymentType,
        ]) => {
            const registerButtonState = getRegisterButtonState({
                processingRegistration,
                registerRequiresRedirectToBrowser,
                paymentConfig,
                activePaymentView,
                selectedPaymentType,
                defaultState,
            });

            scope.registerButton = getRegisterButton({ registerButtonState });
        },
    );
}

export function attachRegisterEnabledWatchFn(scope) {
    scope.$watchGroup(
        ['currentUser.relevantCohort.afterRegistrationOpenDate', 'inEarlyAdmissionsRegistrationPeriod'],
        ([afterRegistrationOpenDate, inEarlyAdmissionsRegistrationPeriod]) => {
            scope.registerEnabled = !!afterRegistrationOpenDate || inEarlyAdmissionsRegistrationPeriod;
        },
    );
}

export function attachPaymentConfigStateWatchFn(scope) {
    scope.$watchGroup(
        ['currentUser.country', 'currentUser.shouldChargeCreditCardPaymentFee'],
        ([country, shouldChargeCreditCardPaymentFee]) => {
            if (!scope.currentUser) return;

            scope.paymentConfigState = getPaymentConfigState({
                country,
                shouldChargeCreditCardPaymentFee,
            });
        },
    );
}

export function attachCohortDatesFn(scope) {
    const earlyAdmissionRegistrationDeadline = earlyRegistrationDeadlineMs(getAdmissionOffer(scope.currentUser));
    const inEarlyAdmissionsRegistrationPeriod =
        scope.currentUser.relevantCohort.inEarlyAdmissionsRegistrationPeriod &&
        Date.now() < earlyAdmissionRegistrationDeadline;
    scope.beforeRegistrationDeadline =
        !scope.currentUser.relevantCohort.registrationDeadlineHasPassed &&
        !scope.currentUser.relevantCohort.afterRegistrationOpenDate;

    const registrationDeadline = inEarlyAdmissionsRegistrationPeriod
        ? earlyAdmissionRegistrationDeadline
        : scope.currentUser.relevantCohort.registrationDeadline;

    const fastTrackRegistrationDeadlineTs = getAdmissionOffer(scope.currentUser)?.fastTrackRegistrationDeadline;
    const fastTrackRegistrationDeadline = fastTrackRegistrationDeadlineTs
        ? 1000 * fastTrackRegistrationDeadlineTs
        : null;

    const fastTrackStartDateTs = getAdmissionOffer(scope.currentUser)?.fastTrackStartDate;
    const fastTrackStartDate = fastTrackStartDateTs
        ? dateHelper.formattedUserFacingMonthDayYearLong(1000 * fastTrackStartDateTs, false)
        : null;

    scope.inEarlyAdmissionsRegistrationPeriod = inEarlyAdmissionsRegistrationPeriod;

    scope.showFastTrackRegistration =
        inEarlyAdmissionsRegistrationPeriod &&
        fastTrackRegistrationDeadline &&
        registrationDeadline < fastTrackRegistrationDeadline &&
        Date.now() < fastTrackRegistrationDeadline;

    scope.registrationDeadline = dateHelper.formattedUserFacingMonthDayYearLong(registrationDeadline);
    scope.registrationDeadlineMobile = dateHelper.formattedUserFacingMonthDayLong(registrationDeadline);

    scope.registrationOpenDate = dateHelper.formattedUserFacingMonthDayYearLong(
        scope.currentUser.relevantCohort.registrationOpenDate,
        false,
    );

    scope.classStartDate = dateHelper.formattedUserFacingMonthDayYearLong(
        scope.currentUser.relevantCohort.startDate,
        false,
    );

    scope.classStartDateMobile = dateHelper.formattedUserFacingMonthDayLong(
        scope.currentUser.relevantCohort.startDate,
        false,
    );

    // Only AdmissionOffers with status='offered_admission' will have upcomingCohortDates
    if (scope.admissionOffer?.upcomingCohortDates?.length > 0) {
        scope.upcomingClassStartDate = dateHelper.formattedUserFacingMonthDayLong(
            new Date(scope.admissionOffer.upcomingCohortDates[0].startDate * 1000),
            false,
        );
    }

    const currentReapplicationDate = getReapplicationDate(scope.currentUser);
    if (currentReapplicationDate) {
        scope.reapplicationDate = dateHelper.formattedUserFacingMonthYearLong(currentReapplicationDate, false);
    }

    scope.cohortDateOptions = [
        { date: fastTrackStartDate, value: true },
        { date: scope.classStartDate, value: false },
    ];
}
