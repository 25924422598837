import { type CumulativeScholarship } from 'Scholarship';
import { type TuitionPlanFrequency } from 'TuitionPlan';
import { formatCurrencyForDisplay } from 'Currency';
import { type FormattedPaymentSituation, type PaymentSituation } from './PaymentSituation.types';

export function paymentSituationWithFormattedValues(situation: PaymentSituation): FormattedPaymentSituation {
    return {
        ...situation,
        paymentInformation: {
            ...situation.paymentInformation,
            formattedPaymentAmountPerInterval: formatCurrencyForDisplay(
                situation.paymentInformation.paymentAmountPerInterval,
            ),
            formattedNetRequiredPayment: formatCurrencyForDisplay(situation.paymentInformation.netRequiredPayment),
            // Used to display base cost of plan without any discounts
            formattedFullTuitionPaymentPerInterval: formatCurrencyForDisplay(
                // numIntervals is nullish, but only Full Scholarship TuitionPlans have null `numIntervals`.
                situation.paymentInformation.baseTuition / (situation.tuitionPlan.numIntervals || 1),
            ),
            formattedNetDiscountAmount: formatCurrencyForDisplay(situation.paymentInformation.netDiscountAmount),
        },
    };
}

// FIXME: deprecated, remove once all UMAs are reading payment situations from CohortSectionOffers
// See also: https://trello.com/c/oioaNcW3/769-chore-read-payment-situations-from-cohortsectionoffers-not-admissionoffers
export function paymentSituationsForScholarship(
    paymentSituations: PaymentSituation[] | undefined,
    cumulativeScholarship: CumulativeScholarship | null | undefined,
): PaymentSituation[] {
    const relevantPaymentSituationsForScholarship =
        paymentSituations?.filter(
            situation =>
                // we don't expect undefined here, since that would mean we're missing a CSO or TC,
                // so we're defensive to that case and won't match any payment situations
                cumulativeScholarship !== undefined &&
                // a null cumulativeScholarship means "No Scholarship"
                situation.cumulativeScholarshipLevel === (cumulativeScholarship?.level || null),
        ) || [];

    return relevantPaymentSituationsForScholarship;
}

// This function is predominantly used from UMAs where we know a TuitionPlan's ID already.
// However, it is used in learner-facing code inside `cohortRegistrationNoPlanSelection` where
// we know we have a TuitionContract with a TuitionPlan and therefore already have the ID.
// FIXME: deprecated, remove once all UMAs are reading payment situations from CohortSectionOffers
// See also: https://trello.com/c/oioaNcW3/769-chore-read-payment-situations-from-cohortsectionoffers-not-admissionoffers
export function paymentSituationForScholarshipAndTuitionPlanId(
    paymentSituations: PaymentSituation[] | undefined,
    cumulativeScholarship: CumulativeScholarship | null | undefined,
    tuitionPlanId: string | null | undefined,
) {
    // Undefined is different from null here. Undefined means that
    // we don't know what the object (a Scholarship or a TuitionPlan's ID)
    // is yet, and can't match up a PaymentSituation as a result.
    // Null, in the case of a Scholarship means there is no scholarship.
    if (cumulativeScholarship === undefined || tuitionPlanId === undefined) {
        return null;
    }

    return paymentSituationsForScholarship(paymentSituations, cumulativeScholarship).find(
        situation => situation.tuitionPlan.id === tuitionPlanId,
    );
}

export function selectablePaymentSituationForTuitionPlanId(
    selectablePaymentSituations: PaymentSituation[] | undefined,
    tuitionPlanId: string | null | undefined,
) {
    // Undefined is different from null here. Undefined means that
    // we don't know what the TuitionPlan's ID is yet and can't match
    // up a PaymentSituation as a result.
    if (tuitionPlanId === undefined) {
        return null;
    }

    const paymentSituation = selectablePaymentSituations?.find(situation => situation.tuitionPlan.id === tuitionPlanId);

    if (!paymentSituation) return null;

    return paymentSituationWithFormattedValues(paymentSituation);
}

// This function is predominantly used from the registration screens where we only know
// the frequency of a TuitionPlan.
// Note that the selectablePaymentSituations here should be coming from a cohortSectionOffer,
// where they're already filtered by Scholarship, i.e. there shouldn't be any selectablePaymentSituations
// here that aren't considered consumable by the user.
export function selectablePaymentSituationForTuitionPlanFrequency(
    selectablePaymentSituations: PaymentSituation[] | undefined,
    tuitionPlanFrequency: TuitionPlanFrequency,
) {
    const paymentSituation = selectablePaymentSituations?.find(
        situation => situation.tuitionPlan.frequency === tuitionPlanFrequency,
    );

    if (!paymentSituation) return null;

    return paymentSituationWithFormattedValues(paymentSituation);
}
