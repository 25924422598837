import { type PublishedPlaylist } from 'FrontRoyalStore';
import { type PlaylistIguanaObject } from 'Playlists';
import { type CurrentUserIguanaObject } from 'Users';

export default function getStreamPackIdsInCurriculumOrder(
    user: CurrentUserIguanaObject,
    playlists: (PublishedPlaylist | PlaylistIguanaObject)[],
) {
    if (user.relevantCohort) {
        return user.relevantCohort.getStreamPackIdsInCurriculumOrder(playlists);
    }
    return playlists
        .map(p => p.stream_entries)
        .flat()
        .map(entry => entry.locale_pack_id);
}
