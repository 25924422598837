
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.settings = window.Smartly.locales.modules.en.back_royal.settings || {};
window.Smartly.locales.modules.en.back_royal.settings.edit_preferences = window.Smartly.locales.modules.en.back_royal.settings.edit_preferences || {};
window.Smartly.locales.modules.en.back_royal.settings.edit_preferences = {...window.Smartly.locales.modules.en.back_royal.settings.edit_preferences, ...{
    "decimal_delim_desc": "Select how you will type decimal numbers. Note: this will not affect how numbers are displayed in lesson text.",
    "decimal_delim_title": "Decimal Input",
    "locale_desc": "Select your preferred language",
    "locale_title": "Preferred Language",
    "preferences": "PREFERENCES",
    "sound_enabled_desc": "Enable all sound effects.",
    "sound_enabled_desc_extra": "If you can't hear any sound effects, make sure your device is unmuted.",
    "sound_enabled_title": "Sound Effects",
    "update_preference_failure": "There was a problem updating your preferences. Please try again later.",
    "update_preference_success": "'{{title}}' successfully updated.",
    "show_photos_names_desc": "Optionally hide names and photos to reduce unconscious bias.",
    "show_photos_names_title": "Candidate Photos & Names",
    "show_photos_names_enabled": "Show Photos and Names",
    "show_photos_names_disabled": "Hide Photos and Names",
    "keyboard_shortcuts_desc": "Enable keyboard shortcuts, such as the enter/return key to advance to the next screen and numbers (1, 2, 3, etc.) to select answer choices.",
    "keyboard_shortcuts_title": "Keyboard Shortcuts",
    "one_click_reach_out_title": "One-click candidate reach out",
    "one_click_reach_out_desc": "Enable contacting candidates without specifying a position first.",
    "one_click_reach_out_enabled": "Enabled",
    "one_click_reach_out_disabled": "Disabled",
    "experimental": "Experimental",
    "warning_only_use_if_instructed": "Warning: only use this feature if directed to by {{brandName}} support.",
    "clear_local_db": "Clear local database",
    "database_cleared": "Database cleared",
    "offline_mode_title": "Offline Mode",
    "offline_mode_desc": "Enable downloading of courses for offline study."
}
}
    