import angularModule from 'Settings/angularModule/scripts/settings_module';
import * as userAgentHelper from 'userAgentHelper';
import { saveAs } from 'file-saver';
import moment from 'moment-timezone';
import getFilePathsFromManifest from 'WebpackManifestHelper';
import { fetchBrandConfig } from 'AppBranding';
import { GraduationStatus, getGraduationStatus, getProgramInclusion } from 'Users';
import { hasBeenExpelled, hasFailed, hasGraduated } from 'ProgramInclusion';
import { formatScore } from 'FormatScore';

// High-level ticket references:
//      https://trello.com/c/19VZXvuB
//      https://trello.com/c/1qojXmjm

angularModule.factory('DownloadableStudentDocsHelper', [
    '$injector',

    $injector => {
        const SuperModel = $injector.get('SuperModel');
        const $q = $injector.get('$q');
        const $http = $injector.get('$http');
        const $window = $injector.get('$window');
        const $ocLazyLoad = $injector.get('$ocLazyLoad');
        const safeDigest = $injector.get('safeDigest');
        const User = $injector.get('User');
        const Cohort = $injector.get('Cohort');
        const Playlist = $injector.get('Playlist');
        const Stream = $injector.get('Lesson.Stream');
        const $rootScope = $injector.get('$rootScope');
        const TextToImageHelper = $injector.get('TextToImageHelper');
        const StreamProgress = $injector.get('Lesson.StreamProgress');

        return SuperModel.subclass(() => ({
            initialize() {
                this.textToImageHelper = new TextToImageHelper();
            },

            _allowTranscriptDownload(user) {
                return (
                    !($window.CORDOVA || userAgentHelper.isiOSoriPadOSDevice()) &&
                    (user.canDownloadTranscripts || $rootScope.currentUser.hasAdminAccess)
                );
            },

            /**
             * @param format - Either 'digital' or 'printable'.
             * @param type - Either 'official' or 'unofficial', but
             *      cannot be 'unofficial' when `format` is 'printable'.
             */
            downloadTranscript(user, transcriptCohortId, format, type) {
                if (!this._allowTranscriptDownload(user)) {
                    return undefined;
                }

                // In the admin we need to grab more data than the User index endpoint provides, so do
                // a show call if asking for the transcript of a different user than the currentUser.
                let userPromise;
                if (user.id !== $rootScope.currentUser.id) {
                    userPromise = User.show(user.id).then(response => {
                        user = response.result;
                        return user;
                    });
                } else {
                    userPromise = $q.when($rootScope.currentUser);
                }

                return userPromise
                    .then(() => this._buildTranscriptData(user, transcriptCohortId))
                    .then(transcriptData => this._performDownload(transcriptData, format, type));
            },

            /**
             * @param document_type - Either 'enrollment_verification_letter' or 'progress_report'.
             */
            downloadSupplementalDocument(user, document_type, programInclusionId) {
                return $http
                    .get(
                        `${$window.ENDPOINT_ROOT}/api/users/${user.id}/${document_type}?program_inclusion_id=${programInclusionId}`,
                        {
                            responseType: 'arraybuffer',
                        },
                    )
                    .then(response => {
                        const file = new Blob([response.data], { type: 'application/zip' });
                        try {
                            const fileName = `${[document_type, user.name].join('-')}.docx`;

                            saveAs(file, fileName);
                            safeDigest($rootScope);
                        } catch (e) {
                            $injector
                                .get('ErrorLogService')
                                .notify(`Failed to write ${document_type} blob data to window!`, e);
                            throw e;
                        }
                    });
            },

            // Note: This could be optimized further for regular users by using the playlists and
            // streams already cached from the LearnerContentAccess calls, but that would require enough
            // work that I decided not to for something that should not be used frequently. It is
            // actually probably better from an accidental breakage perspective to not have this tied to
            // the caching logic, and since we want to use English titles regardless of user's locale we'd
            // have to make sure that the English title always gets returned in the LearnerContentAccess calls.
            _buildTranscriptData(fullUser, transcriptCohortId) {
                const self = this;

                let transcriptCohort;
                let serverTranscriptData;
                let playlists;
                let capstoneProjectProgress;
                const sortedCapstoneProjectProgresses = [];
                let sortedNonCapstoneProjectProgresses = [];
                let requiredStreamPackIds = [];
                let optionalStreamPackIds = [];
                let noncurriculumStreamPackIds = [];
                let streamPackIdsFromPlaylists = [];
                let streamPackIdsFromSortedDedupedCompletedSpecializationPlaylists = [];
                let streamPackIdsFromConcentrationPlaylists = [];
                let foundationsStreamPackIds = [];
                let finalScore = null;
                let sortedDedupedConcentrationPlaylists = null;
                let sortedDedupedCompletedSpecializationPlaylists = null;

                return (
                    $http
                        // Grab the requested cohort and a few pieces of data from the backend that we calculate in mat views
                        .get(
                            `${$window.ENDPOINT_ROOT}/api/users/${fullUser.id}/${transcriptCohortId}/show_transcript_data.json`,
                        )
                        .then(response => {
                            serverTranscriptData = response.data;
                            transcriptCohort = Cohort.new(serverTranscriptData.transcript_cohort);

                            // We don't want to display ungraded projects
                            const gradedProjectProgresses = serverTranscriptData.project_progresses_with_title.filter(
                                progress => progress.graded,
                            );

                            const sortedProjectProgresses = _.sortBy(
                                gradedProjectProgresses,
                                progress => progress.updated_at,
                            );

                            // Find the Capstone project progress if one exists
                            capstoneProjectProgress = sortedProjectProgresses.find(
                                progress => progress.project_type === 'capstone',
                            );

                            // If we have a Capstone, push its progress into the sortedCapstoneProjectProgresses array and find it's related
                            // presentation project progress. This might not have 'capstone' in the title or requirement_identifier.
                            // We should be safe to use the main Capstone project's requirement_identifier to locate the presentation project.
                            let capstonePresentationProjectProgress;
                            if (capstoneProjectProgress) {
                                sortedCapstoneProjectProgresses.push(capstoneProjectProgress);
                                capstonePresentationProjectProgress = sortedProjectProgresses.find(
                                    progress =>
                                        progress.id !== capstoneProjectProgress.id &&
                                        progress.requirement_identifier.includes(
                                            capstoneProjectProgress.requirement_identifier,
                                        ),
                                );
                            }

                            // Conditionally push the presentation progress to the capstone array. We don't want `undefined` in the array.
                            if (capstonePresentationProjectProgress) {
                                sortedCapstoneProjectProgresses.push(capstonePresentationProjectProgress);
                            }

                            // Once all Capstone related progresses are identified, we can filter all project progresses and move
                            // non-Capstone items into their own array.
                            sortedNonCapstoneProjectProgresses = sortedProjectProgresses.filter(
                                progress =>
                                    ![capstoneProjectProgress?.id, capstonePresentationProjectProgress?.id].includes(
                                        progress.id,
                                    ),
                            );

                            // Grab the full specialization playlists in the user's locale or en. Ask for percent_complete
                            // so we can filter down to just the completed ones for the transcript
                            return Playlist.index({
                                user_id: fullUser.id,
                                filters: {
                                    locale_pack_id: transcriptCohort.playlistPackIds,
                                    locale: 'en',
                                },
                                'fields[]': ['id', 'title', 'stream_entries', 'locale_pack', 'percent_complete'],
                            });
                        })
                        .then(response => {
                            playlists = response.result;

                            requiredStreamPackIds = serverTranscriptData.required_stream_locale_pack_ids;
                            optionalStreamPackIds = serverTranscriptData.optional_stream_locale_pack_ids;
                            noncurriculumStreamPackIds = serverTranscriptData.noncurriculum_stream_locale_pack_ids;
                            foundationsStreamPackIds =
                                serverTranscriptData.transcript_cohort.foundations_lesson_stream_locale_pack_ids;
                            finalScore = serverTranscriptData.final_score;

                            // Function to chop down the stream_entries such that no streams are repeated if they're duplicated among multiple playlists.
                            // We're running this on the sorted playlists, required first, so that earlier playlists take precedence as owner
                            // of the stream. As we run it we'll track which streams are in a playlist, both for use as we iterate over the sorted playlists
                            // and to later determine which other required streams, not in a playlist, to display as a separate transcript item.
                            function dedupeStreamEntries(playlist) {
                                // Dedupe the stream_entries into a temporary variable
                                playlist.$$dedupedStreamEntries = playlist.stream_entries.filter(
                                    streamEntry => !streamPackIdsFromPlaylists.includes(streamEntry.locale_pack_id),
                                );

                                // Add the deduped locale_pack_ids to a variable for tracking
                                streamPackIdsFromPlaylists = streamPackIdsFromPlaylists.concat(
                                    playlist.$$dedupedStreamEntries.map(streamEntry => streamEntry.locale_pack_id),
                                );

                                return playlist;
                            }

                            // Required playlists are sorted by their index in concentrationPlaylistPackIds
                            sortedDedupedConcentrationPlaylists = this._getSortedPlaylists(
                                playlists,
                                transcriptCohort.concentrationPlaylistPackIds.filter(
                                    playlistPackId =>
                                        // Remove the foundations playlist so its courses will flow into either their
                                        // respective concentration or electives, rather than a beginning entry at the top
                                        playlistPackId !== transcriptCohort.foundationsPlaylistLocalePackId,
                                ),
                            ).map(dedupeStreamEntries);

                            // Specialization playlists are sorted by their index in the specialization_playlist_pack_ids.
                            // Also filter to only the ones complete. We'll bucket any complete courses from an
                            // incomplete specialization playlist in the optional courses bucket.
                            sortedDedupedCompletedSpecializationPlaylists = this._getSortedPlaylists(
                                playlists,
                                transcriptCohort.specialization_playlist_pack_ids,
                            )
                                .filter(playlist => playlist.percent_complete === 1)
                                .map(dedupeStreamEntries);

                            streamPackIdsFromSortedDedupedCompletedSpecializationPlaylists = _.chain(
                                sortedDedupedCompletedSpecializationPlaylists,
                            )
                                .map('$$dedupedStreamEntries')
                                .flattenDeep()
                                .map(streamEntry => streamEntry.locale_pack_id)
                                .uniq()
                                .value();

                            streamPackIdsFromConcentrationPlaylists = _.chain(sortedDedupedConcentrationPlaylists)
                                .map('$$dedupedStreamEntries')
                                .flattenDeep()
                                .map(streamEntry => streamEntry.locale_pack_id)
                                .uniq()
                                .value();

                            let allStreamPackIds = requiredStreamPackIds
                                .concat(optionalStreamPackIds)
                                .concat(streamPackIdsFromSortedDedupedCompletedSpecializationPlaylists)
                                .concat(noncurriculumStreamPackIds);

                            allStreamPackIds = [...new Set(allStreamPackIds)];

                            return Stream.index({
                                include_progress: true,
                                include_transcript_data: true,
                                'fields[]': [
                                    'id',
                                    'title',
                                    'lessons',
                                    'chapters',
                                    'image',
                                    'locale_pack',
                                    'lesson_streams_progress',
                                    'time_limit_hours',
                                    'exam',
                                ],
                                'lesson_fields[]': [
                                    'id',
                                    'title',
                                    'assessment',
                                    'locale_pack',
                                    'lesson_progress',
                                    'test',
                                ],
                                user_id: fullUser.id,
                                filters: {
                                    locale_pack_id: allStreamPackIds,
                                    // Force English for display purposes
                                    locale: 'en',
                                    // Explicitly pass this as false - StreamController will default it to true for index
                                    // calls if it isn't passed, which results in the non-En streams being filtered from
                                    // the controller response due to the nature of how the joins are constructed.
                                    // In the future, when and if we support different languages in transcripts, we'll
                                    // need to revisit this and refactor the controller to the do the correct thing.
                                    // See also: https://trello.com/c/A5xbtgdm
                                    in_users_locale_or_en: false,

                                    // StreamsAccessMixin will filter on the user's pending or success cohorts unless we set this to false.
                                    // See also: https://trello.com/c/Re5tkDHX
                                    // Note: this is safe to do because, contrary to being in the Settings module, this feature is only available to admins
                                    user_can_see: false,
                                },
                            });
                        })
                        .then(response => {
                            const allStreams = response.result;

                            const streamsToCurriculumTypeMap = {
                                required: [],
                                completedSpecialization: [],
                                elective: [],
                            };

                            const streamsByLocalePackId = _.keyBy(allStreams, 'localePackId');

                            allStreams.forEach(stream => {
                                const isRequiredStream = requiredStreamPackIds.includes(stream.localePackId);
                                const isCompletedSpecializationStream =
                                    streamPackIdsFromSortedDedupedCompletedSpecializationPlaylists.includes(
                                        stream.localePackId,
                                    );
                                const isConcentrationStream = streamPackIdsFromConcentrationPlaylists.includes(
                                    stream.localePackId,
                                );

                                // I write it this way because it is possible to have a complete specialization stream that is
                                // also required, so put a stream like that in both buckets
                                if (isRequiredStream || isCompletedSpecializationStream) {
                                    if (isRequiredStream) {
                                        streamsToCurriculumTypeMap.required.push(stream);
                                    }
                                    if (isCompletedSpecializationStream) {
                                        streamsToCurriculumTypeMap.completedSpecialization.push(stream);
                                    }
                                } else if (!isConcentrationStream) {
                                    // Any course not in a concentration or complete specialization playlist is deemed elective
                                    // including noncurriculum streams
                                    streamsToCurriculumTypeMap.elective.push(stream);
                                }
                                // NOTE: a stream that is not required, not in a specialization but IS in a
                                // concentration stream is not added to streamsToCurriculumTypeMap.  However, that stream
                                // will still show up in the course schedule section, since we look in the
                                // concentration playlists to find which streams to put there. (as of 2021/01/29,
                                // this was the case with Customer Discovery in the EMBA curriculum. That stream
                                // is in the foundations concentration, but not required in the schedule.)
                            });

                            // Sort required streams by the order they appear in the periods if the cohort
                            // supports a schedule, or the title otherwise. Do a sort on the title in both cases since
                            // it is possible to have required streams not in the periods. E.g., a stream is in the foundations
                            // playlist and thus required, but isn't actually specified in the periods. This happened with Blue
                            // Ocean Strategy in EMBA1).
                            let sortedRequiredStreams = _.sortBy(
                                streamsToCurriculumTypeMap.required,
                                stream => stream.title,
                            );

                            if (transcriptCohort.supportsSchedule) {
                                const requiredStreamPackIdsFromPeriods =
                                    transcriptCohort.getRequiredStreamPackIdsFromPeriods();
                                sortedRequiredStreams = _.sortBy(sortedRequiredStreams, stream => {
                                    // Make sure we put streams not found in the periods at the end of the list
                                    const index = requiredStreamPackIdsFromPeriods.indexOf(stream.localePackId);
                                    return index > -1 ? index : Number.MAX_SAFE_INTEGER;
                                });
                            }

                            // Sort elective streams by their title
                            // Note: We are ensuring that the elective stream is not required in a period or included in
                            // a specialization playlist.
                            const sortedElectiveStreams = _.sortBy(
                                streamsToCurriculumTypeMap.elective,
                                stream => stream.title,
                            );

                            let sortedDedupedConcentrationPlaylistTranscriptEntries =
                                this._getPlaylistTranscriptEntries(
                                    sortedDedupedConcentrationPlaylists,
                                    streamsByLocalePackId,
                                    transcriptCohort.graduationDate,
                                );

                            const sortedDedupedCompletedSpecializationPlaylistTranscriptEntries =
                                this._getPlaylistTranscriptEntries(
                                    sortedDedupedCompletedSpecializationPlaylists,
                                    streamsByLocalePackId,
                                    transcriptCohort.graduationDate,
                                );

                            // Filter out any required playlists with no stream complete so that we don't have an empty entry
                            // in a transcript for a user who hasn't graduated
                            sortedDedupedConcentrationPlaylistTranscriptEntries =
                                sortedDedupedConcentrationPlaylistTranscriptEntries.filter(
                                    playlistEntry => playlistEntry.streamEntries.length > 0,
                                );

                            // Calculate the remaining required streams not in a playlist for placing in a special top-level
                            // section alongside playlists
                            const sortedRequiredStreamsNotInPlaylist = sortedRequiredStreams.filter(
                                stream => !streamPackIdsFromPlaylists.includes(stream.localePackId),
                            );

                            const cumulativeGradeTextLookup = {
                                pending: 'IN PROGRESS',
                                failed: 'FAIL',
                                graduated: 'PASS',
                                honors: 'PASS WITH HONORS',
                            };

                            const graduationStatus = getGraduationStatus(fullUser, {
                                cohortId: transcriptCohort.id,
                            });

                            return {
                                branding: transcriptCohort.branding,
                                sortedDedupedConcentrationPlaylistTranscriptEntries,
                                sortedDedupedCompletedSpecializationPlaylistTranscriptEntries,
                                sortedCompleteRequiredStreamTranscriptEntriesNotInPlaylist:
                                    self._getStreamTranscriptEntries(sortedRequiredStreamsNotInPlaylist),
                                sortedCompleteElectiveStreamTranscriptEntries:
                                    self._getStreamTranscriptEntries(sortedElectiveStreams),
                                sortedNonCapstoneProjectProgresses,
                                sortedCapstoneProjectProgresses,
                                capstoneProjectProgress,
                                finalScore,
                                creditHours: transcriptCohort.credit_hours,
                                cumulativeGradeText: cumulativeGradeTextLookup[graduationStatus],
                                graduationStatus,
                                graduationDateText: self._getGraduationDateText(fullUser, transcriptCohort),
                                programType: transcriptCohort.program_type,
                                isDegreeProgram: transcriptCohort.isDegreeProgram,
                                transcriptProgramTitle: transcriptCohort.transcriptProgramTitle,
                                transcriptDegreeConferredTitle: transcriptCohort.transcriptDegreeConferredTitle,
                                name: fullUser.name,
                                email: fullUser.email,
                                addressLine1: fullUser.address_line_1,
                                addressLine2: fullUser.address_line_2,
                                city: fullUser.city,
                                state: fullUser.state,
                                zip: fullUser.zip,
                                country: fullUser.country,
                                foundationsStreamPackIds,
                                preferStrictSmartCaseScore: fullUser.preferStrictSmartCaseScore,
                            };
                        })
                );
            },

            _getGraduationDateText(fullUser, transcriptCohort) {
                const programInclusion = getProgramInclusion(fullUser, { cohortId: transcriptCohort.id });

                if (hasFailed(programInclusion) || !transcriptCohort.supportsSchedule) {
                    return 'N/A';
                }

                if (hasBeenExpelled(programInclusion)) {
                    return 'Will not graduate';
                }

                if (hasGraduated(programInclusion)) {
                    return moment(programInclusion.graduatedAt * 1000).format('MMMM D, YYYY');
                }

                if (new Date(transcriptCohort.startDate).getTime() > new Date().getTime()) {
                    return 'TBD';
                }

                return moment(transcriptCohort.graduationDate).format('MMMM D, YYYY');
            },

            _getSortedPlaylists(playlists, playlistPackIds) {
                return playlists
                    .filter(playlist => playlistPackIds.includes(playlist.localePackId))
                    .sort(
                        (playlist, nextPlaylist) =>
                            playlistPackIds.indexOf(playlist.localePackId) -
                            playlistPackIds.indexOf(nextPlaylist.localePackId),
                    );
            },

            _getPlaylistTranscriptEntries(sortedPlaylists, streamsByLocalePackId, graduationDate) {
                // Build out playlist transcript entries for each of the playlists
                const playlistTranscriptEntries = [];

                // Build each playlist's stream transcript entries
                sortedPlaylists.forEach(playlist => {
                    const streams = [];

                    playlist.$$dedupedStreamEntries.forEach(streamEntry => {
                        streams.push(streamsByLocalePackId[streamEntry.locale_pack_id]);
                    });

                    const playlistEntry = {
                        title: playlist.title,
                        streamEntries: this._getStreamTranscriptEntries(streams),
                    };

                    // Course codes become consistent with cycle 38
                    if (graduationDate >= new Date('2022/10/18')) {
                        Object.assign(playlistEntry, {
                            course_code_prefix: playlist.locale_pack.course_code_prefix,
                            course_code: playlist.locale_pack.course_code,
                        });
                    }

                    playlistTranscriptEntries.push(playlistEntry);
                });

                return playlistTranscriptEntries;
            },

            _getStreamTranscriptEntries(streams) {
                // Remove any uncompleted streams
                streams = streams.filter(stream => stream.complete);

                // Remove any streams with "Exam Practice" or "Coffee Break" in the title
                streams = streams.filter(stream => {
                    const lowerTitle = stream.title.toLowerCase();
                    return !(
                        lowerTitle.includes('exam practice') ||
                        lowerTitle.includes('coffee break') ||
                        lowerTitle.includes('photography basics') ||
                        lowerTitle.includes('why blended learning matters')
                    );
                });

                // Remove any streams that were artificially completed due to
                // the exam being completed in a prior cohort, and for which the user
                // didn't take the initiative to go complete themselves (we unset waiver
                // if they do)
                // See https://trello.com/c/6xyHPQuo
                streams = streams.filter(
                    stream => stream.progressWaiver !== StreamProgress.WAIVER_EXAM_ALREADY_COMPLETED,
                );

                return streams.map(stream => ({
                    title: stream.title,
                    grade: stream.grade,
                    localePackId: stream.localePackId,
                }));
            },

            _performDownload(transcriptData, format, type) {
                const self = this;

                const requests = [];
                let backgroundTranscriptResponse;
                let backPageTranscriptResponse;
                const isUnofficial = type === 'unofficial';
                const textColor = !isUnofficial ? '#000000' : '#666665';
                const isDigital = format === 'digital';
                const isPrintable = format === 'printable';
                const includeBackPage = format !== 'printable';
                let frontPageBackgroundImagePath;
                let backPageImagePath;
                const STANDARD_FONT_SIZE = 9;
                const LARGER_FONT_SIZE = 10;

                const graduated = [GraduationStatus.graduated, GraduationStatus.honors].includes(
                    transcriptData.graduationStatus,
                );

                const brandConfig = fetchBrandConfig(transcriptData.branding);
                const {
                    digitalFrontFull,
                    digitalFrontFullUnofficial,
                    digitalFrontPartial,
                    digitalFrontPartialUnofficial,
                    digitalBack,
                    digitalBackUnofficial,
                    printableFrontFull,
                    printableFrontPartial,
                    printableBack,
                } = brandConfig.transcriptImages;

                if (isPrintable && isUnofficial) {
                    throw new Error('Printable unofficial transcripts are not available');
                } else if (graduated && isDigital) {
                    frontPageBackgroundImagePath =
                        isUnofficial && digitalFrontFullUnofficial ? digitalFrontFullUnofficial : digitalFrontFull;
                    backPageImagePath = isUnofficial && digitalBackUnofficial ? digitalBackUnofficial : digitalBack;
                } else if (!graduated && isDigital) {
                    frontPageBackgroundImagePath =
                        isUnofficial && digitalFrontPartialUnofficial
                            ? digitalFrontPartialUnofficial
                            : digitalFrontPartial;
                    backPageImagePath = isUnofficial && digitalBackUnofficial ? digitalBackUnofficial : digitalBack;
                } else if (graduated && isPrintable) {
                    frontPageBackgroundImagePath = printableFrontFull;
                    backPageImagePath = printableBack;
                } else if (!graduated && isPrintable) {
                    frontPageBackgroundImagePath = printableFrontPartial;
                    backPageImagePath = printableBack;
                } else {
                    throw new Error(`${format} format not supported!`);
                }

                // get background image
                requests.push(
                    $http
                        .get(window.ENDPOINT_ROOT + frontPageBackgroundImagePath, {
                            responseType: 'arraybuffer',
                        })
                        .then(response => {
                            backgroundTranscriptResponse = response;
                        }),
                );

                // get back page
                if (includeBackPage) {
                    requests.push(
                        $http
                            .get(window.ENDPOINT_ROOT + backPageImagePath, {
                                responseType: 'arraybuffer',
                            })
                            .then(response => {
                                backPageTranscriptResponse = response;
                            }),
                    );
                }

                // grab the needed assets in parallel and cache them for rendering the PDF
                getFilePathsFromManifest('certificates', 'js').forEach(path => {
                    requests.push($ocLazyLoad.load(path));
                });

                $q.all(requests).then(() => {
                    // Order matters and there doesn't seem to be a way to specify layers so used the cached
                    // assets to assemble the PDF here in the correct order.

                    // create the pdfkit Document
                    const doc = new $window.PDFDocument({
                        size: [612, 793],
                        margin: 0,
                    });
                    const pdfStream = doc.pipe($window.blobStream());

                    // set the background
                    doc.image(backgroundTranscriptResponse.data, 0, 0, {
                        width: 612,
                        height: 793,
                    });

                    // The transcriptProgramTitle's for both Valar programs take up three lines
                    // of text instead of 2, so we should move them up a bit.
                    const isValar = transcriptData.branding === 'valar';
                    const transcriptProgramTitleHeight = isValar ? 26 : 36;

                    // header text
                    doc.font('Courier-Bold')
                        .fontSize(STANDARD_FONT_SIZE)
                        .fillColor(textColor)
                        .text(transcriptData.transcriptProgramTitle, 270, transcriptProgramTitleHeight, {
                            width: 156,
                        })
                        .text('Graduate', 270, 56);

                    // Conditionally add the name in the header as text or an image depending
                    // on if it contains characters outside of extended ASCII
                    if (self.textToImageHelper.isASCII(transcriptData.name)) {
                        doc.fontSize(STANDARD_FONT_SIZE).text(transcriptData.name, 270, 66, {
                            width: 156,
                        });
                    } else {
                        self.textToImageHelper.addImageTextToPdf(doc, transcriptData.name, 270, 66, {
                            width: 156,
                            fontSize: STANDARD_FONT_SIZE,
                            font: 'Courier-Bold',
                        });
                    }

                    // ***********************************************
                    // Middle left header info
                    // ***********************************************

                    const addressLocation = `${transcriptData.city}, ${
                        transcriptData.state ? transcriptData.state : transcriptData.country
                    } ${transcriptData.zip ? transcriptData.zip : ''}`;

                    // Combine all of the "Issued To" information to test if any of it contains characters outside of
                    // extended ASCII.
                    let issuedToText = `${transcriptData.name}\n`;

                    if (transcriptData.addressLine1) {
                        issuedToText += `${transcriptData.addressLine1}\n`;

                        if (transcriptData.addressLine2) {
                            issuedToText += `${transcriptData.addressLine2}\n`;
                        }

                        issuedToText += `${addressLocation}\n\n`;
                    } else {
                        issuedToText += 'Mailing address unavailable\n\n';
                    }

                    issuedToText += transcriptData.email;

                    // Conditionally add "Issued To" information as text or an image depending
                    // on if it contains characters outside of extended ASCII
                    const issuedToWidth = 250;
                    const issuedToX = 43;
                    const issuedToY = 112;
                    if (self.textToImageHelper.isASCII(issuedToText)) {
                        doc.fontSize(STANDARD_FONT_SIZE)
                            .text(transcriptData.name, issuedToX, issuedToY, {
                                width: issuedToWidth,
                            })
                            .moveDown(0);

                        if (transcriptData.addressLine1) {
                            doc.text(transcriptData.addressLine1, {
                                width: issuedToWidth,
                            }).moveDown(0);

                            if (transcriptData.addressLine2) {
                                doc.text(transcriptData.addressLine2, {
                                    width: issuedToWidth,
                                }).moveDown(0);
                            }

                            doc.text(addressLocation, {
                                width: issuedToWidth,
                            }).moveDown(1);
                        } else {
                            doc.text('Mailing address unavailable', {
                                width: issuedToWidth,
                            }).moveDown(1);
                        }

                        doc.text(transcriptData.email, {
                            width: issuedToWidth,
                        });
                    } else {
                        self.textToImageHelper.addImageTextToPdf(doc, issuedToText, issuedToX, issuedToY, {
                            width: issuedToWidth,
                            fontSize: STANDARD_FONT_SIZE,
                            font: 'Courier-Bold',
                        });
                    }

                    // ***********************************************
                    // Middle right header info
                    // ***********************************************

                    doc.text(moment().format('MMMM D, YYYY'), 397, 112)
                        .text(transcriptData.graduationDateText, 397, 151)
                        .moveDown(0.25);

                    const summaryInfoX = 310;

                    if (graduated) {
                        // Note: Only MBA23 / EMBA18 and on have credit hours
                        if (transcriptData.creditHours) {
                            doc.text(
                                `TOTAL PROGRAM CREDITS: ${transcriptData.creditHours} CREDIT HOURS`,
                                summaryInfoX,
                            ).moveDown(0);
                        }

                        doc.text(
                            `CUMULATIVE FINAL SCORE: ${this._formatScore({
                                grade: transcriptData.finalScore,
                                isFinalScore: true,
                            })}%`,
                            summaryInfoX,
                        ).moveDown(0);

                        if (transcriptData.isDegreeProgram) {
                            doc.text(this._getDegreeConferredText(transcriptData), summaryInfoX).moveDown(0);
                        }
                    }

                    if (!transcriptData.isDegreeProgram) {
                        doc.text(`CUMULATIVE GRADE: ${transcriptData.cumulativeGradeText}`, summaryInfoX);
                    }

                    // Absolute position values derived from the mock
                    const entryWidth = 284;
                    const entryScoreWidth = 26;
                    const entryTitleWidth = 284 - entryScoreWidth - 5; // give the title a little right padding
                    const entryTabWidth = 5;
                    const columnTopY = 216;
                    const leftColumnStartX = 18;
                    const leftColumnScoreX = leftColumnStartX + entryWidth - entryScoreWidth;
                    const rightColumnStartX = 315;
                    const rightColumnScoreX = rightColumnStartX + entryWidth - entryScoreWidth;
                    const leftColumnWrapPoint = 739;

                    // Position tracking for our manual wrapping into the right column
                    let leftColumnHasWrapped = false;
                    let runningColumnStartX = leftColumnStartX;
                    let runningColumnScoreX = leftColumnScoreX;

                    const ENTRY_FONT_SIZE = this._getEntryFontSize(transcriptData);

                    function checkWrapping() {
                        if (doc.y > leftColumnWrapPoint && !leftColumnHasWrapped) {
                            doc.text('', rightColumnStartX, columnTopY);
                            leftColumnHasWrapped = true;
                            runningColumnStartX = rightColumnStartX;
                            runningColumnScoreX = rightColumnScoreX;
                        }
                    }

                    function writeEntryWithGrade(entry, isProject = false, markAsPassed = false) {
                        doc.text(`${entry.title}`, runningColumnStartX + entryTabWidth, doc.y, {
                            width: entryTitleWidth,
                        });
                        doc.moveUp();

                        let grade;
                        if (markAsPassed) {
                            grade = self._formatScore({ isUnpassed: false });
                        } else if (isProject) {
                            grade = self._formatScore({ isProject, isUnpassed: entry.unpassed });
                        } else {
                            grade = self._formatScore({ grade: entry.grade });
                        }

                        doc.text(grade, runningColumnScoreX);
                    }

                    // ***********************************************
                    // Curriculum courses list
                    //
                    // The list of courses that are in concentration playlists or required in the schedule, organized by playlist and in the same order
                    // as they area in the playlist collection. For courses that are in the schedule but not
                    // in a playlist, we'll put them at the end as top-level entries.
                    // ***********************************************

                    if (transcriptData.sortedDedupedConcentrationPlaylistTranscriptEntries.length > 0) {
                        doc.font('Courier-Bold')
                            .fontSize(LARGER_FONT_SIZE)
                            .fillColor(textColor)
                            .text('*************** COURSE SCHEDULE ***************', leftColumnStartX, columnTopY);

                        doc.font('Courier').fontSize(ENTRY_FONT_SIZE).moveDown(0.25);

                        transcriptData.sortedDedupedConcentrationPlaylistTranscriptEntries.forEach(playlistEntry => {
                            checkWrapping();
                            doc.text(
                                this._formatPlaylistTitle(playlistEntry).toUpperCase(),
                                runningColumnStartX,
                            ).moveDown(0);

                            playlistEntry.streamEntries.forEach(streamEntry => {
                                checkWrapping();
                                const markAsPassed = this._omitEntryFromGrade(
                                    transcriptData.preferStrictSmartCaseScore,
                                    transcriptData.foundationsStreamPackIds,
                                    streamEntry,
                                );
                                writeEntryWithGrade(streamEntry, false, markAsPassed);
                            });

                            doc.moveDown(0.25);
                        });

                        // Capstone projects are included under a special 'CAPSTONE' category
                        if (transcriptData.sortedCapstoneProjectProgresses.length > 0) {
                            const capstonePlaylist = {
                                title: 'CAPSTONE',
                                course_code_prefix: transcriptData.capstoneProjectProgress.course_code_prefix,
                                course_code: transcriptData.capstoneProjectProgress.course_code,
                            };
                            checkWrapping();
                            doc.text(this._formatPlaylistTitle(capstonePlaylist), runningColumnStartX).moveDown(0);

                            transcriptData.sortedCapstoneProjectProgresses.forEach(entry => {
                                checkWrapping();
                                writeEntryWithGrade(entry, true);
                            });

                            doc.moveDown(0.25);
                        }

                        // required streams not in a playlist will be placed under a special top-level category
                        if (transcriptData.sortedCompleteRequiredStreamTranscriptEntriesNotInPlaylist.length > 0) {
                            checkWrapping();
                            doc.text('OTHER REQUIREMENTS', runningColumnStartX).moveDown(0);

                            transcriptData.sortedCompleteRequiredStreamTranscriptEntriesNotInPlaylist.forEach(entry => {
                                checkWrapping();
                                const markAsPassed = this._omitEntryFromGrade(
                                    transcriptData.preferStrictSmartCaseScore,
                                    transcriptData.foundationsStreamPackIds,
                                    entry,
                                );
                                writeEntryWithGrade(entry, false, markAsPassed);
                            });

                            doc.moveDown(0.25);
                        }

                        doc.moveDown(0.25);
                    }

                    // ***********************************************
                    // Project list
                    // ***********************************************

                    if (transcriptData.sortedNonCapstoneProjectProgresses.length > 0) {
                        checkWrapping();
                        doc.font('Courier-Bold')
                            .fontSize(LARGER_FONT_SIZE)
                            .text('****************** PROJECTS ******************', runningColumnStartX);

                        doc.font('Courier').fontSize(ENTRY_FONT_SIZE).moveDown(0.25);

                        transcriptData.sortedNonCapstoneProjectProgresses.forEach(entry => {
                            checkWrapping();
                            writeEntryWithGrade(entry, true);
                        });

                        doc.moveDown(0.5);
                    }

                    // ***********************************************
                    // Complete specialization courses list
                    //
                    // The list of courses in complete specializations, organized by playlist and in the same order
                    // as cohorts.specialization_playlist_pack_ids.
                    // ***********************************************

                    if (transcriptData.sortedDedupedCompletedSpecializationPlaylistTranscriptEntries.length > 0) {
                        checkWrapping();
                        doc.font('Courier-Bold')
                            .fontSize(LARGER_FONT_SIZE)
                            .fillColor(textColor)
                            .text('*************** SPECIALIZATIONS ***************', runningColumnStartX);

                        doc.font('Courier').fontSize(ENTRY_FONT_SIZE).moveDown(0.25);

                        transcriptData.sortedDedupedCompletedSpecializationPlaylistTranscriptEntries.forEach(
                            playlistEntry => {
                                checkWrapping();
                                doc.text(
                                    this._formatPlaylistTitle(playlistEntry).toUpperCase(),
                                    runningColumnStartX,
                                ).moveDown(0);

                                playlistEntry.streamEntries.forEach(streamEntry => {
                                    checkWrapping();
                                    const markAsPassed = this._omitEntryFromGrade(
                                        transcriptData.preferStrictSmartCaseScore,
                                        transcriptData.foundationsStreamPackIds,
                                        streamEntry,
                                    );
                                    writeEntryWithGrade(streamEntry, false, markAsPassed);
                                });

                                doc.moveDown(0.25);
                            },
                        );

                        doc.moveDown(0.25);
                    }

                    // ***********************************************
                    // Complete elective courses list
                    //
                    // Basically, the list of complete courses that don't fit into either the course schedule or specialization
                    // ***********************************************

                    if (transcriptData.sortedCompleteElectiveStreamTranscriptEntries.length > 0) {
                        checkWrapping();
                        doc.font('Courier-Bold')
                            .fontSize(LARGER_FONT_SIZE)
                            .text('****************** ELECTIVES ******************', runningColumnStartX);

                        doc.font('Courier').fontSize(ENTRY_FONT_SIZE).moveDown(0.25);

                        transcriptData.sortedCompleteElectiveStreamTranscriptEntries.forEach(entry => {
                            checkWrapping();
                            const markAsPassed = this._omitEntryFromGrade(
                                transcriptData.preferStrictSmartCaseScore,
                                transcriptData.foundationsStreamPackIds,
                                entry,
                            );
                            writeEntryWithGrade(entry, false, markAsPassed);
                        });

                        doc.moveDown(0.5);
                    }

                    // back of document
                    if (includeBackPage) {
                        doc.addPage().image(backPageTranscriptResponse.data, 0, 0, {
                            width: 612,
                            height: 793,
                        });
                    }

                    // set the finish event
                    pdfStream.on('finish', () => {
                        const blob = pdfStream.toBlob('application/pdf');
                        try {
                            const fileName = `${[transcriptData.name, transcriptData.programType, 'Transcript'].join(
                                '-',
                            )}.pdf`;

                            saveAs(blob, fileName);
                            safeDigest($rootScope);
                        } catch (e) {
                            $injector
                                .get('ErrorLogService')
                                .notify('Failed to write certificate blob data to window!', e);
                        }
                    });

                    doc.end();
                });
            },

            _getEntryFontSize(transcriptData) {
                let entryCount = 0;

                entryCount += transcriptData.sortedDedupedConcentrationPlaylistTranscriptEntries.length;
                entryCount += transcriptData.sortedDedupedConcentrationPlaylistTranscriptEntries
                    .map(playlistEntry => playlistEntry.streamEntries)
                    .flat().length;

                if (transcriptData.sortedCapstoneProjectProgresses.length > 0) {
                    entryCount += transcriptData.sortedCapstoneProjectProgresses.length;
                    // add an entry for the fake 'CAPSTONE' playlist title
                    entryCount += 1;
                }

                if (transcriptData.sortedCompleteRequiredStreamTranscriptEntriesNotInPlaylist.length > 0) {
                    entryCount += transcriptData.sortedCompleteRequiredStreamTranscriptEntriesNotInPlaylist.length;
                    // add an entry for the fake 'OTHER REQUIREMENTS' playlist title
                    entryCount += 1;
                }

                entryCount += transcriptData.sortedNonCapstoneProjectProgresses.length;

                entryCount += transcriptData.sortedDedupedCompletedSpecializationPlaylistTranscriptEntries.length;
                entryCount += transcriptData.sortedDedupedCompletedSpecializationPlaylistTranscriptEntries
                    .map(playlistEntry => playlistEntry.streamEntries)
                    .flat().length;

                entryCount += transcriptData.sortedCompleteElectiveStreamTranscriptEntries.length;

                // Empirical measures as of Jan 4, 2022 that seem to work
                // Note: the current max entries is 148 with *all* content complete for EMBA40+,
                // but this adds an additional breakpoint to give us some headroom just in case.
                // Eventually, we'll want to support breaking this onto a second page.
                if (entryCount > 155) {
                    return 5;
                }
                if (entryCount > 129) {
                    return 6;
                }
                if (entryCount > 112) {
                    return 7;
                }
                return 8;
            },

            _getDegreeConferredText(transcriptData) {
                let degreeConferredString = `*** DEGREE CONFERRED: ${transcriptData.transcriptDegreeConferredTitle} `;
                if (transcriptData.graduationStatus === GraduationStatus.honors) {
                    degreeConferredString += `WITH HONORS `;
                }
                degreeConferredString += '***';
                return degreeConferredString;
            },

            _omitEntryFromGrade(preferStrictSmartCaseScore, streamLocalePacksToOmit, streamEntry) {
                return preferStrictSmartCaseScore && !!streamLocalePacksToOmit?.includes(streamEntry?.localePackId);
            },

            _formatScore({ grade = null, isProject = false, isUnpassed = false, isFinalScore = false } = {}) {
                if (isProject) {
                    return isUnpassed ? 'F' : 'P';
                }
                if ([null, undefined].includes(grade) && !isUnpassed && !isFinalScore) {
                    return 'P';
                }
                // We're left with a number grade or a final score, which are formatted the same.
                // Both are expected to be between 0 and 1 inclusive, and are formatted as percentages.
                const score = parseFloat(grade);
                if (Number.isNaN(score) || score < 0 || score > 1) {
                    throw new Error(`score must be a number between 0 and 1 (inclusive). Received: ${grade}`);
                }
                return formatScore(score);
            },

            _formatPlaylistTitle(playlist) {
                if (playlist.course_code_prefix && playlist.course_code) {
                    return `${playlist.course_code_prefix} ${playlist.course_code} - ${playlist.title}`;
                }

                return playlist.title;
            },
        }));
    },
]);
