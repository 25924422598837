// A cumulative scholarship is an object that is generated by all of a TuitionContract's scholarships
// or a CohortSectionOffer's offeredScholarships. We use cumulative scholarships for the following:
// - Matching up payment situations based on the total scholarship
// - To determine if the user has a full/partial scholarship
// - Sometimes for display if we need to show the dollar amount of the total scholarship
//
// As such, we only include `amount` and `level` here on the type to actively discourage folks
// from trying to access other things that they shouldn't use (like an ID).
export interface CumulativeScholarship {
    amount: number | null; // have to support null to support NO_SCHOLARSHIP, even though this is never null in the db
    level: number;
}
export interface Scholarship extends CumulativeScholarship {
    id: string;
    label: string | null;
    name: string;
}

export enum ScholarshipCategory {
    merit = 'merit',
    impact = 'impact',
    employer_partner = 'employer_partner',
    recruitment = 'recruitment',
    need = 'need',
    alumni = 'alumni',
}

export type ScholarshipConfig = {
    category: ScholarshipCategory;
    label: string | null;
    description: string;
    requiresStatement?: boolean;
    statementLabel?: string;
    scholarshipBasis: 'merit' | 'need' | 'employer';
};
