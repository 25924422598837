import { type CountryCode } from 'libphonenumber-js';

// NOTE: This country availability configuration for Buy Now Pay Later methods is also
// enforced on the back-end in StripeExtensions::PaymentMethod module, see self.supported_payment_methods

// https://docs.klarna.com/marketing/solutions/grab-and-go/us/on-your-site/website-landing-page-and-faq/
export const KLARNA_AVAILABILITY_COUNTRIES: Partial<Record<CountryCode, boolean>> = {
    AT: true, // Austria
    AU: true, // Australia
    BE: true, // Belgium
    CA: true, // Canada
    CH: true, // Switzerland
    CZ: true, // Czechia
    DE: true, // Germany
    DK: true, // Denmark
    ES: true, // Spain
    FI: true, // Finland
    FR: true, // France
    GB: true, // United Kingdom
    GR: true, // Greece
    IE: true, // Ireland
    IT: true, // Italy
    NL: true, // The Netherlands
    NZ: true, // New Zealand
    NO: true, // Norway
    PL: true, // Poland
    PR: true, // Puerto Rico - Klarna is not available in other US territories
    PT: true, // Portugal,
    SE: true, // Sweden,
    US: true, // United States
};

// Klarna is not available in the following US states
export const KLARNA_EXCLUDED_STATES: Partial<Record<string, boolean>> = {
    'New Mexico': true,
    Hawaii: true,
};
