import { useMemo } from 'react';
import { type ProgramType } from 'Program';
import { type ProgramList as ProgramListType, type SelectProgramConfig } from '../selectProgram.types';
import { ProgramList } from './ProgramList';

type Props = {
    selectProgramConfig: SelectProgramConfig;
    preapprovals: ProgramType[];
};

export function SelectPreapprovedProgram({ selectProgramConfig, preapprovals }: Props) {
    const items = useMemo(() => {
        const degreeProgramConfig = selectProgramConfig.find(
            programList => programList.className === 'degree-programs',
        ) as ProgramListType;
        return degreeProgramConfig?.items().filter(item => preapprovals.includes(item.programType)) || [];
    }, [selectProgramConfig, preapprovals]);

    return (
        <div className="select-program tw-px-4 tw-py-5 sm:tw-px-[80px]">
            <div className="program-list-container">
                <ProgramList items={items} intro={undefined} preapproved />
            </div>
        </div>
    );
}
