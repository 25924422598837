import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    provider_transaction_id: Yup.string().required(),
    amount: Yup.number().positive().required(),
    refund_time: Yup.date().required(),
    provider: Yup.string().required(),
});

export default validationSchema;
