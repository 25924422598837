import { type LooksLikeAnApi } from './ReduxHelpers.types';

/*
    We need references to all the apis that are setup in our app in order to support error handling.
    In order to preserve encapsulation and prevent cascading imports, we want to preserve initializeAppStore
    as the only place in our app that imports all of the apis. This provider allows us to accomplish both of those
    goals.
*/
class ApiProvider {
    apis: { [key: string]: LooksLikeAnApi } = {};

    add(apis: LooksLikeAnApi[]) {
        apis.forEach(api => {
            this.apis[api.reducerPath] = api;
        });
    }

    get(apiName: string) {
        if (!apiName) throw new Error(`Api name not provided`);
        const api = this.apis[apiName];
        if (!api) throw new Error(`Api not found`);
        return this.apis[apiName];
    }

    reset() {
        this.apis = {};
    }
}

const apiProvider = new ApiProvider();

export { apiProvider };
