import angularModule from 'Settings/angularModule/scripts/settings_module';
import { setupBrandNameProperties } from 'AppBranding';
import template from 'Settings/angularModule/views/edit_preferences.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('editPreferences', [
    '$injector',

    function factory($injector) {
        const ngToast = $injector.get('ngToast');
        const $rootScope = $injector.get('$rootScope');
        const $window = $injector.get('$window');
        const TranslationHelper = $injector.get('TranslationHelper');
        const Locale = $injector.get('Locale');
        const safeApply = $injector.get('safeApply');
        const safeDigest = $injector.get('safeDigest');
        const offlineModeManager = $injector.get('offlineModeManager');
        const User = $injector.get('User');
        const LearnerContentCache = $injector.get('LearnerContentCache');
        const frontRoyalStore = $injector.get('frontRoyalStore');

        return {
            restrict: 'E',
            templateUrl,
            scope: {},
            link(scope) {
                //-------------------------
                // Initialization
                //-------------------------

                setupBrandNameProperties($injector, scope);

                // Setup localization keys
                const translationHelper = new TranslationHelper('settings.edit_preferences');

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                scope.frontRoyalStore = frontRoyalStore;

                scope.preferenceOptions = {
                    soundEnabled: {
                        type: 'checkbox-switch',
                    },
                    offlineMode: {
                        type: 'checkbox-switch',
                    },
                    keyboardShortcuts: {
                        type: 'checkbox-switch',
                    },
                    decimalDelim: {
                        type: 'select',
                        inputOptions: [
                            {
                                title: '1,234.56',
                                value: '.',
                            },
                            {
                                title: '1.234,56',
                                value: ',',
                            },
                        ],
                    },
                    locale: {
                        type: 'select',
                        inputOptions: Locale.availablePreferenceLocales(scope.currentUser),
                    },
                };

                offlineModeManager.isOfflineModeSupported().then(response => {
                    scope.preferenceOptions.offlineMode.hidden = !response;
                    // triggering a digest to solve a problem where the option
                    // stays visible for a few seconds after this code runs
                    // (when switching between tabs in Settings)
                    safeDigest(scope);
                });

                scope.$watch('preferences.soundEnabled', () => {
                    // show help text about mute button if on cordova and sound is enabled
                    if (scope.preferences.soundEnabled && $window.CORDOVA) {
                        scope.preferenceOptions.soundEnabled.desc_extra = true;
                    } else {
                        scope.preferenceOptions.soundEnabled.desc_extra = false;
                    }
                });

                scope.preferences = {
                    soundEnabled: scope.currentUser.pref_sound_enabled,
                    offlineMode: scope.currentUser.pref_offline_mode,
                    keyboardShortcuts: scope.currentUser.pref_keyboard_shortcuts,
                    decimalDelim: scope.currentUser.pref_decimal_delim,
                    locale: scope.currentUser.pref_locale,
                };

                //-------------------------
                // Navigation / Actions
                //-------------------------
                // eslint-disable-next-line no-shadow
                function displayToast(key, success) {
                    const translatedSectionTitle = translationHelper.get(`${key.underscore()}_title`);

                    if (success) {
                        ngToast.create({
                            content: translationHelper.get('update_preference_success', {
                                title: translatedSectionTitle,
                            }),
                            className: 'success',
                        });
                    } else {
                        ngToast.create({
                            content: translationHelper.get('update_preference_failure'),
                            className: 'danger',
                        });
                    }
                }

                function performSave(key, value) {
                    const column = `pref_${key.underscore()}`;

                    // Use a proxy object so we don't update the global currentUser until the save has succeeded
                    // and so we don't update the global Locale before we update the global currentUser. See
                    // https://trello.com/c/pYyc8kX4 for a race condition bug that existed before we did things
                    // this way.
                    const proxyUser = User.new(scope.currentUser.asJson());
                    proxyUser[column] = scope.preferences[key];

                    proxyUser.save().then(
                        () => {
                            scope.currentUser[column] = scope.preferences[key];
                            displayToast(key, true);
                            if (key === 'locale') {
                                Locale.attemptToSetLanguage(value, true, true);
                                LearnerContentCache.preloadStudentDashboard();
                            }

                            if (key === 'offlineMode' && value === false) {
                                offlineModeManager.disableOfflineMode();
                            }
                        },
                        () => {
                            $injector
                                .get('ErrorLogService')
                                .notify(`Failed to update User preference for ${key} = ${value}`);
                            displayToast(key, false);
                        },
                    );
                }

                scope.updatePreference = key => {
                    const value = scope.preferences[key];

                    if (key === 'soundEnabled') {
                        const SoundManager = $injector.get('SoundManager');
                        SoundManager.enabled = value;
                    }

                    if (key === 'locale') {
                        // This takes a few seconds, so we want to hide the form while it's happening
                        // so the user doesn't try to change something else in the form.
                        scope.changingLocale = true;
                    }

                    // handle user backed preferences
                    performSave(key, value);
                };

                // One day we might want to add a window.confirm
                // to this, but it's not really very dangerous right now.
                scope.clearLocalDatabase = async () => {
                    scope.clearingLocalDatabase = true;
                    await scope.frontRoyalStore.reinitializeDb();

                    scope.clearingLocalDatabase = false;
                    ngToast.create({
                        content: translationHelper.get('database_cleared'),
                        className: 'success',
                    });
                    safeApply(scope); // since reinitializeDb is outside of angular
                };

                // button-toggle

                scope.setPreference = (key, value, update) => {
                    scope.preferences[key] = value;
                    if (update) {
                        scope.updatePreference(key);
                    }
                };

                scope.isSelected = (key, value) => scope.preferences[key] === value;
            },
        };
    },
]);
