import angularModule from 'Settings/angularModule/scripts/settings_module';
import { setupBrandNameProperties } from 'AppBranding';
import template from 'Settings/angularModule/views/application_status.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { TuitionAndRegistrationSection, getTuitionAndRegistrationSection } from 'TuitionAndRegistrationSection';
import { AdmissionOfferStatus, declinedAdmissionOffer } from 'AdmissionOffer';
import { hasBeenExpelled } from 'ProgramInclusion';
import {
    getAoiRecord,
    getHasFailedProgram,
    getHasPendingAdmissionOfferOrIsNotYetCurrent,
    getIsCurrentOrHasCompletedActiveProgram,
    getReapplicationDate,
} from 'Users';
import { formattedUserFacingMonthDayLong } from 'DateHelpers';
import { AoiRecordType, isAdmissionOffer, isProgramInclusion } from 'ProgramAoi';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('applicationStatus', [
    '$injector',

    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const TranslationHelper = $injector.get('TranslationHelper');
        const Cohort = $injector.get('Cohort');
        const DialogModal = $injector.get('DialogModal');
        const $location = $injector.get('$location');

        return {
            restrict: 'E',
            templateUrl,
            scope: {},
            link(scope) {
                //-------------------------
                // Initialization
                //-------------------------

                NavigationHelperMixin.onLink(scope);
                setupBrandNameProperties($injector, scope, {
                    branding: () => scope.currentUser?.relevantCohort?.branding,
                });
                const translationHelper = new TranslationHelper('settings.application_status');

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                // A user who just successfully completed a Stripe Checkout session
                // will get redirected to /application_status?completed_stripe_checkout_session=true,
                // likely before we've received the resultant webhooks from Stripe and setup a
                // TuitionContract and ProgramInclusion.
                // In this case, we want to store a temporary flag in ClientStorage so we
                // can show them the correct UI in getTuitionAndRegistrationSection.
                // To see how this gets unset, see the TuitionAndRegistrationSection angularModule
                if ($location.search()?.completed_stripe_checkout_session === 'true') {
                    scope.currentUser.completedStripeCheckoutSession = true;
                    $location.search('completed_stripe_checkout_session', null);
                }
                if ($location.search()?.register_for_previous_cohort === 'true') {
                    scope.currentUser.registeredForPreviousCohort = true;
                    $location.search('register_for_previous_cohort', null);
                }

                Object.defineProperty(scope, 'programApplicationForActiveProgram', {
                    get() {
                        return getAoiRecord(scope.currentUser, AoiRecordType.ProgramApplication);
                    },
                });

                Object.defineProperty(scope, 'lastActiveAoiRecord', {
                    get() {
                        return getAoiRecord(scope.currentUser);
                    },
                });

                Object.defineProperty(scope, 'tuitionAndRegistrationSection', {
                    get() {
                        return getTuitionAndRegistrationSection(scope.currentUser);
                    },
                });

                Object.defineProperty(scope, 'showingTuitionAndRegistrationSectionBody', {
                    get: () => {
                        const val = scope.tuitionAndRegistrationSection;

                        // tuitionAndRegistrationSection is null when the user is not
                        // in a situation that requires registration (due to the cohort or
                        // application status.)
                        // If tuitionAndRegistrationSection is 'empty' or 'specialCase', it
                        // means that we're not actually showing anything in the body of the page,
                        // but the user is in a state that requires registration, and the header
                        // and subheader are still talking about registration.
                        return (
                            val &&
                            ![TuitionAndRegistrationSection.empty, TuitionAndRegistrationSection.specialCase].includes(
                                val,
                            )
                        );
                    },
                });

                // Being defensive here: it's possible for this directive to get rendered briefly if someone navigates directly to it;
                // if no previous AOI record found, simply return to avoid possibility of RTEs
                if (!scope.lastActiveAoiRecord) {
                    return;
                }

                scope.launchProgramGuide = () => {
                    scope.loadUrl(scope.lastActiveAoiRecord.cohortProgramGuideUrl, '_blank');
                };

                function onApplicationsAndCohortsLoaded() {
                    if (!scope.currentUser) {
                        return;
                    }

                    // Set reapply state
                    scope.canReapply = false;
                    const relevantCohort = scope.currentUser.relevantCohort;

                    if (scope.lastActiveAoiRecord) {
                        scope.canReapply = scope.currentUser.canCurrentlySubmitProgramApplication;
                    }
                    scope.cohortToReapplyTo = relevantCohort;

                    // Set translation keys
                    scope.pendingPrimaryKey = `pending_primary_message_${
                        scope.currentUser.relevantCohort && scope.currentUser.relevantCohort.supportsAdmissionRounds
                            ? 'rounds'
                            : 'rolling'
                    }`;
                    scope.pendingSecondaryKey =
                        scope.currentUser.relevantCohort.applicationStatusPendingSecondaryMessageKey;

                    if (isProgramInclusion(scope.lastActiveAoiRecord) && hasBeenExpelled(scope.lastActiveAoiRecord)) {
                        scope.rejectedPrimaryKey = 'expelled_primary_message';
                    } else if (
                        isAdmissionOffer(scope.lastActiveAoiRecord) &&
                        declinedAdmissionOffer(scope.lastActiveAoiRecord) &&
                        scope.currentUser.relevantCohort.isDegreeProgram
                    ) {
                        scope.rejectedPrimaryKey = scope.canReapply
                            ? scope.currentUser.relevantCohort
                                  .applicationStatusRejectedAfterPreAcceptedPrimaryMessageKey
                            : scope.currentUser.relevantCohort
                                  .applicationStatusRejectedAfterPreAcceptedPrimaryMessageKeyCantReapply;
                    } else if (
                        scope.currentUser.relevantCohort.applicationStatusRejectedPrimaryMessageKeyEnableQuantic
                    ) {
                        scope.rejectedPrimaryKey =
                            scope.currentUser.relevantCohort.applicationStatusRejectedPrimaryMessageKeyEnableQuantic;
                    } else if (
                        !scope.canReapply &&
                        Cohort.supportsAdmissionRounds(scope.currentUser.relevantCohort.program_type)
                    ) {
                        scope.rejectedPrimaryKey =
                            scope.currentUser.relevantCohort.applicationStatusRejectedPrimaryMessageKeyCantReapply;
                    } else {
                        scope.rejectedPrimaryKey =
                            scope.currentUser.relevantCohort.applicationStatusRejectedPrimaryMessageKey;
                    }

                    if (getHasPendingAdmissionOfferOrIsNotYetCurrent(scope.currentUser)) {
                        scope.preAcceptedPrimaryKey = 'accepted_primary_message_default';
                    } else if (getIsCurrentOrHasCompletedActiveProgram(scope.currentUser)) {
                        scope.acceptedPrimaryKey = getHasFailedProgram(scope.currentUser)
                            ? 'failed_unfortunately'
                            : scope.currentUser.relevantCohort.applicationStatusAcceptedPrimaryMessageKey;

                        if (scope.showTuitionAndRegistration) {
                            scope.acceptedPrimaryKey = null;
                        }
                    }

                    if (scope.cohortToReapplyTo) {
                        _setReapplyMessaging(scope.cohortToReapplyTo);
                    }
                }

                function _setReapplyMessaging(cohort) {
                    const appliedAt = scope.programApplicationForActiveProgram?.appliedAt;
                    const pendingDeadlineMessage = cohort.getApplicationDeadlineMessage(appliedAt);
                    const lastAppCohortIsPromoted =
                        scope.currentUser.relevantCohort.id === scope.programApplicationForActiveProgram?.cohortId;

                    const rejectedDeadlineMessage = lastAppCohortIsPromoted
                        ? cohort.getReapplyAfterDeadlineMessage()
                        : cohort.getApplicationDeadlineMessage();

                    // get the localized foundation playlist for suggestions
                    if (cohort.foundations_playlist_locale_pack) {
                        scope.foundationsPlaylistLocaleString = cohort.localizedFoundationsPlaylist.title;
                    }

                    if (cohort.admissionRoundDeadlineHasPassed(appliedAt)) {
                        scope.pendingPrimaryKey = 'deadline_passed_with_pending_application';
                    }

                    scope.pendingApplicationPrimaryMessage = translationHelper.get(
                        scope.pendingPrimaryKey,
                        {
                            brandName: scope.brandNameShort,
                            applicationDeadlineWithDecision: pendingDeadlineMessage,
                        },
                        null,
                    );

                    scope.reapplyMessage = translationHelper.get(
                        scope.rejectedPrimaryKey,
                        {
                            reapplyAfterDeadline: rejectedDeadlineMessage,
                            programTitle: cohort.shortProgramTitle,
                            reapplicationDate: formattedUserFacingMonthDayLong(
                                getReapplicationDate(scope.currentUser),
                                false,
                            ),
                        },
                        null,
                    );
                }

                //-------------------------
                // Pending
                //-------------------------

                scope.reapplyOrEditApplication = () => {
                    $injector.get('$location').path('/settings/application').search({
                        page: '1',
                    });
                };

                // this can change, so watch it...
                scope.$watchGroup(
                    [
                        'currentUser.needsToRegister',
                        'currentUser.relevantCohort.program_type',
                        'lastActiveAoiRecord.id',
                        'lastActiveAoiRecord.status',
                        'lastActiveAoiRecord.programType',
                    ],
                    () => {
                        onApplicationsAndCohortsLoaded();
                    },
                );

                onApplicationsAndCohortsLoaded();

                //-------------------------
                // Pre-Accepted - EMBA modal
                //-------------------------
                const stopWatchingStatus = scope.$watch(
                    () => getAoiRecord(scope.currentUser)?.status,
                    () => {
                        // Determine if the application accepted message should be displayed
                        if (
                            getAoiRecord(scope.currentUser)?.status === AdmissionOfferStatus.OfferedAdmission &&
                            !scope.currentUser.hasSeenAccepted &&
                            scope.currentUser.relevantCohort.supportsAcceptanceMessageOnApplicationPage
                        ) {
                            DialogModal.alert({
                                content: '<application-accepted></application-accepted>',
                                size: 'fullscreen',
                                hideCloseButton: true,
                                closeOnClick: false,
                                classes: ['accepted-application-modal'],
                            });

                            if (!scope.currentUser.ghostMode) {
                                scope.currentUser.hasSeenAccepted = true;
                                scope.currentUser.save();
                            }

                            stopWatchingStatus();
                        }
                    },
                );

                scope.$on('$destroy', () => {
                    DialogModal.hideAlerts();
                });
            },
        };
    },
]);
