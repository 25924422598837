
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.settings = window.Smartly.locales.modules.en.back_royal.settings || {};
window.Smartly.locales.modules.en.back_royal.settings.paid_program_welcome_package = window.Smartly.locales.modules.en.back_royal.settings.paid_program_welcome_package || {};
window.Smartly.locales.modules.en.back_royal.settings.paid_program_welcome_package = {...window.Smartly.locales.modules.en.back_royal.settings.paid_program_welcome_package, ...{
    "headline_few_of_your_registered_classmates": "Here are a few of your registered classmates.",
    "headline_video": "Our students explain the {{brandName}} difference",
    "download": "Download",
    "program_guide": "Program Guide",
    "headline_meet_additional_students": "Meet additional students and get all the details",
    "headline_these_students_registered": "These students have recently registered in the {{programTitle}}",
    "headline_these_students_registered_temp": "These students have recently registered for the {{program}}",
    "headline_these_students_registered_generic": "These students have recently registered"
}
}
    