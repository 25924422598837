import { type PaymentSituation } from 'PaymentSituation';

export enum PaymentConfigState {
    us = 'us',
    us_no_cc_payment_fee = 'us_no_cc_payment_fee',
    non_us_no_cc_payment_fee = 'non_us_no_cc_payment_fee',
}

export enum PaymentTypeOption {
    wire_transfer = 'wire_transfer',
    us_bank_account = 'us_bank_account',
    card = 'card',
    buy_now_pay_later = 'buy_now_pay_later',
}

export enum PaymentView {
    select_plan = 'select_plan',
    select_payment = 'select_payment',
}

export enum RegisterButtonState {
    processing = 'processing',
    requires_redirect_to_browser = 'requires_redirect_to_browser',
    continue_to_register = 'continue_to_register',
    review_and_register = 'review_and_register',
    register = 'register',
    wire_transfer_instructions = 'wire_transfer_instructions',
}

export type PaymentConfig = {
    paymentConfigState: PaymentConfigState;
    heading: LocaleString;
    subheadingHtml: LocaleString;
    paymentOptionButtons: PaymentTypeOptionButton[];
    paymentSituation: PaymentSituation;
    availablePaymentTypeOptions: () => PaymentTypeOption[];
};

export type PaymentTypeOptionButton = {
    paymentOption: PaymentTypeOption;
    shouldShow: () => boolean;
    icons: PaymentOptionIcon[];
    title: LocaleString;
    subtext?: LocaleString;
};

export type PaymentOptionIcon = {
    shouldShow: () => boolean;
} & (
    | {
          type: 'fontawesome';
          className: string;
      }
    | {
          type: 'image';
          path: string;
      }
);

export type RegisterButton = {
    registerButtonState: RegisterButtonState;
    text: LocaleString;
    buttonClassname?: string;
    iconClassname?: string;
};

export type LocaleString = {
    key: string;
    values?: Record<string, unknown>;
};
