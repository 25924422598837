import i18n from 'i18next';
import ICU from 'i18next-icu';
import { initReactI18next } from 'react-i18next';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import resourceToBackend from 'i18next-resources-to-backend';
import { DefaultLocale } from 'Translation';
import getFilePathsFromManifest from 'WebpackManifestHelper';

export function initializeReactI18N() {
    i18n
        // enable ICU format for interpolation
        .use(ICU)
        // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
        // learn more: https://github.com/i18next/i18next-http-backend
        .use(ChainedBackend)
        // pass the i18n instance to react-i18next.
        .use(initReactI18next)
        // for all init options read: https://www.i18next.com/overview/configuration-options
        .init({
            fallbackLng: 'en',
            defaultNS: 'back_royal',
            ns: 'back_royal',
            interpolation: {
                escapeValue: false, // not needed for react as it escapes by default
            },
            backend: {
                backends: [HttpBackend, resourceToBackend(window.Smartly.locales.modules)],
                backendOptions: [
                    {
                        loadPath(lngs) {
                            return getFilePathsFromManifest(`locales-${lngs[0]}`, 'json')[0];
                        },
                    },
                ],
            },
        });

    // We can provide translations for the DefaultLocale because they're
    // bundled with the JS and on the window object. If we don't do this,
    // HttpBackend will attempt to load them asynchronously, but the /locales
    // file won't exist.
    i18n.addResourceBundle(DefaultLocale, 'back_royal', window.Smartly.locales.modules[DefaultLocale].back_royal);
}

export function initializeStubbedReactI18N() {
    i18n
        // enable ICU format for interpolation
        .use(ICU)
        // pass the i18n instance to react-i18next.
        .use(initReactI18next)
        // for all init options read: https://www.i18next.com/overview/configuration-options
        .init({
            fallbackLng: 'en',
            defaultNS: 'back_royal',
            ns: 'back_royal',
            react: {
                useSuspense: false,
            },
            interpolation: {
                escapeValue: false, // not needed for react as it escapes by default
            },
        });
}

export default i18n;
