import { type Cohort } from 'Cohorts';
import { type ProgramField, type ProgramType } from 'Program';
import { type ProgramApplication, type ProgramApplicationStatus } from 'ProgramApplication';
import { type AdmissionOffer, type AdmissionOfferStatus } from 'AdmissionOffer';
import { type ProgramInclusion, type ProgramInclusionStatus } from 'ProgramInclusion';

export enum AoiRecordType {
    ProgramApplication = 'ProgramApplication',
    AdmissionOffer = 'AdmissionOffer',
    ProgramInclusion = 'ProgramInclusion',
}

export type AoiStatus = ProgramInclusionStatus | AdmissionOfferStatus | ProgramApplicationStatus;

export type SuccessStatus =
    | AdmissionOfferStatus.AcceptedAdmissionOffer
    | ProgramApplicationStatus.SelectedForAdmission
    | ProgramInclusionStatus.Graduated;

export type FailureStatus =
    | AdmissionOfferStatus.DeclinedAdmissionOffer
    | ProgramApplicationStatus.NotSelectedForAdmission
    | ProgramInclusionStatus.WillNotGraduate;

export type PendingStatus =
    | AdmissionOfferStatus.OfferedAdmission
    | ProgramApplicationStatus.SubmittedApplication
    | ProgramInclusionStatus.Included;

export interface UserProgramFieldState {
    id: string;
    programField: ProgramField;
    userProgramStateId: string;
    relevant: boolean;
    reapplicationDate: number;
}

export interface UserProgramState {
    id: string;
    programType: ProgramType;
    programApplicationId: string | null;
    admissionOfferId: string | null;
    programInclusionId: string | null;
    cohort: Cohort;
}

export type AoiRecord<adminOnlyFields = false> =
    | ProgramApplication<adminOnlyFields>
    | AdmissionOffer
    | ProgramInclusion;
