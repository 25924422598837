/* eslint-disable max-classes-per-file */
import { type configureStore } from '@reduxjs/toolkit';

export class NoStoreError extends Error {
    constructor(message = '') {
        super(`store is not defined ${message}`);
    }
}

class StoreProvider {
    store: ReturnType<typeof configureStore> | null = null;

    dispatch = <T>(action: T extends Parameters<ReturnType<typeof configureStore>['dispatch']>[0] ? T : never) => {
        if (!this.store) {
            throw new NoStoreError();
        }

        return this.store.dispatch(action) as T;
    };
}

export const storeProvider = new StoreProvider();

// In lesson preview mode, the storeProvider is attached to the iframe.contentWindow
export const getState = () => (window.storeProvider ?? storeProvider).store!.getState();
