
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.settings = window.Smartly.locales.modules.en.back_royal.settings || {};
window.Smartly.locales.modules.en.back_royal.settings.edit_documents = window.Smartly.locales.modules.en.back_royal.settings.edit_documents || {};
window.Smartly.locales.modules.en.back_royal.settings.edit_documents = {...window.Smartly.locales.modules.en.back_royal.settings.edit_documents, ...{
    "documents": "Documents",
    "desktop_to_upload": "Please sign in on a desktop device to upload your documents.",
    "upload": "Upload",
    "identification": "Identification",
    "identification_verified": "Identification Verified",
    "identification_desc_when_can_upload": "Please upload a clear image of your driver's license or passport so we can verify your identity.",
    "identification_desc_when_cannot_upload": "Upon acceptance to the program, please upload a clear image of your driver's license or passport so we can verify your identity.",
    "transcripts": "Transcripts",
    "transcripts_section_admin_message": "Student did not complete a formal degree program and does not meet eligibility requirements.",
    "english_language_proficiency_documentation": "English Language Proficiency Documentation",
    "english_language_proficiency_documents_desc": "Students whose native language is not English and who have not earned a degree from an accredited institution where English is the primary language of instruction are required to demonstrate proficiency in English by providing appropriate documentation. Please <a ng-click=\"loadFaqUrl('/help/english-language-requirements', '_blank')\">click here</a> to review the complete list of acceptable assessments and documentation.",
    "english_language_proficiency_section_admin_message_for_native_english_speaker": "Learner is a native English speaker and is therefore not required to upload English language proficiency documents and does not require document approval.",
    "english_language_proficiency_section_admin_message_for_accredited_degree_holder": "Learner has earned an accredited degree from an institution where English was the principal language of instruction and is therefore not required to upload English language proficiency documents and does not require document approval.",
    "english_language_proficiency_section_admin_message_for_exempt_user": "Learner has worked in a predominantly English speaking company for at least five years and is therefore not required to upload English language proficiency documents and does not require document approval.",
    "minimum_required_scores": "Minimum required scores and additional information",
    "select_documentation_type": "Select documentation type",
    "duolingo": "Duolingo English Test",
    "toefl_pbt": "Test of English as a Foreign Language (TOEFL PBT)",
    "ibt": "Internet Based Test (iBT)",
    "ielts": "International English Language Test (IELTS)",
    "pte": "Pearson Test of English (PTE) Academic Score Report",
    "act_compass": "ACT COMPASS",
    "accuplacer_esl": "College Board Accuplacer ESL Exam Series",
    "eiken": "Eiken English Proficiency Exam",
    "cefr": "CEFR-aligned exam (e.g.: LCCI, Cambridge)",
    "other": "Other",
    "comments_optional": "Comments (optional)",
    "uploaded_date": "Uploaded {{uploadDate}}",
    "mailing_address": "Mailing Address",
    "mailing_address_description": "Strictly confidential. For identity verification and diploma delivery.",
    "address": "<i class=\"fa fa-search\"></i> Search For Your Address",
    "address_help_text": "Please select the closest address possible. You can edit the results after searching.",
    "address_placeholder": "Enter your address",
    "address_line_1": "Address line 1",
    "address_line_1_placeholder": "Street address, P.O. box, company name, c/o",
    "address_line_2": "Address line 2",
    "address_line_2_placeholder": "Apartment, suite, unit, building, floor, etc.",
    "city": "City",
    "state": "State / Province / Region",
    "zip": "Zip / Postal Code",
    "country": "Country",
    "phone": "Phone",
    "save_documents": "Save Documents",
    "save_mailing_address": "Save Mailing Address",
    "documents_saved": "Documents Saved",
    "mailing_address_saved": "Mailing Address Saved",
    "approve": "Approve?",
    "no_sections_message": "Upon acceptance to the program, you may be asked to provide additional documents to the registrar.",
    "unofficial_transcripts_description_part_one": "Get a head start! Upload an unofficial transcript for each degree you've earned. <a ng-click=\"loadFaqUrl('/help/student-transcript-policy', '_blank')\">Click here</a> to learn more about transcript requirements.",
    "unofficial_transcripts_description_part_two": "You may upload multiple documents per degree below. Once uploaded, documents may not be deleted. If you have uploaded a document in error, please contact <a href=\"mailto:registrar@quantic.edu\" target=\"_blank\">registrar@quantic.edu</a> for assistance.",
    "unofficial_transcripts_final_note": "Upon acceptance into the program, you will need to request an official transcript for your highest degree earned. <a ng-click=\"loadFaqUrl('/help/transcript-faqs#what', '_blank')\">Learn more</a> about official transcripts.",
    "unofficial_transcripts_upload": "Upload an unofficial transcript.",
    "unofficial_transcripts_upload_thank_you": "Thank you for uploading an unofficial transcript!",
    "unofficial_transcripts_please_upload": "Please upload your unofficial transcripts below by clicking on the Upload icon. For more information regarding acceptable formats and document requirements, please <a ng-click=\"loadFaqUrl('/help/am-i-required-to-provide-official-documents-transcript-diploma-etc#unofficial', '_blank')\">click here.</a>",
    "official_transcripts_also_required": "Official transcripts sent directly from your prior institution are also required. Check below to see which degrees require official transcripts.",
    "official_transcripts_approved": "We've approved your official transcripts.",
    "official_transcripts_waived": "We've waived your official transcripts.",
    "official_transcripts_required": "Official transcript required. Learn more about submitting official transcripts <a ng-click=\"loadFaqUrl('/help/transcript-faqs', '_blank')\">here</a>."
}
}
    