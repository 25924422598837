import angularModule from 'Settings/angularModule/scripts/settings_module';
import { setupBrandNameProperties } from 'AppBranding';
import template from 'Settings/angularModule/views/tuition_and_registration/paid_program_welcome_package.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

import arrowDownFadedWhite from 'vectors/arrow_down_faded_white.svg';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('paidProgramWelcomePackage', [
    '$injector',

    function factory($injector) {
        const $sce = $injector.get('$sce');
        const $rootScope = $injector.get('$rootScope');
        const CareerProfile = $injector.get('CareerProfile');
        const WorkExperience = $injector.get('WorkExperience');
        const EducationExperience = $injector.get('EducationExperience');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                launchProgramGuide: '&',
            },
            link(scope) {
                scope.arrowDownFadedWhite = arrowDownFadedWhite;

                //-------------------------
                // Initialization
                //-------------------------

                NavigationHelperMixin.onLink(scope);

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                Object.defineProperty(scope, 'relevantCohort', {
                    get() {
                        return scope.currentUser.relevantCohort;
                    },
                });

                setupBrandNameProperties($injector, scope, {
                    branding: () => scope.relevantCohort?.branding,
                });

                Object.defineProperty(scope, 'welcomeVideoSrc', {
                    get() {
                        return $sce.trustAsResourceUrl(scope.relevantCohort.welcomePackageConfiguration.videoSrc);
                    },
                });

                // hard-coded data for now; fetch from server later
                function mockStudent(props) {
                    const { name, locationString, jobTitle, company, university, degree, avatarUrl } = props;
                    const profile = CareerProfile.new({
                        name,
                        work_experiences: [
                            WorkExperience.new({
                                professional_organization: {
                                    text: company,
                                },
                                job_title: jobTitle,
                                featured: true,
                            }),
                        ],
                        education_experiences: [
                            EducationExperience.new({
                                educational_organization: {
                                    text: university,
                                },
                                degree,
                                degree_program: true,
                                will_not_complete: false,
                            }),
                        ],
                        avatar_url: avatarUrl,
                    });
                    Object.defineProperty(profile, 'locationString', {
                        value: locationString,
                    });
                    return profile;
                }

                scope.students = scope.relevantCohort.welcomePackageConfiguration.students.map(mockStudent);

                //-------------------------
                // Locale Keys
                //-------------------------

                if (scope.relevantCohort.name === 'EMBA14') {
                    scope.headlineKey =
                        'settings.paid_program_welcome_package.headline_few_of_your_registered_classmates';
                } else {
                    scope.headlineKey = `settings.paid_program_welcome_package.${scope.relevantCohort.welcomePackageConfiguration.headlineKey}`;
                }
            },
        };
    },
]);
