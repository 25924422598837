
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.playlists = window.Smartly.locales.modules.en.back_royal.playlists || {};
window.Smartly.locales.modules.en.back_royal.playlists.playlist_link_box = window.Smartly.locales.modules.en.back_royal.playlists.playlist_link_box || {};
window.Smartly.locales.modules.en.back_royal.playlists.playlist_link_box = {...window.Smartly.locales.modules.en.back_royal.playlists.playlist_link_box, ...{
    "active": "ACTIVE",
    "completed": "COMPLETED",
    "courses": "COURSES",
    "create_your_own": "CREATE YOUR OWN",
    "edit": "EDIT",
    "edit_content": "Custom playlists are not yet available.",
    "edit_title": "Feature not yet available",
    "estimated_time": "EST TIME",
    "hours": "hours",
    "use_playlist_editor": "Use our playlist editor to create your own sequence of courses.",
    "view": "VIEW",
    "x_courses": "{{num}} COURSES"
}
}
    