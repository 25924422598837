import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { type ProgramList as ProgramListType, type SelectProgramConfig } from '../selectProgram.types';
import { ProgramList } from './ProgramList';
import { ProgramListWithSubSections } from './ProgramListWithSubSections';

type Props = {
    selectProgramConfig: SelectProgramConfig;
};

export function SelectProgram({ selectProgramConfig }: Props) {
    const { t } = useTranslation('back_royal');
    const [selectedProgramListIndex, setSelectedProgramListIndex] = useState<number>(0);

    const selectedProgramList = useMemo(
        () => selectProgramConfig[selectedProgramListIndex],
        [selectProgramConfig, selectedProgramListIndex],
    );

    const hasSubSections = 'subSections' in selectedProgramList && !!selectedProgramList.subSections().length;

    return (
        <div className="select-program tw-px-4 tw-py-5 sm:tw-px-[80px]">
            <div className="list-select-buttons tw-mb-6 tw-flex tw-justify-center tw-gap-3 sm:tw-gap-7">
                {selectProgramConfig.map((programList, index) => (
                    <button
                        type="button"
                        key={programList.title.key}
                        onClick={() => setSelectedProgramListIndex(index)}
                        className={clsx(
                            selectedProgramListIndex === index
                                ? 'selected tw-bg-coral tw-text-white'
                                : 'tw-bg-transparent tw-text-coral',
                            'list-select-button tw-rounded tw-border-none tw-px-[14px] tw-py-[12px] tw-text-sm',
                        )}
                    >
                        {t(programList.title.key)}
                    </button>
                ))}
            </div>
            <div className="program-list-container">
                {hasSubSections ? (
                    <ProgramListWithSubSections programList={selectedProgramList} />
                ) : (
                    <ProgramList
                        items={(selectedProgramList as ProgramListType).items()}
                        intro={selectedProgramList.intro}
                    />
                )}
            </div>
        </div>
    );
}
