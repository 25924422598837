import 'ScrollHelper/angularModule';
import 'Capabilities/angularModule';

// This is only FrontRoyal-specific because it assumes each route has a directive property
export default angular
    .module('FrontRoyal.RouteAnimationHelper', ['Capabilities', 'scrollHelper'])
    .factory('RouteAnimationHelper', [
        '$injector',

        $injector => {
            const SuperModel = $injector.get('SuperModel');
            const Singleton = $injector.get('Singleton');
            const $rootScope = $injector.get('$rootScope');
            const $document = $injector.get('$document');
            const $timeout = $injector.get('$timeout');
            const $location = $injector.get('$location');
            const Capabilities = $injector.get('Capabilities');
            const $animate = $injector.get('$animate');
            const scrollHelper = $injector.get('scrollHelper');

            const Helper = SuperModel.subclass(function () {
                this.include(Singleton);
                this.defineSingletonProperty('cssClasses', 'resolveRouteChange', 'animatePathChange');

                this.extend({
                    init() {
                        return this.instance;
                    },
                });

                return {
                    initialize() {
                        this.cssClasses = []; // classes that should currently be applied to ng-view
                        this._animatedRouteChangeInProgress = false;

                        $rootScope.$on('$routeChangeStart', this._onRouteChangeStart.bind(this));
                        $rootScope.$on('$routeChangeSuccess', this._onRouteChangeSuccess.bind(this));

                        // viewContentLoaded is fired after the new ng-view element has been
                        // created, but before any content has been rendered inside of it.  At this
                        // point, we can start listening for it to finish animating onto the screen.
                        $rootScope.$on('$viewContentLoaded', () => {
                            const finishedEvent = 'RouteAnimationHelper.animationFinished';

                            function broadcast(element, phase) {
                                if (phase === 'close') {
                                    $rootScope.$broadcast(finishedEvent);
                                }
                            }

                            const incomingView = $('[ng-view]:not(.route-leave):eq(0)');
                            // If we're animating the new view onto the screen, wait for
                            // the animation to end and then fire an event.  If we're not
                            // animating it, then just fire the event (after a timeout so
                            // that the view will be rendered first.)
                            if (this.cssClasses.includes('animated')) {
                                $animate.on('enter', incomingView, broadcast);
                            } else {
                                $timeout(() => {
                                    $rootScope.$broadcast(finishedEvent);
                                });
                            }
                        });
                    },

                    /*
                        Animate the current view off of the screen and then
                        do a location change.  The goal here is to respond
                        immediately to clicks without having to do a scope.$digest.
                        Helpful for a super-snappy mobile experience.

                        1. Add cssClass and 'route-leave' to the current view.
                            css should be defined for [ng-view].route-leave.{{cssClass}}
                            that animates the view off ofthe screen. (css should be defined
                            it route_animations.scss)
                        2. Wait duration ms (defaults to 500ms) and then call
                            $location.url. duration should be set to the amount
                            of time the route-leave animation will take, so that
                            once the animation is finished the location change can
                            happen
                        3. If you want to animate the next view onto the screen, define
                            css for [ng-view].ng-enter.{{cssClass}}

                    */
                    animatePathChange(newPath, cssClass, duration = 500, hash) {
                        if (!Capabilities.cssTransitions) {
                            duration = 0;
                        }
                        this._cleanup();
                        this._animatedRouteChangeInProgress = true;

                        // if moving quickly between pages, the old ng-view might
                        // still be around.  Kill it now so it doesn't cause trouble
                        $('[ng-view].ng-leave').remove();
                        const view = $($document[0].getElementById('ng-view'));

                        view.addClass(cssClass).addClass('route-leave');
                        this.cssClasses = ['animated', cssClass];

                        // wait for the animation to finish, then scroll to the
                        // top of the screen and resolve the returned promise
                        $timeout(
                            () => {
                                scrollHelper.scrollToTop();
                                $location.url(newPath);

                                if (hash) {
                                    $location.hash(hash);
                                }
                            },
                            duration,
                            true,
                        );
                    },

                    _cleanup() {
                        // cleanup classes added by previous animation
                        const view = $($document[0].getElementById('ng-view'));

                        this.cssClasses.forEach(cssClass => {
                            view.removeClass(cssClass);
                        });
                        this.cssClasses = [];
                    },

                    _onRouteChangeStart() {
                        if (!this._animatedRouteChangeInProgress) {
                            this._cleanup();
                        }
                    },

                    _onRouteChangeSuccess() {
                        this._animatedRouteChangeInProgress = false;

                        // if still listening for view to animate onto screen, stop
                        const view = $($document[0].getElementById('ng-view'));
                        $animate.off('enter', view);
                    },
                };
            });

            return Helper;
        },
    ]);
