import { getBrandName } from 'AppBranding';
import 'Translation/angularModule';
import { generateCanonicalUrl } from 'PedagoDomainConstants';

export default angular.module('SiteMetadata', ['Translation']).factory('SiteMetadata', [
    '$injector',

    $injector => {
        const $rootScope = $injector.get('$rootScope');
        const SuperModel = $injector.get('SuperModel');
        const Singleton = $injector.get('Singleton');
        const $window = $injector.get('$window');
        const ConfigFactory = $injector.get('ConfigFactory');
        const $translate = $injector.get('$translate');
        const TranslationHelper = $injector.get('TranslationHelper');

        // Setup localization keys
        const translationHelper = new TranslationHelper('search_engine_optimization.site_metadata_mixin');

        return SuperModel.subclass(function factory() {
            this.include(Singleton);
            this.defineSingletonProperty(
                'defaultShareInfo',
                'seoCanonicalUrlFromTitle',
                'smartlyShareInfo',
                'smartlyDemoShareInfo',
                'blueOceanShareInfo',
                'contentCompletedShareInfo',
                'contentDefaultShareInfo',
                'updateHeaderMetadata',
                'parameterizedTitle',
            );

            Object.defineProperty(this.prototype, 'defaultShareInfo', {
                get: () => {
                    if (!this._defaultShareInfo) {
                        const config = ConfigFactory.getSync();
                        const brandName = getBrandName($rootScope.currentUser, config);

                        // FIXME: https://trello.com/c/UYIzl3cj
                        let tweetTemplateKey = config.isQuantic()
                            ? 'default_share_tweet_template_quantic'
                            : 'default_share_tweet_template';
                        if ($rootScope.currentUser?.isMiyaMiya) {
                            tweetTemplateKey = 'default_share_tweet_template_miya_miya';
                        }
                        const twitterName = $rootScope.currentUser?.isMiyaMiya
                            ? '@MiyaMiyaSchool'
                            : `@${config.social_name}`;
                        const courseEmailDescriptionKey = $rootScope.currentUser?.isMiyaMiya
                            ? 'default_share_course_email_description_miya_miya'
                            : 'default_share_course_email_description';

                        this._defaultShareInfo = {
                            canonicalUrl: `https://${generateCanonicalUrl(config, '/')}`,
                            title: translationHelper.get('default_share_title', { brandName }),
                            tweetTemplate: translationHelper.get(tweetTemplateKey, {
                                twitterName,
                            }),
                            description: translationHelper.get('default_share_description'),
                            emailDescription: signature =>
                                translationHelper.get('default_share_email_description', {
                                    brandName,
                                    canonicalUrl: this.defaultShareInfo.canonicalUrl,
                                    signature,
                                }),
                            courseEmailDescription: (courseTitle, courseDescription, canonicalUrl, signature) =>
                                translationHelper.get(courseEmailDescriptionKey, {
                                    brandName,
                                    courseTitle,
                                    courseDescription,
                                    canonicalUrl,
                                    signature,
                                }),
                        };
                    }
                    return this._defaultShareInfo;
                },
            });

            return {
                pageMetadataDefaults() {
                    return ConfigFactory.getSync().defaultPageMetadata();
                },

                parameterizedTitle(title) {
                    if (title) {
                        return title
                            .trim()
                            .replace(/[^a-zA-Z0-9-\s]/g, '')
                            .replace(/[^a-zA-Z0-9-]/g, '-')
                            .toLowerCase();
                    }
                    return undefined;
                },

                seoCanonicalUrlFromTitle(contentType, title, id) {
                    // If we are computing a URL for a title that contains strictly non-Latin characters (ex. Chinese) then we should not
                    // use the title in the URL as it results in '//' (see parameterizedTitle above). Ensure that for any content types
                    // that we use the title in the URL that we do the below check and also ensure that we support the non-title route in navigation_module.
                    // See https://trello.com/c/KYQLUGR0
                    if (
                        _.isString(this.parameterizedTitle(title)) &&
                        this.parameterizedTitle(title).replace(/-+/, '').length === 0
                    ) {
                        // We turn spaces into '-'
                        return `/${contentType}/${id}`;
                    }

                    return `/${contentType}/${this.parameterizedTitle(title)}/${id}`;
                },

                smartlyShareInfo(user) {
                    const signature = user && user.name ? user.name : '';
                    const tweetTemplateText = this.defaultShareInfo.tweetTemplate;

                    // FIXME: https://trello.com/c/UYIzl3cj
                    const shareEmailTitleKey = $rootScope.currentUser?.isMiyaMiya
                        ? 'default_share_email_title_miya_miya'
                        : 'default_share_email_title';

                    return {
                        url: this.defaultShareInfo.canonicalUrl,
                        title: this.defaultShareInfo.title,
                        description: this.defaultShareInfo.description,
                        campaignMedium: 'social',
                        campaignDescription: undefined,

                        twitter: {
                            description: tweetTemplateText,
                        },

                        email: {
                            title: translationHelper.get(shareEmailTitleKey),
                            description: this.defaultShareInfo.emailDescription(signature),
                        },
                    };
                },

                smartlyDemoShareInfo() {
                    const smartlyShareInfo = this.smartlyShareInfo();
                    const config = ConfigFactory.getSync();
                    // custom twitter share link
                    smartlyShareInfo.twitter = {
                        description: translationHelper.get('smartly_demo_twitter', {
                            twitterName: `@${ConfigFactory.getSync().social_name}`,
                        }),
                        url: config.isQuantic() ? 'https://bit.ly/2qBqejE' : 'http://bit.ly/1NbiPIB',
                        campaignMedium: undefined,
                        campaignDescription: undefined,
                        campaignContent: undefined,
                    };

                    return smartlyShareInfo;
                },

                blueOceanShareInfo(user) {
                    const config = ConfigFactory.getSync();

                    // Email information
                    const signature = user && user.name ? user.name : '';
                    const tweetTemplateText = translationHelper.get('blue_ocean_share_tweet_template', {
                        twitterName: config.social_name,
                        socialHashtag: config.social_hashtag,
                        url: config.isQuantic() ? 'https://bit.ly/2siTyMd' : 'http://j.mp/learnblue ',
                    });
                    const canonicalUrl = generateCanonicalUrl(config, '/blue-ocean-strategy');
                    const brandName = getBrandName(user, config);

                    return {
                        url: canonicalUrl,
                        title: translationHelper.get('blue_ocean_share_title'),
                        description: translationHelper.get('blue_ocean_share_description', { brandName }),
                        campaignMedium: 'organic',
                        campaignDescription: 'Blue Ocean Strategy',

                        twitter: {
                            description: tweetTemplateText,
                        },

                        email: {
                            title: translationHelper.get('blue_ocean_share_email_title'),
                            description: translationHelper.get('blue_ocean_share_email_description', {
                                brandName,
                                canonicalUrl,
                                signature,
                            }),
                        },
                    };
                },

                contentCompletedShareInfo(user, contentItem) {
                    return this.contentDefaultShareInfo(user, contentItem);
                },

                contentDefaultShareInfo(user, contentItem) {
                    // Base the content share info on the generic smartly share info
                    const shareInfo = this.smartlyShareInfo(user);
                    const config = ConfigFactory.getSync();

                    // Email information
                    const courseDescription = contentItem.description || '';
                    const courseTitle = contentItem.title;
                    const signature = user && user.name ? user.name : '';

                    // Entity Canonical URL
                    // Note that any shared entities should use the app subdomain.
                    const canonicalUrl = generateCanonicalUrl(config, contentItem.entity_metadata.canonical_url, true);

                    // Content-Item specific overrides
                    shareInfo.url = canonicalUrl;
                    shareInfo.title = courseTitle;
                    shareInfo.description = courseDescription;
                    shareInfo.campaignContent = contentItem.utmCampaign;
                    shareInfo.facebook = {
                        title: shareInfo.title,
                        description: shareInfo.description,
                    };
                    if (contentItem.email) {
                        shareInfo.email = contentItem.email;
                    } else {
                        shareInfo.email.description = this.defaultShareInfo.courseEmailDescription(
                            courseTitle,
                            courseDescription,
                            canonicalUrl,
                            signature,
                        );
                    }
                    if (contentItem.campaignDescription) {
                        shareInfo.campaignDescription = contentItem.campaignDescription;
                    }

                    // Entity Metadata overrides.
                    // Only done if using English locale for now, since we don't yet have translated entity_metadata entries.
                    // The overrides from the entity_metadata have been set at the database level and contain references to
                    // Quantic, so we only apply these overrides for Quantic users.
                    if ($translate.preferredLanguage() === 'en' && config.isQuantic()) {
                        // Entity Tweet template
                        let tweetTemplateText = this.defaultShareInfo.tweetTemplate;
                        if (contentItem.entity_metadata.tweet_template) {
                            tweetTemplateText = contentItem.entity_metadata.tweet_template;
                        }

                        // Entity Description & Title
                        const entityTitle = contentItem.entity_metadata.title;
                        const entityDescription = contentItem.entity_metadata.description;
                        shareInfo.title = entityTitle;
                        shareInfo.description = entityDescription || this.defaultShareInfo.description;
                        shareInfo.facebook.title = entityTitle;
                        shareInfo.facebook.description = entityDescription;
                        shareInfo.twitter.description = tweetTemplateText;
                    }

                    return shareInfo;
                },

                /*
                    NOTE: it is possible to create global_metadata entries for pages
                    in the angular app (currently join, sign-in and library), but that
                    data is not respected while navigating within the app, only on
                    initial page load.  It would be tricky to fix that in a theoretically
                    correct way, since routes and actions are not always 1-1, and I think
                    it doesn't matter for SEO stuff.  So maybe it's fine?
                */
                updateHeaderMetadata(opts = {}) {
                    const title = opts.title || this.pageMetadataDefaults()?.default_title;

                    $window.document.title = title;
                    $('meta[name="twitter:title"]').attr('content', title);
                    $('meta[property="og:title"]').attr('content', title);

                    const description = opts.description || this.pageMetadataDefaults()?.default_description;

                    $('meta[name="description"]').attr('content', description);
                    $('meta[name="twitter:description"]').attr('content', description);
                    $('meta[property="og:description"]').attr('content', description);

                    // Any time we call updateHeaderMetadata, and we pass in opts.path, we'll be using the app subdomain.
                    // That feels right, and we only currently do it in three places: show-lesson-dir, stream-dashboard-dir,
                    // and navigation-module. All of those places are served by the app subdomain.
                    const canonicalUrl = opts.path
                        ? generateCanonicalUrl(ConfigFactory.getSync(), opts.path, true)
                        : this.pageMetadataDefaults()?.default_canonical_url;

                    $('link[rel="canonical"]').attr('href', canonicalUrl);
                    $('meta[name="twitter:url"]').attr('content', canonicalUrl);
                    $('meta[property="og:url"]').attr('content', canonicalUrl);

                    if (
                        this.pageMetadataDefaults()?.default_image &&
                        this.pageMetadataDefaults()?.default_image.formats &&
                        this.pageMetadataDefaults()?.default_image.formats.original
                    ) {
                        if (!opts.image) {
                            opts.image = this.pageMetadataDefaults()?.default_image.formats.original.url;
                        }
                    }
                    if (opts.image) {
                        $('meta[name="twitter:image:src"]').attr('content', opts.image);
                        $('meta[property="og:image"]').attr('content', opts.image);
                    } else {
                        $('meta[name="twitter:image:src"]').attr('content', '');
                        $('meta[property="og:image"]').attr('content', '');
                    }
                },
            };
        });
    },
]);
