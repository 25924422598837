import mba from 'images/select-program/mba.png';
import emba from 'images/select-program/emba.png';
import aiTechLeaders from 'images/select-program/ai-tech-leaders.png';
import busAnalyticsLeaders from 'images/select-program/bus-analytics-leaders.png';
import cmoProgram from 'images/select-program/cmo-program.png';
import ctoProgram from 'images/select-program/cto-program.png';
import dataScienceFoundations from 'images/select-program/data-sci-foundations.png';
import executiveProgram from 'images/select-program/executive-program.png';
import financeAccountingManagers from 'images/select-program/finance-accounting-managers.png';
import foundersProgram from 'images/select-program/founders-program.png';
import msba from 'images/select-program/msba.png';
import msse from 'images/select-program/msse.png';
import prototypeGpt from 'images/select-program/prototype-gpt.png';
import tybAi from 'images/select-program/tyb-ai.png';
import tybBlockchain from 'images/select-program/tyb-blockchain.png';

import { ProgramType } from 'Program';
import { type SelectProgramConfig } from './selectProgram.types';

export const selectProgramConfig = (): SelectProgramConfig => [
    {
        title: { key: 'settings.select_program.section_degree_programs' },
        className: 'degree-programs',
        items: () => [
            {
                image: mba,
                imageMode: 'center',
                title: { key: 'settings.select_program.program_mba' },
                description: { key: 'settings.select_program.program_mba_description' },
                learnMoreUrl: 'https://quantic.edu/mba/',
                programType: ProgramType.mba,
            },
            {
                image: emba,
                imageMode: 'center',
                title: { key: 'settings.select_program.program_emba' },
                description: { key: 'settings.select_program.program_emba_description' },
                learnMoreUrl: 'https://quantic.edu/executive-mba/',
                programType: ProgramType.emba,
            },
            {
                image: msba,
                imageMode: 'center',
                title: { key: 'settings.select_program.program_msba' },
                description: { key: 'settings.select_program.program_msba_description' },
                preapprovedDescription: { key: 'settings.select_program.program_msba_preapproved_description' },
                learnMoreUrl: 'https://quantic.edu/ms-business-analytics/',
                programType: ProgramType.msba,
            },
            {
                image: msse,
                imageMode: 'center',
                title: { key: 'settings.select_program.program_msse' },
                description: { key: 'settings.select_program.program_msse_description' },
                preapprovedDescription: { key: 'settings.select_program.program_msse_preapproved_description' },
                learnMoreUrl: 'https://quantic.edu/ms-software-engineering/',
                programType: ProgramType.msse,
            },
        ],
    },
    {
        title: { key: 'settings.select_program.section_exec_ed' },
        className: 'exec-ed-programs',
        intro: { key: 'settings.select_program.section_exec_ed_intro' },
        subSections: () => [
            {
                title: { key: 'settings.select_program.section_rise_to_c_suite' },
                intro: { key: 'settings.select_program.section_rise_to_c_suite_intro' },
                items: () => [
                    {
                        image: ctoProgram,
                        imageMode: 'cover',
                        title: { key: 'settings.select_program.program_cto_cert' },
                        description: { key: 'settings.select_program.program_cto_cert_description' },
                        learnMoreUrl: 'https://quantic.edu/executive-education/quantic-cto-program/',
                        programType: ProgramType.cto_cert,
                    },
                    {
                        image: foundersProgram,
                        imageMode: 'cover',
                        title: { key: 'settings.select_program.program_founders_cert' },
                        description: { key: 'settings.select_program.program_founders_cert_description' },
                        learnMoreUrl: 'https://quantic.edu/executive-education/quantic-founders-program/',
                        programType: ProgramType.founders_cert,
                    },
                    {
                        image: executiveProgram,
                        imageMode: 'cover',
                        title: { key: 'settings.select_program.program_executive_cert' },
                        description: { key: 'settings.select_program.program_executive_cert_description' },
                        learnMoreUrl: 'https://quantic.edu/executive-education/quantic-executive-program/',
                        programType: ProgramType.executive_cert,
                    },
                    {
                        image: cmoProgram,
                        imageMode: 'cover',
                        title: { key: 'settings.select_program.program_cmo_cert' },
                        description: { key: 'settings.select_program.program_cmo_cert_description' },
                        learnMoreUrl: 'https://quantic.edu/executive-education/quantic-cmo-program/',
                        programType: ProgramType.cmo_cert,
                    },
                ],
            },
            {
                title: { key: 'settings.select_program.section_finance_data' },
                intro: { key: 'settings.select_program.section_finance_data_intro' },
                items: () => [
                    {
                        image: financeAccountingManagers,
                        imageMode: 'cover',
                        title: { key: 'settings.select_program.program_fin_for_non_fin_managers_cert' },
                        description: {
                            key: 'settings.select_program.program_fin_for_non_fin_managers_cert_description',
                        },
                        learnMoreUrl: 'https://quantic.edu/executive-education/finance-and-accounting-for-managers/',
                        programType: ProgramType.fin_for_non_fin_managers_cert,
                    },
                    {
                        image: busAnalyticsLeaders,
                        imageMode: 'cover',
                        title: { key: 'settings.select_program.program_bus_analytics_leaders_cert' },
                        description: {
                            key: 'settings.select_program.program_bus_analytics_leaders_cert_description',
                        },
                        learnMoreUrl: 'https://quantic.edu/executive-education/business-analytics-for-leaders/',
                        programType: ProgramType.bus_analytics_leaders_cert,
                    },
                    {
                        image: dataScienceFoundations,
                        imageMode: 'cover',
                        title: { key: 'settings.select_program.program_data_science_foundations_cert' },
                        description: {
                            key: 'settings.select_program.program_data_science_foundations_cert_description',
                        },
                        learnMoreUrl: 'https://quantic.edu/executive-education/data-science-foundations/',
                        programType: ProgramType.data_science_foundations_cert,
                    },
                ],
            },
            {
                title: { key: 'settings.select_program.section_ai_future_of_business' },
                intro: { key: 'settings.select_program.section_ai_future_of_business_intro' },
                items: () => [
                    {
                        image: tybAi,
                        imageMode: 'cover',
                        title: { key: 'settings.select_program.program_tyb_ai_cert' },
                        description: {
                            key: 'settings.select_program.program_tyb_ai_cert_description',
                        },
                        learnMoreUrl:
                            'https://quantic.edu/executive-education/transform-your-business-with-ai-and-chatgpt/',
                        programType: ProgramType.tyb_ai_cert,
                    },
                    {
                        image: prototypeGpt,
                        imageMode: 'cover',
                        title: { key: 'settings.select_program.program_prototype_gpt_cert' },
                        description: {
                            key: 'settings.select_program.program_prototype_gpt_cert_description',
                        },
                        learnMoreUrl: 'https://quantic.edu/executive-education/ai-for-building-software/',
                        programType: ProgramType.prototype_gpt_cert,
                    },
                    {
                        image: aiTechLeaders,
                        imageMode: 'cover',
                        title: { key: 'settings.select_program.program_ai_for_technical_leaders_cert' },
                        description: {
                            key: 'settings.select_program.program_ai_for_technical_leaders_cert_description',
                        },
                        learnMoreUrl: 'https://quantic.edu/executive-education/ai-for-technical-leaders/',
                        programType: ProgramType.ai_for_technical_leaders_cert,
                    },
                    {
                        image: tybBlockchain,
                        imageMode: 'cover',
                        title: { key: 'settings.select_program.program_tyb_blockchain_cert' },
                        description: {
                            key: 'settings.select_program.program_tyb_blockchain_cert_description',
                        },
                        learnMoreUrl:
                            'https://quantic.edu/executive-education/transform-your-business-with-blockchain/',
                        programType: ProgramType.tyb_blockchain_cert,
                    },
                ],
            },
        ],
    },
];
