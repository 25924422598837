import { type EndpointDefinitions } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import {
    type Api,
    type BaseQueryFn,
    type FetchArgs,
    type FetchBaseQueryError,
} from '@reduxjs/toolkit/dist/query/react';
import {
    combineReducers,
    type Slice,
    type ReducersMapObject,
    type Middleware,
    type Reducer,
    type AnyAction,
} from '@reduxjs/toolkit';
import { type CurriedGetDefaultMiddleware } from '@reduxjs/toolkit/dist/getDefaultMiddleware';

const noOpReducer: Reducer = (state, _action: AnyAction) => state;

export function combineApisAndSlices<
    BaseQuery extends BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
    Definitions extends EndpointDefinitions,
    Path extends string,
    TagTypes extends string,
>({
    apis,
    slices,
    middleware,
}: {
    apis?: Api<BaseQuery, Definitions, Path, TagTypes>[];
    slices?: Slice[];
    middleware?: Middleware[];
}) {
    apis = apis || [];
    slices = slices || [];

    const apiReducers: ReducersMapObject = apis.reduce((acc, api) => {
        acc[api.reducerPath] = api.reducer;
        return acc;
    }, {} as ReducersMapObject);

    const reducers = slices.reduce((acc, slice) => {
        acc[slice.name] = slice.reducer;
        return acc;
    }, apiReducers);

    const combinedMiddleware = apis.reduce((acc, api) => {
        acc.push(api.middleware);
        return acc;
    }, middleware || []);

    return {
        reducer: Object.keys(reducers).length > 0 ? combineReducers(reducers) : noOpReducer,
        middleware: (getDefault: CurriedGetDefaultMiddleware) =>
            getDefault({
                // serializableCheck is a built-in check that runs only in dev mode to warn you if you
                // put non-serializable objects into Redux. The tool has a built-in warning when it is running
                // slowly, and we started seeing that warning regularly and having the dev app run really slowly. The
                // warning says that if you're putting big objects into redux, then the check might be too slow and
                // you might have to disable it. We are intentially putting big objects in Redux, so it seems like
                // we're just gonna have to live without this check.
                serializableCheck: false,
            }).concat(combinedMiddleware),
    };
}
