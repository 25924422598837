import angularModule from 'Settings/angularModule/scripts/settings_module';
import template from 'Settings/angularModule/views/edit_english_documents.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

import trashcanBeige from 'vectors/trashcan_beige.svg';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('editEnglishDocuments', [
    '$injector',

    function factory($injector) {
        const User = $injector.get('User');
        const TranslationHelper = $injector.get('TranslationHelper');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const DialogModal = $injector.get('DialogModal');
        const PrivateUserDocumentsHelper = $injector.get('PrivateUserDocumentsHelper');
        const S3EnglishLanguageProficiencyDocument = $injector.get('S3EnglishLanguageProficiencyDocument');
        const frontRoyalUpload = $injector.get('frontRoyalUpload');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                user: '<',
                userProxy: '<',
                adminMode: '=?',
            },
            link(scope) {
                scope.trashcanBeige = trashcanBeige;

                NavigationHelperMixin.onLink(scope);

                scope.downloadDocument = document => PrivateUserDocumentsHelper.downloadDocument(document);
                scope.viewDocument = document => PrivateUserDocumentsHelper.viewDocument(document);

                const editDocumentsTranslationHelper = new TranslationHelper('settings.edit_documents'); // pain to refactor

                scope.englishLanguageProficiencyDocumentsUploadIsDisabled = () =>
                    scope.uploadingEnglishLanguageProficiencyDocument ||
                    (!scope.adminMode &&
                        (scope.userProxy.s3_english_language_proficiency_documents.length >=
                            User.maxEnglishLanguageProficiencyDocuments ||
                            !scope.userProxy.recordsIndicateUserShouldUploadEnglishLanguageProficiencyDocuments));

                Object.defineProperty(scope, 'documentCommentsAreaIsDisabled', {
                    get() {
                        if (scope.adminMode) {
                            return false;
                        }

                        if (!scope.userProxy.recordsIndicateUserShouldUploadEnglishLanguageProficiencyDocuments) {
                            return true;
                        }

                        return scope.userProxy.english_language_proficiency_documents_approved;
                    },
                    configurable: true,
                });

                scope.englishLanguageProficiencyDocumentsTypeOptions = _.map(
                    $injector.get('ENGLISH_LANGUAGE_PROFICIENCY_DOCUMENTS_TYPES'),
                    type => ({
                        value: type,
                        label: editDocumentsTranslationHelper.get(type),
                    }),
                );

                scope.onEnglishLanguageProficiencyDocumentSelect = ($file, $errFiles) => {
                    scope.englishLanguageProficiencyDocumentErrMessage = null;
                    scope.uploadingEnglishLanguageProficiencyDocument = true;

                    frontRoyalUpload
                        .handleNgfSelect($file, $errFiles, file => ({
                            url: S3EnglishLanguageProficiencyDocument.UPLOAD_URL,
                            data: {
                                record: {
                                    file,
                                    user_id: scope.userProxy.id,
                                },
                            },
                            supportedFormatsForErrorMessage: '.pdf, .doc, .docx, .jpg, .png',
                        }))
                        .then(response => {
                            const englishDocumentJson = _.first(
                                response.data.contents.s3_english_language_proficiency_documents,
                            );

                            // Since this change happens independently of a save button, reflect
                            // it on the original object now.
                            scope.user.s3_english_language_proficiency_documents.push(
                                S3EnglishLanguageProficiencyDocument.new(englishDocumentJson),
                            );
                            scope.userProxy.s3_english_language_proficiency_documents.push(
                                S3EnglishLanguageProficiencyDocument.new(englishDocumentJson),
                            );
                            // We call .save here (if not in admin mode) to address an issue where some
                            // users would upload then navigate away from the Documents page, forcing
                            // admins to manually set the document type on the user later.
                            if (!scope.adminMode) {
                                scope.$parent.save(
                                    scope.$parent.EDITABLE_DOCUMENTS_ATTRIBUTES,
                                    scope.$parent.EDITABLE_MAILING_ADDRESS_ATTRIBUTES,
                                );
                            }
                        })
                        .catch(err => {
                            scope.englishLanguageProficiencyDocumentErrMessage = err && err.message;
                        })
                        .finally(() => {
                            scope.uploadingEnglishLanguageProficiencyDocument = false;
                        });
                };

                scope.deleteEnglishLanguageProficiencyDocument = index => {
                    // documents can only be deleted in adminMode
                    if (!scope.adminMode) {
                        return;
                    }

                    const fileName = scope.userProxy.s3_english_language_proficiency_documents[index].file_file_name;

                    DialogModal.confirm({
                        text: `Are you sure you want to delete transcript '${fileName}'?`,
                        confirmCallback: () => {
                            scope.deletingEnglishLanguageProficiencyDocument = true;
                            scope.userProxy.s3_english_language_proficiency_documents[index]
                                .destroy()
                                .then(
                                    () => {
                                        // Since this change happens independently of a save button, reflect
                                        // it on the original object now.
                                        scope.user.s3_english_language_proficiency_documents.splice(index, 1);
                                        scope.userProxy.s3_english_language_proficiency_documents.splice(index, 1);
                                    },
                                    err => {
                                        throw new Error(err);
                                    },
                                )
                                .finally(() => {
                                    scope.deletingEnglishLanguageProficiencyDocument = false;
                                });
                        },
                    });
                };
            },
        };
    },
]);
