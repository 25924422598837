import moment from 'moment-timezone';
import { createFormFieldConversions } from 'FrontRoyalForm';

const [toForm, fromForm] = createFormFieldConversions({
    refund_time: {
        fromForm: values => values.refund_time && values.refund_time / 1000,
        toForm: values => values.refund_time && moment(values.refund_time * 1000),
    },
});

export { toForm, fromForm };
