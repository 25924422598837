
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.settings = window.Smartly.locales.modules.en.back_royal.settings || {};
window.Smartly.locales.modules.en.back_royal.settings.edit_account = window.Smartly.locales.modules.en.back_royal.settings.edit_account || {};
window.Smartly.locales.modules.en.back_royal.settings.edit_account = {...window.Smartly.locales.modules.en.back_royal.settings.edit_account, ...{
    "back_to_account": "BACK TO ACCOUNT",
    "change_password": "CHANGE PASSWORD",
    "email": "Email",
    "phone": "Phone Number",
    "name": "Name",
    "full_name": "Full Name",
    "nickname": "First Name or Nickname",
    "profile_update_failure": "There was a problem updating your profile. Please check your info and try again.",
    "profile_update_success": "Profile details successfully updated.",
    "save_changes": "SAVE CHANGES",
    "save_change": "SAVE CHANGE",
    "delete_account": "Delete Account",
    "delete": "Delete",
    "delete_account_description": "Account deletions are permanent and irreversible.",
    "confirm_account_deletion_title": "Confirm Account Deletion",
    "confirm_account_deletion": "Please confirm your account deletion request. Account deletions are permanent and irreversible.",
    "account_scheduled_for_deletion": "Account scheduled for deletion.",
    "should_you_wish_to_delete": "Should you wish to delete your account and all associated data from {{brandName}}, please contact <a href=\"mailto:{{brandEmail}}?subject=Account%20Deletion%20Request\">{{brandEmail}}</a> with the subject line \"Account Deletion Request.\" Please note that account deletion requests are <strong>permanent and irreversible</strong>.",
    "defer_instead": "If you're having issues staying on track with your cohort or you simply need a break from {{brandName}}, you should ask for a deferral before proceeding with the account deletion process. Simply email us at <a href=\"mailto:{{brandEmail}}?subject=Deferral%20Request\">{{brandEmail}}</a> with the subject \"Deferral Request\" to begin the deferral process. You can read more about deferring <a ng-click=\"loadFaqUrl('/help/what-does-it-mean-to-defer', '_blank')\">here</a>.",
    "student_id": "Student ID",
    "copy": "Copy",
    "copied": "Copied!",
    "email_forwarding": "Email Forwarding",
    "email_forwarding_desc_quantic": "As an active {{brandName}} student, you have been assigned a {{brandName}} Email Address that forwards to an address of your choosing. This email address remains active while you are enrolled in a degree program. It will be deactivated after graduation. {{brandName}} students may use this email address to sign up for free or discounted services with participating providers. For more information, see the FAQ article <a ng-click=\"loadFaqUrl('/help/student-discounts', '_blank')\">here</a>.",
    "your_student_email_address": "Your {{brandName}} Email Address:",
    "forwarding_address": "Forwarding Address:",
    "forwarding_address_desc": "Emails sent to your {{brandName}} Email Address will be forwarded here.",
    "manage_digital_exam_signatures": "Manage digital exam signatures",
    "create_bio_sig_id": "Create BioSig-ID",
    "update_bio_sig_id": "Update BioSig-ID"
}
}
    