import { type EndpointDefinitions, type EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { type Api, createApi as rtkCreateApi } from '@reduxjs/toolkit/dist/query/react';
import { type AnyFunction } from '@Types';
import { createMakeRequestFunction } from './createMakeRequestFunction';

export const createApi = <
    BaseQuery extends AnyFunction,
    Path extends string,
    Definitions extends EndpointDefinitions,
    TagTypes extends string = never,
>(
    reducerPath: Path,

    // We don't really want to exclude extractRehydrationInfo, but there's some mysterious interaction
    // between it and reducerPath that makes it hard to placate typescript if we don't omit it
    config: {
        baseQuery: BaseQuery;
        tagTypes?: TagTypes[];
        endpoints: (build: EndpointBuilder<BaseQuery, TagTypes, Path>) => Definitions;
    } & Omit<Parameters<typeof rtkCreateApi>[0], 'endpoints' | 'extractRehydrationInfo' | 'tagTypes'>,
) => {
    const api = rtkCreateApi({
        ...config,
        reducerPath,
    });

    const makeRequest = createMakeRequestFunction(api as unknown as Api<BaseQuery, Definitions, Path, TagTypes>);

    return { ...api, makeRequest };
};

export default createApi;
