
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.settings = window.Smartly.locales.modules.en.back_royal.settings || {};
window.Smartly.locales.modules.en.back_royal.settings.sign_out = window.Smartly.locales.modules.en.back_royal.settings.sign_out || {};
window.Smartly.locales.modules.en.back_royal.settings.sign_out = {...window.Smartly.locales.modules.en.back_royal.settings.sign_out, ...{
    "signing_out": "Signing out..."
}
}
    