import EventLoggerProvider from 'EventLogger/EventLoggerProvider';
import { type AnyFunction, type AnyObject } from '@Types';
import { type ComponentType } from 'JsxTransformer/JsxTransformer.types';
import { type ReactElement, type ReactNode } from 'react';
import ErrorLogServiceProvider from 'ErrorLogging/ErrorLogServiceProvider';

type PropsForLogableComponents = AnyObject &
    (
        | { onSubmit: AnyFunction; children?: ReactNode; logLabel?: string; logPayload?: AnyObject }
        | { onClick: AnyFunction; children?: ReactNode; logLabel?: string; logPayload?: AnyObject }
    );

// exported for specs
export const getComponentName = (type: ComponentType) => {
    if (typeof type === 'function') return type.name;

    if (typeof type === 'object' && !!type) {
        // Try and discern "module" components or forwarded ref components
        if (type.displayName) return type.displayName;
        if ('render' in type && typeof type.render === 'function') return type.render.name;
        // Try and discern 3rd party components
        if (typeof type.type === 'object' && !!type.type) {
            if (type.type.displayName) return type.type.displayName;
            if ('render' in type.type && typeof type.type.render === 'function')
                return type.type.render.displayName || type.type.render.name;
        }
    }

    return String(type);
};

// exported for specs
export const getLogLabel = ({ children }: { children?: ReactNode }, type: ComponentType) => {
    const componentName = getComponentName(type);
    if (!children) return componentName;

    // if the component has children, we add them into the label
    // e.g. <button onClick={...}>Cancel</button> will create a label of "Cancel:button"
    if (typeof children === 'string') return `${children}:${componentName}`;
    if (typeof children === 'object') {
        if (Array.isArray(children)) {
            return children
                .map(child => {
                    if (typeof child === 'string') return child;
                    return getComponentName(child?.type ?? child);
                })
                .join(':')
                .slice(0, 64)
                .concat(':', componentName);
        }

        const childComponentName = getComponentName((children as ReactElement).type as ComponentType);
        return `${childComponentName}:${componentName}`;
    }

    return componentName;
};

const propsToLogFor = ['onClick', 'onSubmit'] as const;

/**
 * reactPropsLogger allows us to check the props passed to a component and if the component includes
 * a prop defined in propsToLogFor, then we can add logging to the function passed in as that prop.
 *
 * e.g. if a <button onClick={someFunc}></button> has an 'onClick' property, then
 * we will log an event every time it is clicked.
 *
 * See jsx-runtime.ts for an explanation of how this is used and works
 *
 * reactPropsLogger also supports a few extra props around controlling logging
 *  The "noLog" prop will ensure no logging is added.
 *  Passing a string as the "logLabel" prop will set that string as the "label" key for the event.
 *  Passing an object as "logPayload" props will set that object as the payload object for the event
 */

export const reactPropsLogger = (props: AnyObject | null | undefined, type: ComponentType) => {
    if (!props || typeof props !== 'object' || !Object.keys(props).length) return props;

    if ('noLog' in props && !!props.noLog) return props;

    try {
        // As of right now we really only want to inject logging to intrinsic html elements.
        // If we later decide to change this, we can remove these if statements
        if (typeof type === 'object' && String(type.$$typeof) !== 'Symbol(react.forward_ref)') return props;
        if (typeof type === 'function') return props;

        return propsToLogFor.reduce((acc, key) => {
            if (!(key in acc && !!acc[key])) return acc;

            const eventPayload = {
                label: acc.logLabel ? acc.logLabel : getLogLabel(acc, type),
                ...acc.logPayload,
            };
            const eventType = key.startsWith('on') ? key.slice(2).toLowerCase() : key.toLowerCase();

            const passedCallback = acc[key] as AnyFunction;

            acc[key] = async (...args: unknown[]) => {
                EventLoggerProvider.get()?.log(eventType, eventPayload, { segmentio: false });
                await passedCallback?.(...args);
            };

            return acc;
        }, props as PropsForLogableComponents);
    } catch (e) {
        ErrorLogServiceProvider.get()?.notifyInProd(
            e as Error,
            'An error occurred trying to parse props in reactPropsLogger',
        );

        return props;
    }
};

if (process.env.NODE_ENV === 'development') {
    const consoleError = console.error;
    console.error = (msg, ...rest) => {
        // suppress any react warnings about not supporting our custom "logLabel/logConfig/noLog" props
        if (msg?.match?.(/Warning: React does not recognize the/)) return;
        consoleError(msg, ...rest);
    };
}
