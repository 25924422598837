import { DELIVERED_DIGITAL_CERTIFICATE, PHYSICAL_DIPLOMA } from './constants';
import { type ProgramType, type ProgramTypeConfig } from './Program.types';
import { ProgramTypeConfigs } from './ProgramTypeConfigs';

export function getProgramConfigValue(programType: ProgramType, key: keyof ProgramTypeConfig) {
    return ProgramTypeConfigs[programType][key];
}

export function showsDiplomaCheckInWhenInFinishedState(programType: ProgramType) {
    return getProgramConfigValue(programType, 'completionDocumentType') === PHYSICAL_DIPLOMA;
}

export function showsCertificateCheckInWhenInFinishedState(programType: ProgramType) {
    return getProgramConfigValue(programType, 'completionDocumentType') === DELIVERED_DIGITAL_CERTIFICATE;
}
