export default angular.module('prioritizedInterceptors', []).provider('PrioritizedInterceptors', [
    '$injector',
    function ($injector) {
        const $httpProvider = $injector.get('$httpProvider');

        return {
            /*
                Request interceptors will be run in order of priority.
                Response interceptors will be run in reverse order.

                Interceptors that are added to $httpProvider.interceptors without
                using PrioritizedInterceptors.addInterceptor are assumed to
                have a priority of 0.

            */
            addInterceptor(priority, interceptor) {
                if (typeof interceptor === 'string') {
                    const path = interceptor;
                    interceptor = ['$injector', $injector => $injector.get(path)];
                }
                interceptor.priority = priority;
                let i = 0;
                for (i; i < $httpProvider.interceptors.length; i++) {
                    const _interceptor = $httpProvider.interceptors[i];
                    const _priority = _interceptor.priority || 0;

                    if (priority < _priority) {
                        break;
                    }
                }

                $httpProvider.interceptors.splice(i, 0, interceptor);
            },
            $get() {
                return {};
            },
        };
    },
]);
